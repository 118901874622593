import React from 'react'
import { styled } from 'styled-components'
import { Icon, LinkBlocks as LinkBlocksComponent, theme } from '@damen/ui'
import type { BlockType } from '@damen/ui/lib/esm/components/LinkBlocks/Default'
import { colors } from '@damen/ui/lib/cjs/styles'

import Link from '@/components/Link'
import Container from '@/components/UI/Container'
import { LinkBlocks as LinkBlocksProps } from '@/queries/schema.generated'

const StyledLink = styled(Link.VNext)`
  justify-content: space-between;
  color: ${colors.white};
`

interface Props {
  content: LinkBlocksProps
}

const LinkBlocks: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const blocks =
    content.blocks.map((block) => {
      if (block.__typename === 'LinkBlockImage') {
        return {
          buttonComponent:
            block.link &&
            (({ children }: any) => (
              <Link.VNext type="link" link={block.link}>
                {children}
              </Link.VNext>
            )),
          id: block.id,
          image: {
            alt: block.image.alt,
            src: block.image.filename,
            sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
          },
          title: block.title,
          type: 'image' as BlockType,
        }
      }

      return {
        id: block.id,
        links: block.links.map((link) => ({
          buttonComponent:
            link &&
            (() => (
              <StyledLink type="link" link={link.link} icon={() => <Icon.LeftChevron fill={colors.white} />}>
                {link.linkText}
              </StyledLink>
            )),
          id: link.id,
        })),
        title: block.title,
        type: 'list' as BlockType,
      }
    }) || []

  return (
    <Container>
      <LinkBlocksComponent.Default blocks={blocks} title={content.title} />
    </Container>
  )
}

export default LinkBlocks
