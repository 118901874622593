import React from 'react'
import { Icon, theme } from '@damen/ui'
import Modal from 'react-modal'
import styled, { keyframes } from 'styled-components'

import { DefaultModalProps } from '../types'
import { scrollbarBlue } from '@/styles/scrollbars'

interface Props extends DefaultModalProps {
  extraPanelContent?: any
}

const styles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
  content: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    padding: 0,
    border: 0,
    outline: 'none',
  },
}

const ReactModalAdapter: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  children,
  extraPanelContent,
  footer,
  isOpen,
  testId,
  onClose,
}) => {
  const wrapperElement = React.useRef<HTMLDivElement>(null)

  const handleClick = (event: any) => {
    if (!wrapperElement.current) return

    if (event.target === wrapperElement.current || wrapperElement.current.contains(event.target)) return

    onClose()
  }

  return (
    <Modal
      ariaHideApp
      className={`${className}__content`}
      closeTimeoutMS={theme.timing.numDefault}
      contentLabel="Modal"
      isOpen={isOpen}
      overlayClassName={`${className}__overlay`}
      onRequestClose={onClose}
      portalClassName={className}
      role="dialog"
      style={styles}
      shouldCloseOnEsc
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
      testId={testId}
    >
      <Container onClick={(event) => handleClick(event)}>
        <Wrapper ref={wrapperElement} sidePanel={!!extraPanelContent}>
          <Header>
            <CloseButton onClick={onClose}>
              <Icon.Close height={17} width={18} fill={theme.colors.blue} />
            </CloseButton>
          </Header>
          <PanelWrapper>
            {extraPanelContent && (
              <SidePanel>
                <Content>{extraPanelContent}</Content>
              </SidePanel>
            )}
            <Panel>
              <Content>{children}</Content>
              {footer && <Footer>{footer}</Footer>}
            </Panel>
          </PanelWrapper>
        </Wrapper>
      </Container>
    </Modal>
  )
}

const StyledModal = styled(ReactModalAdapter)`
  @media ${theme.legacyMediaQueries.md} {
    &__overlay {
      background-color: transparent;
      transition: background-color 300ms ease;

      &.ReactModal__Overlay--after-open {
        background-color: rgba(0, 0, 0, 0.75);
      }

      &.ReactModal__Overlay--before-close {
        background-color: transparent;
      }
    }
  }

  &__content {
    left: auto !important;
    transform: translateX(100%);
    transition-duration: ${theme.timing.default};
    transition-timing-function: ease-in-out;
    transition-property: transform, opacity;

    &.ReactModal__Content--after-open {
      transform: translateX(0%);
    }

    &.ReactModal__Content--before-close {
      transform: translateX(100%);
    }
  }

  @media ${theme.legacyMediaQueries.md} {
    &__content {
      width: 390px;
    }
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;

  @media ${theme.legacyMediaQueries.sm} {
    justify-content: flex-end;
  }
`

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['sidePanel'].includes(prop),
})<{ sidePanel: boolean }>`
  width: 100%;

  @media ${theme.legacyMediaQueries.md} {
    display: flex;
    justify-content: flex-end;
    max-width: 386px;

    ${({ sidePanel }) =>
      sidePanel &&
      `
        max-width: 775px;
    `}
  }

  @media ${theme.legacyMediaQueries.mdMax} {
    width: 100%;
    flex-direction: column;
    overflow-y: auto;
    background: ${theme.colors.white};

    ${scrollbarBlue}

    ${({ sidePanel }) =>
      sidePanel &&
      `
      display: block;

      ${Panel} {
        height: auto;
      }
    `}
  }
`

const PanelWrapper = styled.div`
  display: flex;

  @media ${theme.legacyMediaQueries.mdMax} {
    flex-direction: column-reverse;
  }
`

const Panel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${theme.colors.white};
  width: 386px;

  & + & {
    z-index: 2;
  }

  @media ${theme.legacyMediaQueries.mdMax} {
    width: 100%;
  }
`

const slideIn = keyframes`
  0% {
    transform: translateX(30%);
  }
  100% {
    transform: translateX(0);
  }
`

const SidePanel = styled(Panel)`
  background-color: ${theme.colors.blueIce};

  @media ${theme.legacyMediaQueries.mdMax} {
    padding-top: 16px;
  }

  @media ${theme.legacyMediaQueries.md} {
    transform: translateX(30%);
    animation: 0.3s ${slideIn} ease-in-out forwards;
    animation-delay: 0.3s;
  }
`

const Header = styled.div`
  @media ${theme.legacyMediaQueries.mdMax} {
    display: flex;
    justify-content: flex-end;
    padding: 24px 24px 0 0;
    position: relative;
    z-index: 2;
  }

  @media ${theme.legacyMediaQueries.md} {
    position: absolute;
    z-index: 3;
    top: 32px;
    right: 32px;
    padding: 0;
  }
`

const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  border-radius: 50%;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  transition: ${theme.timing.fast};
  display: flex;
  justify-content: center;
  align-items: center;

  :hover,
  :focus {
    outline: none;
    background: ${theme.colors.blueIce};
  }

  @media ${theme.legacyMediaQueries.md} {
    order: 1;
  }
`

const Content = styled.div`
  flex: 1;
  padding: 32px 16px;

  @media ${theme.legacyMediaQueries.md} {
    overflow-y: auto;
    padding: 96px 40px 40px;

    ${scrollbarBlue}
  }
`

const Footer = styled.div`
  transition: border-color 300ms ease;
  padding: 24px;

  @media ${theme.legacyMediaQueries.md} {
    padding: 32px 40px;
  }
`

export default StyledModal
