import React from 'react'
import { Downloads as DownloadsComponent } from '@damen/ui'
import { Download } from '@damen/ui/lib/esm/components/Downloads/Default'

import Container from '@/components/UI/Container'
import { Background } from '@/types'

interface Content {
  id: string
  title: string
  background: Background
  showMore: string
  showLess: string
  downloads: Download[]
}

interface Props {
  content: Content
}

const Downloads: React.FC<React.PropsWithChildren<Props>> = ({ content }) => (
  <Container background={content.background}>
    <DownloadsComponent.Default
      title={content.title}
      showMore={content.showMore}
      showLess={content.showLess}
      background={content.background}
      downloads={content.downloads}
    />
  </Container>
)

export default Downloads
