import React from 'react'

import { TabBar as TabBarComponent } from '@damen/ui'

import { Background } from '@/types'

interface Props {
  background?: Background
  initialValue?: string
  items: any[]
  onTabClick: (tab: string) => void
}

const TabBar: React.FC<React.PropsWithChildren<Props>> = ({
  background = 'default',
  initialValue,
  items,
  onTabClick,
}) => (
  <TabBarComponent.Default background={background} initialValue={initialValue} items={items} onTabClick={onTabClick} />
)

export default TabBar
