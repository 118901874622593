import * as Types from '../../schema.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetTranslationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTranslationsQuery = (
  { __typename?: 'Query' }
  & { site: Types.Maybe<(
    { __typename?: 'Site' }
    & Pick<Types.Site, 'id'>
    & { emailTranslations: Types.Maybe<(
      { __typename?: 'EmailTranslations' }
      & Pick<Types.EmailTranslations, 'id' | 'subject'>
    )> }
  )> }
);


export const GetTranslationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getTranslations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"site"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"emailTranslations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"subject"}}]}}]}}]}}]} as unknown as DocumentNode<GetTranslationsQuery, GetTranslationsQueryVariables>;