import React from 'react'
import { Icon, NextImage, theme } from '@damen/ui'
import styled, { css } from 'styled-components'

interface Props {
  image?: any
  showBowWave?: boolean
  inline?: boolean
  testId?: string
  show: boolean
}

const Default: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  image,
  showBowWave = true,
  inline = false,
  testId = 'modalSuccessContent',
  show,
}) => {
  return (
    <Container maxWidth={inline} data-testid={testId} show={show}>
      <ImageWrapper>
        <ImageInner>
          <NextImage
            src={image?.filename}
            alt={image?.alt ?? 'Send successfully'}
            width={200}
            height={200}
            aspectRatio="1:1"
          />
        </ImageInner>
        {showBowWave && <Icon.BowWaveSmall width={85} height={43} />}
      </ImageWrapper>
      {children}
    </Container>
  )
}

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['maxWidth', 'show'].includes(prop),
})<{ maxWidth: boolean; show: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  text-align: center;

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      width: 386px;
      margin: 0 auto;

      @media ${theme.legacyMediaQueries.md} {
        padding: 0 40px;
      }

      @media ${theme.legacyMediaQueries.mdMax} {
        width: 100%;
        padding: 32px 16px;
      }
    `}
`

const ImageInner = styled.div`
  overflow: hidden;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;

  svg {
    position: absolute;
    top: 40px;
    left: 24px;
  }

  img {
    border-radius: 50%;
  }
`

export default Default
