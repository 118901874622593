import { NextRouter, useRouter } from 'next/router'
import { StoryblokLink } from '@/queries/schema.generated'
import {
  CONTACT_QUERYSTRING,
  DEPARTMENT_CONTACT_MODAL_URL_REGEX,
  DEPARTMENT_CONTACT_QUERYSTRING,
  DEPARTMENT_NEWSLETTER_MODAL_URL_REGEX,
  DEPARTMENT_NEWSLETTER_QUERYSTRING,
  FEEDBACK_QUERYSTRING,
  MAP_QUERYSTRING,
  GLOBAL_MAP_QUERYSTRING,
  FORMTYPE,
  NEWSLETTER_QUERYSTRING,
  QUOTE_QUERYSTRING,
  DONATION_MODAL_URL_REGEX,
  DONATION_QUERYSTRING,
} from '@/components/Forms/constants'

type FormattedRouter = (link?: StoryblokLink) => [() => void, (link: StoryblokLink) => void]

export const isHTTP = (linkObj: StoryblokLink) =>
  linkObj.url?.includes('http') && !linkObj.url?.includes('https')

const executeCallback = (link: StoryblokLink, router: NextRouter) => {
  if ((!link.cachedUrl && !link.url) || isHTTP(link)) return null

  if (link.url === '/') {
    return router.push('/', {}, { scroll: false })
  }

  if (link.url?.includes('https') || link.url?.includes('www')) {
    return router.push(link.url)
  }

  if (link.cachedUrl === 'contact' || link.url === 'contact') {
    return router.push({ hash: CONTACT_QUERYSTRING }, undefined, { shallow: true })
  }

  if (link.cachedUrl === 'quote' || link.url === 'quote') {
    return router.push({ hash: QUOTE_QUERYSTRING }, undefined, { shallow: true })
  }

  if (DEPARTMENT_CONTACT_MODAL_URL_REGEX.test(link.cachedUrl)) {
    localStorage.setItem(FORMTYPE, DEPARTMENT_CONTACT_MODAL_URL_REGEX.exec(link.cachedUrl)[2])

    return router.push({ hash: DEPARTMENT_CONTACT_QUERYSTRING }, undefined, { shallow: true })
  }

  if (DONATION_MODAL_URL_REGEX.test(link.cachedUrl)) {
    localStorage.setItem(FORMTYPE, DONATION_MODAL_URL_REGEX.exec(link.cachedUrl)[2])

    return router.push({ hash: DONATION_QUERYSTRING }, undefined, { shallow: true })
  }

  if (DEPARTMENT_NEWSLETTER_MODAL_URL_REGEX.test(link.cachedUrl)) {
    localStorage.setItem(FORMTYPE, DEPARTMENT_NEWSLETTER_MODAL_URL_REGEX.exec(link.cachedUrl)[2])
    return router.push({ hash: DEPARTMENT_NEWSLETTER_QUERYSTRING }, undefined, { shallow: true })
  }

  if (link.cachedUrl === 'map') {
    return router.push({ hash: MAP_QUERYSTRING }, undefined, { shallow: true })
  }

  if (link.cachedUrl === 'global_map') {
    return router.push({ hash: GLOBAL_MAP_QUERYSTRING }, undefined, { shallow: true })
  }

  if (link.cachedUrl === 'newsletter') {
    return router.push({ hash: NEWSLETTER_QUERYSTRING }, undefined, { shallow: true })
  }

  if (link.cachedUrl === 'feedback') {
    return router.push({ hash: FEEDBACK_QUERYSTRING }, undefined, { shallow: true })
  }

  if (link.cachedUrl) {
    return router.push(`/${link.cachedUrl}`, {}, { scroll: false })
  }

  return null
}

const useFormattedRouter: FormattedRouter = (link) => {
  const router = useRouter()

  const handleOnRouteClick = () => executeCallback(link, router)

  const handleDynamicRouteClick = (linkObject: StoryblokLink) => executeCallback(linkObject, router)

  return [handleOnRouteClick, handleDynamicRouteClick]
}

export { useFormattedRouter }
