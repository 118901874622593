import React from 'react'
import styled from 'styled-components'

import { Spacing } from '@/types'

export interface Props {
  verticalSpacing?: Spacing
}

const getVerticalSpacing = (spacing: Spacing) => {
  switch (spacing) {
    case 'top':
      return `
        padding: 80px 0 0;
      `
    case 'bottom':
      return `
        padding: 0 0 80px;
      `
    case 'both':
      return `
        padding: 80px 0;
      `
    default:
      return `
        padding: 0;
      `
  }
}

const ContainerVertical: React.FC<React.PropsWithChildren<Props>> = ({ children, verticalSpacing = 'none' }) => (
  <Inner verticalSpacing={verticalSpacing}>{children}</Inner>
)

const Inner = styled.div.withConfig({
  shouldForwardProp: (prop) => !['verticalSpacing'].includes(prop),
})<{ verticalSpacing: Spacing }>`
  padding: 40px 0;

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    ${({ verticalSpacing }) => getVerticalSpacing(verticalSpacing)}
  }
`

export default ContainerVertical
