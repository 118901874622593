import { DOMAttributes, useRef } from 'react'

export const useIgnoreClickOnDrag = () => {
  const pointer = useRef({ x: 0, y: 0 })

  const result: Partial<DOMAttributes<unknown>> = {
    onMouseDownCapture: (e) => {
      pointer.current = { x: e.clientX, y: e.clientY }
    },
    onMouseUp: (e) => {
      const { x: x1, y: y1 } = pointer.current
      const { clientX: x2, clientY: y2 } = e

      if (x1 !== x2 || y1 !== y2) {
        e.stopPropagation()
      }
    },
  }

  return result
}
