import React from 'react'
import { Slider, theme } from '@damen/ui'
import Link from '@/components/Link'
import { Background } from '@/types'
import { Category, Slide } from './types'
import { getSustainableMark } from '@/utils/helpers/sustainableIcon'
import Container from '../Container'

interface Content {
  background: Background
  categories: Category[]
  id: string
}

interface Props {
  content: Content
}

const titleWithIcon = (title: string, sustainableMark: string): React.ReactNode => {
  return (
    <>
      {title}
      {getSustainableMark(sustainableMark)}
    </>
  )
}

const ProductSlider: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const categories = content.categories.map((item: Category) => {
    const slides = item.slides.map((slide: Slide) => ({
      ...slide,
      buttonComponent:
        slide.link.cachedUrl !== ''
          ? ({ children }: any) => (
              <Link.VNext type="link" link={slide.link}>
                {children}
              </Link.VNext>
            )
          : undefined,
      id: slide.id,
      img: {
        alt: slide.img?.alt,
        src: slide.img?.filename,
        sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 448px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 3), (min-width: 722px) calc(100vw / 3), 100vw`,
      },
      title: slide.sustainableMark ? titleWithIcon(slide.title, slide.sustainableMark) : slide.title,
    }))

    if (!item.link?.cachedUrl) {
      return {
        ...item,
        link: null,
        slides,
      }
    }

    return {
      ...item,
      buttonComponent: item.link && (() => <Link.VNext type="button" link={item.link} text={item.linkText} />),
      slides,
    }
  })

  return (
    <Container background={content.background}>
      <Slider.Product id={content.id} background={content.background} categories={categories} />
    </Container>
  )
}

export default ProductSlider
