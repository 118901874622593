import React from 'react'
import { Feed } from '@damen/ui'

import Link from '@/components/Link'
import { Container } from '@/components/UI'
import { Props } from './types'

const EventFeed: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { title, linkText, link, eventItems } = content

  const mappedFeedItems = eventItems.map((feedItem) => ({
    ...feedItem,
    linkComponent:
      feedItem.url &&
      (({ children }: any) => (
        <Link.VNext type="link" link={feedItem.url}>
          {children}
        </Link.VNext>
      )),
  }))

  // If there are no events, the component should not be displayed
  if (eventItems.length < 1) {
    return <></>
  }

  return (
    <Container>
      <Feed.Event
        title={title}
        buttonComponent={linkText && link && (() => <Link.VNext type="contentlink" link={link} text={linkText} />)}
        items={mappedFeedItems}
      />
    </Container>
  )
}

export default EventFeed
