import { theme } from '@damen/ui'
import React from 'react'
import Skeleton, { SkeletonProps } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkeletonLoader = ({ ...props }: SkeletonProps) => {
  return <Skeleton baseColor={theme.colors.greyDark} highlightColor={theme.colors.greyAccentLight} {...props} />
}

export default SkeletonLoader
