import { StoryBlokLink } from '@/types'

const removeTrailingSlash = (slug: string): string => {
  return slug.endsWith('/') ? slug.substr(0, slug.length - 1) : slug
}

export const removePrecedingSlash = (slug: string): string => {
  return slug.startsWith('/') ? slug.substr(1, slug.length) : slug
}

export const formatSlug = (domain: string, slug: string): string => {
  if (!slug || slug === '/') {
    return domain
  }

  const slugWithoutPrecedingSlash = removePrecedingSlash(slug)
  const slugWithoutPrecedingAndTrailingSlash = removeTrailingSlash(slugWithoutPrecedingSlash)

  return `${domain}/${slugWithoutPrecedingAndTrailingSlash}`
}

export const isValidLink = (link: StoryBlokLink) => {
  return link && (link.cachedUrl || link.url)
}
