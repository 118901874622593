import { ForwardedRef, MutableRefObject, RefCallback } from 'react'

export default function mergeRefs<T>(...refs: (MutableRefObject<T> | ForwardedRef<T>)[]): RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        // eslint-disable-next-line no-param-reassign
        ref.current = value
      }
    })
  }
}
