import React from 'react'
import { Action, Banner } from '@damen/ui'
import styled from 'styled-components'
import { Container } from '@/components/UI'
import { ServiceBanner as ServiceBannerContent } from '@/queries/schema.generated'
import LinkVNext from '@/components/Link'

type ServiceBannerAliasContent = {
  serviceBannerTitle: string
  serviceBannerText: string
} & ServiceBannerContent

interface Content {
  content: ServiceBannerAliasContent
}

const ServiceBanner: React.FC<React.PropsWithChildren<Content>> = ({ content }) => {
  const PhoneComponent = (
    <StyledContactLink type="link" link={`tel:${content.phone}`}>
      {content.phone}
    </StyledContactLink>
  )

  return (
    <Container>
      <Banner.Service
        contactComponent={PhoneComponent}
        title={content.serviceBannerTitle}
        text={content.serviceBannerText}
      />
    </Container>
  )
}

const StyledContactLink = styled(LinkVNext.VNext)`
  ${Action.LinkStyle}

  &::before {
    height: 3px;
  }
`

export default ServiceBanner
