import React from 'react'
import { Icon, Services as ServicesComponent, theme } from '@damen/ui'
import { Props as ServicesProps } from '@damen/ui/lib/esm/components/Services/Default'
import { SvgProps } from '@damen/ui/lib/esm/types'
import { Container } from '@/components/UI'
import { ServicesFragment } from '@/queries/fragments/ServicesFragment.generated'
import { isValidLink } from '@/utils/slugUtils'
import Link from '@/components/Link'

interface Content {
  content: ServicesFragment
}

// TODO: THis type should be updated so we can return the element directly, therefore we also have to update the icon type in the component library
const getSeviceIcon = (iconType: string): React.ComponentType<SvgProps> => {
  const iconHeight = 41
  const iconWidth = 41
  switch (iconType) {
    case 'finance':
      // eslint-disable-next-line react/display-name
      return () => <Icon.CardFinance height={iconHeight} width={iconWidth} fill={theme.colors.grey} />
    case 'lifecycle':
      // eslint-disable-next-line react/display-name
      return () => <Icon.BellThin height={iconHeight} width={iconWidth} fill={theme.colors.grey} />
    case 'built':
      // eslint-disable-next-line react/display-name
      return () => <Icon.Location height={iconHeight} width={iconWidth} fill={theme.colors.grey} />
    case 'trade':
      // eslint-disable-next-line react/display-name
      return () => <Icon.BoatThin height={iconHeight} width={iconWidth} fill={theme.colors.grey} />
    default:
      // eslint-disable-next-line react/display-name
      return () => <Icon.BoatThin height={iconHeight} width={iconWidth} fill={theme.colors.grey} />
  }
}

const Services: React.FC<React.PropsWithChildren<Content>> = ({ content }) => {
  const servicesProps: ServicesProps = {
    title: content.title,
    items: content.items.map((item) => {
      return {
        description: item.description,
        title: item.title,
        id: item.id,
        icon: getSeviceIcon(item.icon),
        linkComponent:
          item.linkText &&
          isValidLink(item.link) &&
          (() => <Link.VNext type="contentlink" link={item.link} text={item.linkText} size="small" target="_blank" />),
      }
    }),
  }

  return (
    <Container id={content.sectionId}>
      <ServicesComponent.Default {...servicesProps} />
    </Container>
  )
}

export default Services
