import React from 'react'
import { Heading, Icon, ParallaxTiles as ParallaxTilesComponent, theme } from '@damen/ui'
import styled from 'styled-components'

import Link from '@/components/Link'
import { Container, ContainerVertical } from '@/components/UI'

import { Spacing } from '@/types'
import { isValidLink } from '@/utils/uriUtil'
import { ParallaxTiles as ParallaxTilesProps } from '@/queries/schema.generated'

interface Content {
  content: ParallaxTilesProps
}

const ParallaxTiles: React.FC<React.PropsWithChildren<Content>> = ({ content }) => {
  const columns =
    content.columns?.map((column) => ({
      ...column,
      id: column.id,
      tiles: column?.tiles?.map((tile) => {
        return {
          ...tile,
          title: tile.title,
          buttonComponent:
            isValidLink(tile.link) &&
            (({ children }: any) => (
              <Link.VNext type="link" link={tile.link}>
                {children}
              </Link.VNext>
            )),
          id: tile.id,
          image: {
            alt: tile.image.alt,
            src: tile.image?.filename,
            sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 3), 100vw`,
          },
        }
      }),
    })) || []

  return (
    <ContainerVertical verticalSpacing={content.spacing as Spacing}>
      <Container>
        {content.title && <Title>{content.title}</Title>}
        <ParallaxWrapper>
          <ParallaxTilesComponent.Default columns={columns} />
        </ParallaxWrapper>
        {content.bowWave && (
          <BowWave>
            <Icon.BowWaveSmall width={265} height={196} fill={theme.colors.blueIce} />
          </BowWave>
        )}
      </Container>
    </ContainerVertical>
  )
}

const Title = styled(Heading.HeadingTwo)`
  margin: 0 0 60px;

  @media ${theme.legacyMediaQueries.md} {
    max-width: 288px;
  }
`

const ParallaxWrapper = styled.div`
  position: relative;
  z-index: 1;
`

const BowWave = styled.div`
  position: absolute;
  top: 80px;
  right: 0;
  z-index: 0;
  display: none;
  opacity: 0.5;

  @media ${theme.legacyMediaQueries.md} {
    display: block;
  }

  @media ${theme.legacyMediaQueries.lg} {
    top: 100px;

    svg {
      width: 496px !important;
      height: 249px !important;
    }
  }
`

export default ParallaxTiles
