import { useEffect, useState } from 'react'

export const useIsoCountries = (lang = 'en') => {
  const [countryList, setCountryList] = useState<{ label: string; value: string }[]>([])

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const i18n = await import('i18n-iso-countries')
      const i18nEn = await import(`i18n-iso-countries/langs/en.json`)
      i18n.registerLocale(i18nEn)

      const names = i18n.getNames(lang, { select: 'official' })

      const sortedList = Object.entries(names)
        .map((name) => {
          return {
            label: name[1],
            value: name[0],
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))

      setCountryList(sortedList)
    }

    fetchData().catch((e: unknown) => console.error(e))
  }, [lang])

  return { countryList, lang }
}
