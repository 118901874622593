import React from 'react'
import { Image } from '@damen/ui'

import Container from '@/components/UI/Container'
import ContainerVertical from '@/components/UI/ContainerVertical'

import { ImageType, Spacing } from '@/types'

type Alignment = 'none' | 'left' | 'right'

interface Content {
  id: string
  bowWaveVariant?: Alignment
  image: ImageType
  spacing?: Spacing
}

interface Props {
  content: Content
}

const FullscreenImageSingle: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const image = {
    alt: content.image.alt,
    src: content.image.filename,
  }

  return (
    <ContainerVertical verticalSpacing={content.spacing}>
      <Container full>
        <Image.FullscreenSingle bowWave={content.bowWaveVariant} image={image} />
      </Container>
    </ContainerVertical>
  )
}

export default FullscreenImageSingle
