import React, { ComponentProps, ReactNode, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { theme } from '@damen/ui'

import DelayRender from './DelayRender'
import HighlightIcon from './HighlightIcon.svg'
import { HotspotButtonColor } from '../types'

/**
 * The highlight button has the following animation states:
 *
 * - New: it just appeared and will animate in
 * - Attention grabbing: it is randomly triggered to grab attention
 * - Idle: the idle animation just finished, and we need an animation to
 * restore to the normal (idle) state.
 * - Hover: the user hovered the button
 * - Active: the user clicked the button
 */

const getButtonColor = (color: HotspotButtonColor) => {
  switch (color) {
    case 'blue':
      return theme.colors.blue
    case 'white':
      return theme.colors.white
    case 'orange':
    default:
      return theme.colors.orange
  }
}

const getIconColor = (color: HotspotButtonColor) => {
  switch (color) {
    case 'white':
      return theme.colors.blue
    case 'blue':
    case 'orange':
    default:
      return theme.colors.white
  }
}

const Label = styled.div`
  font-weight: ${theme.typography.fontWeightMedium};
  font-size: ${theme.typography.fontSizeText}px;
  line-height: ${theme.typography.lineHeightTag};
  margin-bottom: -4px;

  display: none;
  @media screen and (${theme.mediaQueries.mediaQueryTablet}) {
    display: block;
  }
`

const LoadingIndicator = styled.div`
  background: ${theme.colors.blue};
  height: 2px;
  width: calc(var(--progress, 0) * 80px);
  margin-left: -8px; // The text has a 16px gap, but it looks weird for the loading indicator
`

const ring1Length = 522
const ringAppearCss = css`
  @media (prefers-reduced-motion: no-preference) {
    .ring1 {
      stroke-dasharray: ${ring1Length};
      stroke-dashoffset: -${ring1Length};
      transform: rotate(180deg);
      animation: ${keyframes`
          0% {
            stroke-dashoffset: -${ring1Length};
          }
          30% {
            transform: rotate(180deg);
          }
          to {
            stroke-dashoffset: 0;
            transform: rotate(0deg);
          }
        `} 1000ms ease-in-out forwards;
    }
  }
`
const appearCss = css`
  @media (prefers-reduced-motion: no-preference) {
    .background,
    .circle {
      opacity: 0;
      will-change: opacity;
      animation: ${keyframes`
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        `} 700ms ease-in-out forwards;
      animation-delay: 300ms;
    }
    ${ringAppearCss}
  }
`
const ringGrowAnimation = keyframes`
  from {
    r: 83;
    opacity: 1;
  }
  to {
    r: 120;
    opacity: 0;
  }
`
const innerRingGrowAnimation = keyframes`
  0% {
    r: 58;
    opacity: 1;
  }
  // Wait with transitioning the opacity so it isn't already slightly gone when
  // it first becomes visible
  20% {
    opacity: 1;
  }
  to {
    r: 120;
    opacity: 0;
  }
`
const idleAnimationDuration = 600
const idleAnimationTotalDuration = idleAnimationDuration + idleAnimationDuration * (400 / 600)
const grabAttentionCss = css`
  @media (prefers-reduced-motion: no-preference) {
    .ring1 {
      r: 83;
      animation: ${ringGrowAnimation} ${idleAnimationDuration}ms linear forwards;
    }
    .ring2 {
      r: 58;
      display: block;
      animation: ${innerRingGrowAnimation} ${idleAnimationDuration}ms linear forwards;
      animation-delay: ${idleAnimationDuration * (200 / 600)}ms;
    }
    .ring3 {
      r: 58;
      display: block;
      animation: ${innerRingGrowAnimation} ${idleAnimationDuration}ms linear forwards;
      animation-delay: ${idleAnimationDuration * (400 / 600)}ms;
    }
  }
`
const AnimatedHighlightIcon = styled(HighlightIcon).withConfig({
  shouldForwardProp: (prop) => !['state', 'color'].includes(prop),
})<{ state: HighlightButtonState; color: HotspotButtonColor }>`
  width: var(--icon-size);
  overflow: visible;

  .ring1,
  .ring2,
  .ring3 {
    transform-origin: center;
  }
  .ring2,
  .ring3 {
    display: none;
  }
  .icon {
    color: ${({ color }) => getIconColor(color)};
    fill: ${({ color }) => getIconColor(color)};

    path {
      fill: ${({ color }) => getIconColor(color)};
    }
  }

  ${({ state }) =>
    state === HighlightButtonState.New &&
    css`
      ${appearCss}
    `}

  ${({ state }) =>
    state === HighlightButtonState.GrabAttention &&
    css`
      ${grabAttentionCss}
    `}

  ${({ state }) =>
    state === HighlightButtonState.Idle &&
    css`
      ${ringAppearCss}
    `}
`

const buttonHoverCss = css`
  ${AnimatedHighlightIcon} {
    @media (prefers-reduced-motion: no-preference) {
      .circle,
      .chevron,
      .ring1 {
        transition: opacity 500ms;
      }
      .chevron {
        transition-delay: 100ms;
      }
      .background {
        transition: r 600ms;
      }
    }
    .chevron {
      opacity: 0;
    }
  }
  ${Label} {
    opacity: 0;
    @media (prefers-reduced-motion: no-preference) {
      transform: translateY(1em);
      transition: 800ms;
      transition-delay: 600ms;
    }
  }

  &:hover,
  &:active {
    ${AnimatedHighlightIcon} {
      .circle {
        // Remove the appear animation
        animation: none;
        opacity: 0;
      }

      .chevron {
        opacity: 1;
      }
      .background {
        r: 89;
      }
      .ring1,
      .ring2,
      .ring3 {
        opacity: 0;
      }
    }
    ${Label} {
      opacity: 1;
      transform: translateY(0%);
    }
  }
`

const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => prop !== 'color',
})<{ color: HotspotButtonColor }>`
  ${buttonHoverCss}
  color: ${({ color }) => getButtonColor(color)};
  display: flex;
  align-items: center;
  gap: ${theme.spacing.x2}px;
  border: 0;
  cursor: pointer;
  --padding: ${theme.spacing.x1}px;
  padding: var(--padding);
  background: none;

  --icon-size: ${theme.spacing.x3}px;

  transform: translate(
    calc((var(--icon-size) + var(--padding) * 2) / 2 * -1),
    calc((var(--icon-size) + var(--padding) * 2) / 2 * -1)
  );

  @media screen and (${theme.mediaQueries.mediaQueryTablet}) {
    --icon-size: ${theme.spacing.x4}px;
  }
`

interface Props extends ComponentProps<typeof Button> {
  children: ReactNode
  grabAttention?: boolean
  loadingProgress?: number
}

enum HighlightButtonState {
  New,
  GrabAttention,
  Idle,
}

const HighlightButton = ({ children, grabAttention, loadingProgress, color, ...others }: Props) => {
  const [state, setState] = useState<HighlightButtonState>(HighlightButtonState.New)
  useEffect(() => {
    if (grabAttention) {
      setState(HighlightButtonState.GrabAttention)
      setTimeout(() => {
        setState(HighlightButtonState.Idle)
      }, idleAnimationTotalDuration + 250)
    }
  }, [grabAttention])

  return (
    <Button type="button" color={color} {...others}>
      <AnimatedHighlightIcon state={state} color={color} />
      {loadingProgress === undefined ? (
        <Label>{children}</Label>
      ) : (
        <DelayRender delay={1000}>
          <LoadingIndicator
            style={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ['--progress' as any]: loadingProgress,
            }}
          />
        </DelayRender>
      )}
    </Button>
  )
}

export default HighlightButton
