import { css } from 'styled-components'
import { theme } from '@damen/ui'

export const SCROLLBAR_PX_WIDTH = '12px'
export const SCROLLBAR_TRACK_BACKGROUND = 'transparent'
export const SCROLLBAR_BLUE = theme.colors.blue
export const SCROLLBAR_BLUE_HOVER = theme.colors.blueDark
export const SCROLLBAR_WHITE = theme.colors.white
export const SCROLLBAR_WHITE_HOVER = theme.colors.silverGrey200

// If scrollbar styling should be adjusted, also take a look at: src/components/layout/Base/index.tsx

const scrollbarBase = css`
  &::-webkit-scrollbar {
    width: ${SCROLLBAR_PX_WIDTH};
  }

  &::-webkit-scrollbar-track {
    background: ${SCROLLBAR_TRACK_BACKGROUND};
  }
`

export const scrollbarWhite = css`
  ${scrollbarBase}

  scrollbar-color: ${SCROLLBAR_WHITE} ${SCROLLBAR_TRACK_BACKGROUND};

  &::-webkit-scrollbar-thumb {
    background-color: ${SCROLLBAR_WHITE};

    &:hover {
      background-color: ${SCROLLBAR_WHITE_HOVER};
    }
  }
`

export const scrollbarBlue = css`
  ${scrollbarBase}

  scrollbar-color: ${SCROLLBAR_BLUE} ${SCROLLBAR_WHITE};

  &::-webkit-scrollbar-thumb {
    background: ${SCROLLBAR_BLUE};

    &:hover {
      background-color: ${SCROLLBAR_BLUE_HOVER};
    }
  }

  &::-webkit-scrollbar-track {
    background: ${SCROLLBAR_WHITE};
  }
`
