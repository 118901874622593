import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { accessibility, legacyMediaQueries, colors, layout, typography } from '@damen/ui/lib/cjs/styles'
import Dropdown from './Dropdown'

const initialAnimation = { opacity: 0, y: 20 }

type Background = 'default' | 'light'

export interface Item {
  handle: string
  text: string
}

export interface Props {
  background?: Background
  initialValue?: string
  items: Item[]
  onTabClick?: (handle: string) => void
  inline?: boolean
  button?: React.ComponentType
}

const Default: React.FC<Props> = ({
  background = 'default',
  initialValue,
  items = [],
  onTabClick,
  inline,
  button: ButtonComponent,
}) => {
  const [activeItem, setActiveItem] = useState(items[0])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const onClick = () => setIsDropdownOpen(!isDropdownOpen)

  useEffect(() => {
    const newActive = items.find((toggle) => toggle.handle === initialValue)

    if (newActive && newActive !== activeItem) {
      setActiveItem(newActive)
    }
  }, [activeItem, initialValue, items])

  const handleOnTabSelect = (item: Item): void => {
    if (item === activeItem) return null

    setIsDropdownOpen(false)
    setActiveItem(item)

    if (onTabClick) { onTabClick(item.handle); return; }

    return null
  }

  return (
    <Wrapper background={background} inline={inline}>
      <Container>
        <Tabs
          animate={{ opacity: 1, y: 0, transition: { delay: inline ? 0 : 0.4, duration: inline ? 0 : 0.6 } }}
          initial={initialAnimation}
          inline={inline}
        >
          {items.map((item) => (
            <Tab
              isActive={item === activeItem}
              key={item.handle}
              onClick={() => handleOnTabSelect(item)}
              inline={inline}
            >
              {item.text}
            </Tab>
          ))}
        </Tabs>
        <MobileTabs inline={inline} isOpen={isDropdownOpen}>
          <Dropdown
            inline={inline}
            isOpen={isDropdownOpen}
            onClick={onClick}
            items={[
              ...items.map((item) => (
                <MobileTab
                  isActive={item === activeItem}
                  inline={inline}
                  key={item.handle}
                  onClick={() => handleOnTabSelect(item)}
                >
                  {item.text}
                </MobileTab>
              )),
              <ButtonWrapper key="MobileButton">{ButtonComponent && <ButtonComponent />}</ButtonWrapper>,
            ]}
            activeItem={activeItem}
          />
        </MobileTabs>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['background', 'inline'].includes(prop),
})<{ background: string; inline: boolean }>`
  width: 100%;
  padding: ${({ inline }) => (inline ? '' : '48px 16px')};

  @media ${legacyMediaQueries.md} {
    padding: ${({ inline }) => (inline ? '' : '48px 0 48px 80px')};
    background: ${({ background }) => (background === 'default' ? colors.white : colors.silverGrey200)};
  }

  @media ${legacyMediaQueries.lg} {
    padding: ${({ inline }) => (inline ? '' : '48px 0 48px 120px')};
  }
`

const Container = styled.div`
  display: flex;

  @media ${legacyMediaQueries.md} {
    justify-content: center;
  }

  @media (min-width: 1328px) {
    justify-content: end;
  }
`

const Tabs = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => !['inline'].includes(prop),
})<{ inline: boolean }>`
  position: relative;
  display: none;
  width: 100%;
  color: ${colors.marineBlack};
  border-bottom: ${({ inline }) => (inline ? '' : `solid 1px ${colors.greyDark}`)};

  @media ${legacyMediaQueries.md} {
    display: flex;
  }

  @media (min-width: 1328px) {
    margin-left: ${({ inline }) => (inline ? '' : `calc(calc(100% - 1328px) / 2)`)};
  }
`

const MobileTabs = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => !['inline', 'isOpen'].includes(prop),
})<{ inline: boolean; isOpen: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${colors.marineBlack};
  border-bottom: ${({ inline }) => (inline ? '' : `solid 1px ${colors.greyDark}`)};

  ${({ inline, isOpen }) =>
    inline && isOpen
      ? css`
          &::after {
            content: '';
            position: absolute;
            top: 62px;
            height: 100dvh;
            width: 100%;
            opacity: 0.6;
            background: #000;
          }
        `
      : css``}

  @media ${legacyMediaQueries.md} {
    display: none;
  }
`

const MobileTab = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isActive', 'inline'].includes(prop),
})<{ isActive: boolean; inline: boolean }>`
  cursor: pointer;
  padding: 12px 0;
  color: ${({ isActive }) => (isActive ? colors.blue : colors.grey)};
  font-size: ${typography.fontSizeTextSmall}px;
  line-height: ${typography.lineHeightTextSmall};
  letter-spacing: 0.4px;
  font-weight: ${typography.fontWeightMedium};
  background: transparent;
  border: none;

  &:hover,
  &:focus {
    color: ${colors.marineBlack};
  }

  &:focus {
    outline: ${accessibility.outline};
    outline-offset: 8px;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ inline, isActive }) =>
    inline
      ? css`
          color: ${isActive ? colors.blue : colors.marineBlack};

          &:hover,
          &:focus {
            color: ${colors.blue};
          }
        `
      : css``}
`

const Tab = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isActive', 'inline'].includes(prop),
})<{ isActive: boolean; inline: boolean }>`
  cursor: pointer;
  margin: 0 40px 0 0;
  padding: 0 0 12px 0;
  color: ${({ isActive }) => (isActive ? colors.marineBlack : colors.grey)};
  font-size: ${typography.fontSizeTextSmall}px;
  line-height: ${typography.lineHeightTextSmall};
  letter-spacing: 0.4px;
  font-weight: ${typography.fontWeightMedium};
  background: transparent;
  border: none;
  border-bottom: ${({ isActive }) => (isActive ? `4px solid ${colors.marineBlack}` : '4px solid transparent')};

  &:hover,
  &:focus {
    color: ${colors.marineBlack};
  }

  &:focus {
    outline: ${accessibility.outline};
    outline-offset: 8px;
  }

  &:last-child {
    margin-right: 0;
  }

  ${({ inline, isActive }) =>
    inline
      ? css`
          border-bottom: 0;
          padding: 0;
          color: ${isActive ? colors.blue : colors.marineBlack};
          font-size: ${typography.fontSizeLabel}px;

          &:hover,
          &:focus {
            color: ${colors.blue};
          }
        `
      : css``}
`

const ButtonWrapper = styled.div`
  margin-top: ${layout.spacingM};
`

export default Default
