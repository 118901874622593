import { Action, theme } from '@damen/ui'
import styled, { css } from 'styled-components'
import { ButtonProps, Color } from '../../types'

export const ShadowStyle = `
  content: '';
  position: absolute;
  z-index: -1;
  width: 90%;
  height: 80%;
  filter: blur(10px);
  border-radius: 17px;
  left: 50%;
  bottom: -7%;
  transform: translate3d(-50%, 0, 0);
  background-color: #000000;
  transition: all ${theme.timing.fast};
`
export const getButtonStyles = (type: Color) => {
  switch (type) {
    case 'white':
      return `
        color: ${theme.colors.blue};
        background-color: ${theme.colors.white};
        &:hover,
        &:focus {
          background-color: ${theme.colors.blueIce};
        }
        &:disabled {
          color: ${theme.colors.blue};
          background-color: #5068c5;
        }
      `
    case 'orange':
      return `
        color: ${theme.colors.white};
        background-color: ${theme.colors.orange};
        &:hover,
        &:focus {
          background-color: ${theme.colors.orangeDark};
        }
        &:disabled {
          color: ${theme.colors.white};
          background-color: ${theme.colors.orange};
          opacity: 0.5;
        }
      `
    case 'whiteBlue':
      return `
        color: ${theme.colors.blue};
        background-color: ${theme.colors.white};
        border: 1px solid ${theme.colors.blue};
        padding: 10px 18px !important;
        &:hover,
        &:focus {
          background-color: ${theme.colors.blueIce} !important;
        }
        &:disabled {
          opacity: 0.5;
        }
      `
    case 'career':
      return `
        color: ${theme.colors.white};
        background-color: transparent;
        border: 3px solid ${theme.colors.white};

        &:hover,
        &:focus {
          background-color: ${theme.colors.white};
          color: ${theme.colors.marineBlack};
        }

        &:disabled {
          opacity: 0.5;
        }
      `
    default:
      return `
        color: ${theme.colors.white};
        background-color: ${theme.colors.blue};
        &:hover,
        &:focus {
          background-color: #193ca5;
        }
        &:disabled {
          background-color: ${theme.colors.greyDark};
        }

        g {
          fill: #fff;
        }
      `
  }
}

export const ButtonShadowStyle = `
  &:before {
    ${ShadowStyle}
  }
`
const { LinkStyle } = Action

export const ButtonStyles = css<ButtonProps>`
  cursor: pointer;
  position: relative;
  font-family: ${theme.fonts.body.style.fontFamily};
  font-weight: ${theme.typography.fontWeightMedium};
  border: none;
  transition: background-color ${theme.timing.fast};
  width: fit-content;

  &:focus {
    outline: ${theme.accessibility.outline};
    outline-offset: 1px;
  }

  &:hover,
  &:focus {
    svg {
      transform: translateX(4px);
    }
  }

  ${({ hasIcon }) =>
    hasIcon
      ? `
        display: inline-flex;
        align-items: center;
      `
      : `
        display: inline-block;
        align-items: initial;
      `}
  ${({ hideText, isSmall }) =>
    isSmall
      ? `
        font-size: ${theme.typography.fontSizeTextSmall}px;
        line-height: ${theme.typography.lineHeightTextSmall};
        letter-spacing: 0.4px;
        padding: ${hideText ? '10px 11px' : '4px 16px'};
      `
      : `
        font-size: ${theme.typography.fontSizeText}px;
        line-height: 1;
        letter-spacing: 0.5px;
        padding: ${hideText ? '12px 13px' : '16px 24px'};
      `}
  ${({ rounded, shadow }) =>
    rounded
      ? `
        border-radius: 24px;
        ${shadow ? ButtonShadowStyle : ''}
      `
      : `
        border-radius: 0;
      `}
${({ buttonType, colorType }) => {
    if (buttonType === 'text') {
      return `
      ${LinkStyle};
      &:focus {
        outline: ${theme.accessibility.outline};
        outline-offset: 8px;
      }
    `
    }
    return getButtonStyles(colorType)
  }}
${({ buttonType, hasIcon }) =>
    buttonType === 'text' &&
    hasIcon &&
    `
  &:before {
    display: none;
  }
`}
`
export const StyledLink = styled.span.withConfig({
  shouldForwardProp: (prop) =>
    !['buttonType', 'colorType', 'dataAttrs', 'hasIcon', 'hideText', 'isSmall', 'rounded', 'shadow', 'action'].includes(
      prop,
    ),
})<ButtonProps>`
  a {
    ${ButtonStyles}
    text-decoration: none;
  }
`
