import dynamic from 'next/dynamic'

const Contact = dynamic(() => import('./Contact'), { ssr: false })
const DepartmentContact = dynamic(() => import('./DepartmentContact'), { ssr: false })
const DepartmentNewsletter = dynamic(() => import('./DepartmentNewsletter'), { ssr: false })
const Donation = dynamic(() => import('./Donation'), { ssr: false })
const Download = dynamic(() => import('./Download'), { ssr: false })
const Newsletter = dynamic(() => import('./Newsletter'), { ssr: false })
const Feedback = dynamic(() => import('./Feedback'), { ssr: false })
const SurveyMonkeyModal = dynamic(() => import('./SurveyMonkey'), { ssr: false })
const SurveyMonkeyPopupModal = dynamic(() => import('./SurveyMonkeyPopup'), { ssr: false })

export default {
  Contact,
  DepartmentContact,
  DepartmentNewsletter,
  Donation,
  Download,
  Newsletter,
  Feedback,
  SurveyMonkeyModal,
  SurveyMonkeyPopupModal,
}
