import React from 'react'
import { Icon, Popover, theme } from '@damen/ui'
import { Color } from '@damen/ui/lib/esm/components/Popover/index'

export const SustainableIcon: React.FC<{ iconType: string; height?: number; width?: number }> = ({
  iconType,
  height = 16,
  width = 19,
}) => {
  if (iconType === 'E3') {
    return <Icon.SustainableMarkE3 height={height} width={width} />
  }
  if (iconType === 'Green') {
    return <Icon.SustainableMarkGreen height={height} width={width} />
  }
  if (iconType === 'Concept') {
    return <Icon.Lighthouse height={height} width={width} fill={theme.colors.orange} />
  }
  return null
}

export interface SustainableMarkProps {
  icon: string
  title: string
  text: string
  color: string
}

export const getSustainableMark = (sustainableMark: string, height = 16, width = 19): React.ReactNode => {
  return <SustainableIcon iconType={sustainableMark} height={height} width={width} />
}

export const getSustainableMarkWithPopover = (
  sustainableMark: SustainableMarkProps,
  height = 16,
  width = 19,
): React.ReactNode => {
  if (!sustainableMark) return null

  const color = sustainableMark.color as Color
  // Add additional 5 px for popover padding on frontend to display arrow on popover
  const iconHeight = height + 5
  return (
    <Popover
      title={sustainableMark.title}
      text={sustainableMark.text}
      iconComponent={() => <SustainableIcon iconType={sustainableMark.icon} height={height} width={width} />}
      color={color}
      iconHeight={iconHeight}
    />
  )
}
