import React, { ReactNode, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { TransitionGroup, Transition } from 'react-transition-group'
import { EXITED, EXITING } from 'react-transition-group/Transition'
import { Heading, NextImage, theme } from '@damen/ui'
import Link from '@/components/Link'
import Container from '../Container'
import Portal from '../Portal'
import useDimensions from '@/utils/useDimensions'
import IntegratedServicesModal from './components/IntegratedServicesModal'
import { IntegratedServicesHighlight, ImageInfo } from './types'

const Title = styled(Heading.HeadingTwo)`
  margin-top: 0;
  margin-bottom: ${theme.spacing.x2}px;
`

const Text = styled.div`
  margin-bottom: ${theme.spacing.x3}px;
  line-height: ${theme.typography.lineHeightText};
`

const StyledNextImage = styled(NextImage)`
  width: 100%;
  height: auto;
`

const ContentLeft = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing.x5}px ${theme.spacing.x4}px;
  color: ${theme.colors.white};

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    width: 50%;
    padding: ${theme.spacing.x9}px ${theme.spacing.x8}px;
  }
`

const Content = styled.div`
  position: relative;
  text-align: center;

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    max-width: 374px;
    text-align: left;
  }
`

const ContentRight = styled.div`
  position: relative;

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    width: 50%;
  }
`

const ContentRow = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    flex-direction: row;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${theme.colors.marineBlack};
    opacity: 0.7;
  }

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    margin-right: -164px;
    margin-top: -116px;
    width: calc(100% + 164px);
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.marineBlack};
`

const ContentContainer = styled.div`
  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    margin-right: 164px;
    margin-top: 116px;
  }
`

const Backdrop = styled.div(
  () => css`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
  `,
)

const StyledBackdrop = styled(Backdrop)`
  opacity: 0;
  will-change: opacity;
  animation: ${keyframes`
    to {
      opacity: 1;
    }
  `} 800ms ease-in-out forwards;
`

interface Props {
  title: ReactNode
  text: ReactNode
  linkText: string
  image: ImageInfo
  highlight: IntegratedServicesHighlight[]
}

const IntegratedServices: React.FC<Props> = ({ title, text, linkText, image, highlight }) => {
  const [active, setActive] = useState(false)
  const [imagePositionerRef, { left, top, width, height }] = useDimensions()
  const highlightData = highlight[0]

  const exitTransitionDuration = 120

  return (
    <>
      <Container>
        <ContentContainer>
          <ContentWrapper>
            <ContentRow>
              <ContentLeft>
                <Content>
                  <Title>{title}</Title>
                  <Text>{text}</Text>
                  <Link.VNext
                    type="button"
                    color="orange"
                    text={linkText}
                    onClick={() => {
                      setActive(true)
                    }}
                  />
                </Content>
              </ContentLeft>
              <ContentRight>
                <ImageWrapper ref={imagePositionerRef}>
                  <StyledNextImage {...image} aspectRatio="16:9" width={662} height={372} />
                </ImageWrapper>
              </ContentRight>
            </ContentRow>
          </ContentWrapper>
        </ContentContainer>
      </Container>

      {/* MODAL */}
      <TransitionGroup component={null}>
        {active && left && top && (
          <Transition timeout={exitTransitionDuration}>
            {(transitionState) => (
              <Portal>
                <div
                  style={{
                    transition:
                      transitionState === EXITING || transitionState === EXITED
                        ? `opacity ${exitTransitionDuration}ms ease-in`
                        : undefined,
                    opacity: transitionState === EXITING || transitionState === EXITED ? 0 : 1,
                  }}
                >
                  <StyledBackdrop onClick={() => setActive(false)} />
                  <IntegratedServicesModal
                    style={{
                      left: left + window.scrollX,
                      top: top + window.scrollY,
                      width,
                      height,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      ['--scroll-y' as any]: `${window.scrollY}px`,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      ['--image-top' as any]: `${0 + window.scrollY}px`,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      ['--image-height' as any]: `100vh`,
                    }}
                    id={highlightData.id}
                    title={highlightData.title}
                    subTitle={highlightData.subtitle}
                    image={highlightData.image}
                    buttons={highlightData.buttons}
                    close={() => setActive(false)}
                    data-testid="integrated-services.modal"
                  />
                </div>
              </Portal>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </>
  )
}

export default IntegratedServices
