import React, { useEffect, useState } from 'react'
import { Numbers as NumbersComponent } from '@damen/ui'
import { useInView } from 'react-intersection-observer'

import { Container } from '@/components/UI'

interface Item {
  id: string
  label: string
  number: number
  prefix: string
  suffix: string
  title: string
}

interface NumbersContent {
  id: string
  items: Item[]
  title: string
}

interface Content {
  content: NumbersContent
}

const Numbers: React.FC<React.PropsWithChildren<Content>> = ({ content }) => {
  const [ref, inView] = useInView({ rootMargin: '-50%' })
  const [startAnimation, setAnimation] = useState(false)

  const items = content.items.map((item) => ({
    label: item.label,
    number: item.number,
    prefix: item.prefix,
    suffix: item.suffix,
    title: item.title,
  }))

  useEffect(() => {
    if (inView && !startAnimation) {
      setAnimation(true)
    }
  }, [inView, startAnimation])

  return (
    <Container background="light">
      <div ref={ref}>
        <NumbersComponent.Default blocks={items} startAnimation={startAnimation} title={content.title} />
      </div>
    </Container>
  )
}

export default Numbers
