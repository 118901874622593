import React from 'react'
import Modal from 'react-modal'
import styled from 'styled-components'
import { Icon, theme } from '@damen/ui'

import { DefaultModalProps } from '../types'
import { scrollbarBlue } from '@/styles/scrollbars'

const styles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 900,
  },
  content: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    padding: 0,
    border: 0,
    outline: 'none',
  },
}

const ReactModalAdapter: React.FC<React.PropsWithChildren<DefaultModalProps>> = ({
  background,
  closeColorMobile,
  className,
  children,
  hasPadding = true,
  footer,
  isOpen,
  onClose,
}) => {
  const wrapperElement = React.useRef<HTMLDivElement>(null)

  const handleClick = (event: any) => {
    if (!wrapperElement.current) return

    if (event.target === wrapperElement.current || wrapperElement.current.contains(event.target)) return

    onClose()
  }

  return (
    <Modal
      ariaHideApp
      className={`${className}__content`}
      closeTimeoutMS={theme.timing.numDefault}
      contentLabel="Modal"
      isOpen={isOpen}
      overlayClassName={`${className}__overlay`}
      onRequestClose={onClose}
      portalClassName={className}
      role="dialog"
      style={styles}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
    >
      <Container onClick={(event) => handleClick(event)}>
        <Wrapper background={background} ref={wrapperElement}>
          <Header>
            <CloseButton colorMobile={closeColorMobile} onClick={onClose}>
              <Icon.Close height={17} width={18} fill={theme.colors.blue} />
            </CloseButton>
          </Header>
          <Content hasPadding={hasPadding}>{children}</Content>
          {footer && <Footer>{footer}</Footer>}
        </Wrapper>
      </Container>
    </Modal>
  )
}

const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    background-color: transparent;
    transition: background-color 300ms ease;

    &.ReactModal__Overlay--after-open {
      background-color: rgba(0, 0, 0, 0.75);
    }

    &.ReactModal__Overlay--before-close {
      background-color: transparent;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    transition-duration: ${theme.timing.fast};
    transition-timing-function: ease-in-out;
    transition-property: transform;
    transform: translateY(100%);
    outline: none;

    &.ReactModal__Content--after-open {
      transform: translateY(0);
    }

    &.ReactModal__Content--before-close {
      transform: translateY(100%);
    }
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;

  @media ${theme.legacyMediaQueries.sm} {
    justify-content: flex-end;
  }
`

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['background'].includes(prop),
})<{ background: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  position: relative;

  background-color: ${({ background }) => background || theme.colors.blueIce};

  @media ${theme.legacyMediaQueries.mdMax} {
    width: 100%;
    max-width: 100%;
  }
`

const Header = styled.div`
  display: inline-flex;
  z-index: 999;
  padding: 16px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;

  @media ${theme.legacyMediaQueries.md} {
    padding: 40px 32px 12px;
  }
`

const CloseButton = styled.button.withConfig({
  shouldForwardProp: (prop) => !['colorMobile'].includes(prop),
})<{ colorMobile?: string }>`
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  transition: ${theme.timing.fast};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  ${({ colorMobile }) => `
  @media ${theme.legacyMediaQueries.lgMax} {

    svg {
      fill: ${colorMobile} !important;

      path {
        fill: ${colorMobile} !important;
      }
    }
  }
`}

  :hover,
  :focus {
    outline: none;
    background: ${theme.colors.white};
  }

  @media ${theme.legacyMediaQueries.md} {
    order: 1;
  }
`

const Content = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hasPadding'].includes(prop),
})<{ hasPadding: boolean }>`
  flex: 1;
  overflow-y: auto;

  ${scrollbarBlue}

  ${({ hasPadding }) =>
    hasPadding &&
    `

    padding: 32px 0 24px;

    @media ${theme.legacyMediaQueries.md} {
      padding: 104px 0 32px;
    }
  `}
`

const Footer = styled.div`
  transition: border-color 300ms ease;
  padding: 24px;

  @media ${theme.legacyMediaQueries.md} {
    padding: 32px 42px;
  }
`

export default StyledModal
