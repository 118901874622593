import { MutableRefObject } from 'react'
import { gsap, Linear, Power2 } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

interface AnimationParams {
  containerRef: MutableRefObject<HTMLDivElement>
  delay: number
  lockScroll?: boolean
}

export const setAnimations = ({ containerRef, delay, lockScroll }: AnimationParams) => {
  if (typeof window === 'undefined' || !containerRef.current) return null

  const { innerHeight, scrollY } = window

  ScrollTrigger.refresh()

  const scrollTriggerConfig = {
    end: `${innerHeight}px`,
    invalidateOnRefresh: true,
    start: `0`,
    trigger: containerRef.current,
  }

  if (scrollY < 50 && lockScroll) {
    // Intro animation
    gsap.to('body', {
      overflow: 'hidden',
    })

    // Intro animation
    gsap.to('body', {
      overflow: null,
      delay: delay + 1,
    })
  }

  // Stage One
  gsap.to(`.intro-title`, {
    scale: 1,
    delay,
    duration: 2,
    ease: Power2.easeInOut,
  })

  // Stage One
  gsap.to(`.intro`, {
    opacity: 0,
    ease: Linear.easeIn,
    scrollTrigger: {
      ...scrollTriggerConfig,
      end: `${innerHeight * 0.3}px`,
      start: `${innerHeight * 0.2}px`,
      scrub: 0.8,
    },
  })

  // Stage One
  gsap.to(`.intro-subtitle`, {
    opacity: 1,
    y: 0,
    delay: delay + 0.5,
    duration: 1.2,
    ease: Power2.easeInOut,
  })

  // Stage One
  gsap.to(`.background`, {
    scale: 1,
    delay: delay + 0.2,
    duration: 3.5,
    ease: Power2.easeInOut,
  })

  // Stage Two
  gsap.to(`.background`, {
    y: -innerHeight * 0.05,
    ease: Linear.easeIn,
    scrollTrigger: {
      ...scrollTriggerConfig,
      end: `${innerHeight}px`,
      start: `${innerHeight * 0.5}px`,
      scrub: 0.8,
    },
  })

  // Stage Two
  gsap.to(`.message`, {
    opacity: 1,
    y: 0,
    ease: Linear.easeIn,
    scrollTrigger: {
      ...scrollTriggerConfig,
      end: `${innerHeight * 0.8}px`,
      start: `${innerHeight * 0.5}px`,
      scrub: 0.8,
    },
  })

  // Stage Two
  return gsap.to(`.overlay`, {
    opacity: 0.6,
    ease: Linear.easeIn,
    scrollTrigger: {
      ...scrollTriggerConfig,
      end: `${innerHeight * 0.4}px`,
      start: `${innerHeight * 0.2}px`,
      scrub: 0.8,
    },
  })
}
