import React, { ReactNode, useEffect, useState } from 'react'

interface Props {
  children: ReactNode
  delay: number
}

const DelayRender = ({ children, delay }: Props) => {
  const [render, setRender] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setRender(true)
    }, delay)
    return () => clearTimeout(timer)
  }, [delay])

  if (!render) {
    return null
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export default DelayRender
