import React from 'react'
import { Storytell, theme } from '@damen/ui'

import Link from '@/components/Link'
import { Container, ContainerVertical } from '@/components/UI'

import { Background, ImageType, Spacing } from '@/types'
import { StoryblokLink } from '@/queries/schema.generated'

interface Item {
  id: string
  img: ImageType
  link: StoryblokLink
  linkText: string
  overline: string
  text: string
  title: string
}

interface Content {
  id: string
  background: Background
  items: Item[]
  spacing?: Spacing
}

interface Props {
  content: Content
}

const StepByStep: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const stories = content.items.map((item) => {
    return {
      buttonComponent: item.linkText && (() => <Link.VNext type="button" link={item.link} text={item.linkText} />),
      id: item.id,
      image: {
        alt: item.img.alt,
        src: item.img.filename,
        sizes: `${theme.legacyMediaQueries.md} 50vw, 100vw`,
      },
      overline: item.overline,
      text: item.text,
      title: item.title,
    }
  })

  return (
    <ContainerVertical verticalSpacing={content.spacing}>
      <Container background={content.background} full>
        <Storytell.Slider stories={stories} />
      </Container>
    </ContainerVertical>
  )
}

export default StepByStep
