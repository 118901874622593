import React, { useId } from 'react'

import { SkeletonLoader } from '@/components/UI'
import { PaginationWrapper, PaginationItem, PaginationNavigationWrapper } from './styles'

const pages = 5

const Pagination: React.FC = () => {
  const id = useId()
  return (
    <PaginationWrapper>
      {new Array<number>(pages).map((_item, index) => {
        return (
          <PaginationItem key={`${id}paginationItem${index}`}>
            <SkeletonLoader width="32px" height="32px" circle />
          </PaginationItem>
        )
      })}
      <PaginationNavigationWrapper>
        <SkeletonLoader width="32px" height="32px" circle />
        <SkeletonLoader width="32px" height="32px" circle />
      </PaginationNavigationWrapper>
    </PaginationWrapper>
  )
}

export default Pagination
