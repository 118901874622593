import React from 'react'
import styled, { css } from 'styled-components'
import { theme } from '@damen/ui'
import Link from '@/components/Link'
import { StoryblokLink } from '@/queries/schema.generated'
import { UnderlineStyle } from '../styles'

const StyledLink = styled(Link.VNext).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{
  isActive: boolean
}>`
  &:focus {
    color: ${theme.colors.blue};
    outline: ${theme.accessibility.outline};
    outline-offset: 8px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${UnderlineStyle}
      color: ${theme.colors.blue} !important;
      font-weight: ${theme.typography.fontWeightMedium};
    `}
`

const CurrentPageItem = styled.span.withConfig({
  shouldForwardProp: (prop) => !['variant'].includes(prop),
})<{ variant: Variant }>`
  font-size: ${theme.typography.fontSizeText}px;
  font-weight: ${theme.typography.fontWeightMedium};
  color: inherit;
  letter-spacing: 0.5px;
  color: ${theme.colors.blue};

  ${({ variant }) => variant === 'support' && `font-size: ${theme.typography.fontSizeTextXSmall}px;`}
`

type Variant = 'default' | 'support'
interface Props {
  link: StoryblokLink
  title: string
  onClick?: () => void
  isActive?: boolean
  variant?: Variant
  currentPage: string
}

const MenuLinkItem: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  link,
  onClick,
  isActive,
  variant = 'default',
  currentPage,
}) => {
  const isCurrentPage = link.url === currentPage

  if (isCurrentPage && link && !onClick) {
    return <CurrentPageItem variant={variant}>{title}</CurrentPageItem>
  }

  return (
    <StyledLink link={link} isActive={isActive} type="link" onClick={onClick} text={title} prefetch={false}>
      {title}
    </StyledLink>
  )
}

export default MenuLinkItem
