import { useEffect, useState } from 'react'

const useIsAtTop = () => {
  const [isAtTop, setIsAtTop] = useState(true)
  useEffect(() => {
    const handleScroll = () => {
      // eslint-disable-next-line prefer-destructuring
      const scrollY = window.scrollY

      // scrollY may temporarily be less than 0 on mac due to "overscrolling"
      setIsAtTop(scrollY <= 0)
    }

    handleScroll()
    document.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    // eslint-disable-next-line consistent-return
    return () => document.removeEventListener('scroll', handleScroll)
  }, [])

  return isAtTop
}

export default useIsAtTop
