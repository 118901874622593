import React from 'react'
import { css, styled } from 'styled-components'
import { theme } from '@damen/ui'
import { SiteNavigationSubItemsGroup, StoryblokLink } from '@/queries/schema.generated'
import Link from '@/components/Link'
import { UnderlineStyle } from '../styles'

const StyledLink = styled(Link.VNext).withConfig({
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<{
  isActive: boolean
}>`
  &:focus {
    color: ${theme.colors.blue};
    outline: ${theme.accessibility.outline};
    outline-offset: 8px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${UnderlineStyle}
      color: ${theme.colors.blue} !important;
    `}
`

const toggleMenuOverlayAndFirstColumn = (
  subItems: SiteNavigationSubItemsGroup[],
  link: StoryblokLink,
  activeItem: string,
  setActiveItem: React.Dispatch<React.SetStateAction<string>>,
): (() => void) => {
  return subItems?.length > 0
    ? () => {
        if (link.url === activeItem || activeItem.startsWith(link.url)) {
          setActiveItem('')
        } else {
          setActiveItem(link.url)
        }
      }
    : undefined
}

interface MainMenuItemProps {
  id: string
  activeItem: string
  link: StoryblokLink
  setActiveItem: React.Dispatch<React.SetStateAction<string>>
  title: string
  subItems: SiteNavigationSubItemsGroup[]
  isMenuOpen: boolean
  currentPage: string
}

const isMainItemActive = (activeItem: string, currentPage: string, link: StoryblokLink, isMenuOpen: boolean) => {
  // Check if the link is active
  // The currentPage check works as follows: 
  // - currentPage is structured as /vessels/tugs/asd-tugs
  // - a split gives us ['', 'vessels', 'tugs', 'asd-tugs']
  // - on this level in the menu we have links like /vessels
  return (link.url === activeItem || activeItem.startsWith(link.url) || link.url === currentPage.split('/').slice(0, 2).join('/') && isMenuOpen)
}

const MobileMainMenuItem: React.FC<React.PropsWithChildren<MainMenuItemProps>> = ({
  activeItem,
  isMenuOpen,
  setActiveItem,
  link,
  title,
  subItems,
  currentPage,
  ...props
}) => {
  if (link.url === currentPage && !subItems) {
    return title
  }

  return (
    <StyledLink
      link={link}
      isActive={isMainItemActive(activeItem, currentPage, link, isMenuOpen)}
      type="link"
      onClick={toggleMenuOverlayAndFirstColumn(subItems, link, activeItem, setActiveItem)}
      text={title}
      prefetch={false}
      {...props}
    >
      {title}
    </StyledLink>
  )
}

export default MobileMainMenuItem
