export const parseBreakpoint = (mq: string) => {
  const breakpoint = /(\d+)/.exec(mq)

  return breakpoint ? parseInt(breakpoint[0], 10) : null
}

export const trimAndLowerCaseString = (value: string) => value.replace(/ /g, '_').toLocaleLowerCase()

export function hexToRgb(hex: string, options: { alpha?: number; format?: 'array' | 'css' }) {
  // https://github.com/sindresorhus/hex-rgb
  const hexCharacters = 'a-f\\d'
  const match3or4Hex = `#?[${hexCharacters}]{3}[${hexCharacters}]?`
  const match6or8Hex = `#?[${hexCharacters}]{6}([${hexCharacters}]{2})?`
  const nonHexChars = new RegExp(`[^#${hexCharacters}]`, 'gi')
  const validHexSize = new RegExp(`^${match3or4Hex}$|^${match6or8Hex}$`, 'i')
  let newHex = hex

  if (typeof hex !== 'string' || nonHexChars.test(hex) || !validHexSize.test(hex)) {
    throw new TypeError('Expected a valid hex string')
  }

  newHex = newHex.replace(/^#/, '')
  let alphaFromHex = 1

  if (newHex.length === 8) {
    alphaFromHex = Number.parseInt(newHex.slice(6, 8), 16) / 255
    newHex = newHex.slice(0, 6)
  }

  if (newHex.length === 4) {
    alphaFromHex = Number.parseInt(newHex.slice(3, 4).repeat(2), 16) / 255
    newHex = newHex.slice(0, 3)
  }

  if (newHex.length === 3) {
    newHex = newHex[0] + newHex[0] + newHex[1] + newHex[1] + newHex[2] + newHex[2]
  }

  const number = Number.parseInt(newHex, 16)
  // eslint-disable-next-line no-bitwise
  const red = number >> 16
  // eslint-disable-next-line no-bitwise
  const green = (number >> 8) & 255
  // eslint-disable-next-line no-bitwise
  const blue = number & 255
  const alpha = typeof options.alpha === 'number' ? options.alpha : alphaFromHex

  if (options.format === 'array') {
    return [red, green, blue, alpha]
  }

  if (options.format === 'css') {
    const alphaString = alpha === 1 ? '' : ` / ${Number((alpha * 100).toFixed(2))}%`
    return `rgb(${red} ${green} ${blue}${alphaString})`
  }

  return { red, green, blue, alpha }
}
