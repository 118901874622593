import { isNullOrUndefined } from './nullUtils'
import { StoryblokLink } from '@/queries/schema.generated'

const HTTP_HTTPPREFIX = 'http://'
const HTTP_HTTPSPREFIX = 'https://'
const HTTP_NOPROTOCOLPREFIX = '//'
const WWW = 'www'
const SLASH = '/'

export const ensureStartsWithSlash = (url: string) => (url.startsWith(SLASH) ? url : `${SLASH}${url}`)

export const formatUrl = (url: string) => {
  return formatInternalUrl(url)
}

export const formatStoryblokLink = (link: StoryblokLink) => {
  if (link.linktype === 'url') {
    return formatExternalUrl(link.url)
  }
  if (link.linktype === 'story') {
    return formatInternalUrl(link.cachedUrl ?? link.url)
  }
  return link.url ?? ''
}

export const formatInternalUrl = (url?: string | null) => {
  if (url === '' || !url) {
    return ''
  }
  if (url === 'catalogue' || url.startsWith('catalogue/')) {
    // Redirects are not picked up in next/link:
    // https://github.com/vercel/next.js/issues/16928
    // See the comment here which explains more about how redirects work: https://stackoverflow.com/questions/72542435/make-nextjs-link-respect-the-default-redirects-middleware#comments-72542435
    if (url === 'catalogue') {
      return url.replace('catalogue', 'vessels')
    }
    if (url.startsWith('catalogue/')) {
      return url.replace('catalogue/', 'vessels/')
    }
  }
  if (url.startsWith(SLASH)) {
    return url
  }
  if (url.startsWith(HTTP_HTTPPREFIX)) {
    return url.replace(HTTP_HTTPPREFIX, HTTP_HTTPSPREFIX)
  }
  if (url.startsWith(HTTP_HTTPSPREFIX)) {
    return url
  }
  if (url.startsWith(SLASH)) {
    return url
  }
  if (url.startsWith(WWW)) {
    return `${HTTP_HTTPSPREFIX}${url}`
  }
  return `${SLASH}${url}`
}

export const formatExternalUrl = (url?: string | null) => {
  if (url === '' || !url ) {
    return ''
  }
  if (url === 'catalogue' || url.startsWith('catalogue/')) {
    if (url === 'catalogue') {
      return url.replace('catalogue', 'vessels')
    }
    if (url.startsWith('catalogue/')) {
      return url.replace('catalogue/', 'vessels/')
    }
  }
  if (url.startsWith(HTTP_HTTPPREFIX)) {
    return url.replace(HTTP_HTTPPREFIX, HTTP_HTTPSPREFIX)
  }
  if (url.startsWith(HTTP_HTTPSPREFIX)) {
    return url
  }
  if (url.startsWith(HTTP_NOPROTOCOLPREFIX)) {
    return `https:${url}`
  }
  if (url.startsWith(SLASH)) {
    return url
  }
  if (url.startsWith(WWW)) {
    return `${HTTP_HTTPSPREFIX}${url}`
  }
  return url
}

export const isExternalUrl = (url: string) => {
  return (
    !isNullOrUndefined(url) &&
    (url.startsWith(HTTP_HTTPPREFIX) || url.startsWith(HTTP_HTTPSPREFIX) || url.startsWith(HTTP_NOPROTOCOLPREFIX))
  )
}

export const isValidLink = (urlObject: string | StoryblokLink) => {
  // Null is Invalid
  if (isNullOrUndefined(urlObject)) {
    return false
  }
  // Hardcoded Url must have length 1
  if (typeof urlObject === 'string') {
    return urlObject.length > 0
  }
  // Storyblok link must have A value or be empty string (/ home)
  const url = urlObject.cachedUrl ?? urlObject.url

  if (!isNullOrUndefined(url) && url.length > 0) {
    return true
  }
  return false
}
