import React from 'react'
import styled from 'styled-components'
import { NextImage, theme } from '@damen/ui'

import Container from '@/components/UI/Container'

import { Background, ImageType, PageType } from '@/types'

interface Content {
  id: string
  background: Background
  img: ImageType
}

interface Props {
  content: Content
  maxWidth: number
  pageType: PageType
}

const DefaultImage: React.FC<React.PropsWithChildren<Props>> = ({ content, maxWidth, pageType }) => {
  return (
    <Container background={content.background} maxWidth={maxWidth} pageType={pageType}>
      <Image
        src={content.img.filename}
        alt={content.img.alt}
        fill
        style={{ objectFit: 'contain' }}
        sizes={`${theme.legacyMediaQueries.md} calc(100vw - 160px), ${theme.legacyMediaQueries.lg} calc(100vw - 240px) , 100vw`}
      />
    </Container>
  )
}

const Image = styled(NextImage)`
  position: relative !important;
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
`

export default DefaultImage
