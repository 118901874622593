import React, { ReactElement } from 'react'
import { BodyContent, Heading, Icon, theme } from '@damen/ui'
import styled from 'styled-components'

import Link from '@/components/Link'
import Container from '@/components/UI/Container'

import { Background, PageType } from '@/types'
import { renderRichText } from '@/utils/richTextUtils'
import { StoryblokLink } from '@/queries/schema.generated'

type AlignmentType = 'left' | 'center'

interface LinkType extends StoryblokLink {
  text: string
  type: 'default' | 'text'
}

interface Content {
  id: string
  alignment: AlignmentType
  background: Background
  content: any
  hadPadding?: boolean
  intro?: boolean
  label: string
  link: LinkType
  linkText: string
  title?: string
}

interface Props {
  content: Content
  maxWidth: number
  pageType: PageType
}

const Body: React.FC<React.PropsWithChildren<Props>> = ({ content, maxWidth, pageType }) => {
  const bodyContent = content.content ? renderRichText(JSON.parse(content.content)) : []

  const firstIntroParagraph =
    content.intro && bodyContent?.length > 0
      ? bodyContent.indexOf(bodyContent.find((item: any) => item.type === 'p'))
      : false

  return (
    <Container background={content.background} maxWidth={maxWidth} pageType={pageType}>
      <ContentWrapper alignment={content.alignment}>
        {content.title && <Title>{content.title}</Title>}
        <BodyContent label={content.label} key={content.id}>
          <ContentInner pageType={pageType}>
            {bodyContent?.map((item: ReactElement, index: number) => (
                <React.Fragment key={item.key}>
                  {content.intro && item.type === 'p' && index === firstIntroParagraph && (
                    <IconWrapper>
                      <Icon.Arrow fill={theme.colors.orange} width="20px" height="12px" />
                    </IconWrapper>
                  )}
                  {item}
                </React.Fragment>
              ))}
          </ContentInner>
        </BodyContent>
        {content.link && content.linkText && (
          <Link.VNext
            type={content.link.type === 'text' ? 'link' : 'button'}
            link={content.link}
            text={content.linkText}
          />
        )}
      </ContentWrapper>
    </Container>
  )
}

export const ContentWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['alignment'].includes(prop),
})<{ alignment: AlignmentType }>`
  display: flex;
  flex-direction: column;

  a,
  button {
    margin-top: 24px;
  }

  margin: ${({ alignment }) => (alignment === 'center' ? '0 auto' : '0')};

  @media ${theme.legacyMediaQueries.md} {
    text-align: ${({ alignment }) => alignment && alignment};
    align-items: ${({ alignment }) => (alignment === 'center' ? 'center' : 'flex-start')};
  }
`

const Title = styled(Heading.HeadingTwo)`
  margin: 0 0 ${theme.layout.spacingM};
`

const IconWrapper = styled.span`
  float: left;
  margin-right: 6px;

  svg {
    transform: translateY(4px);
    width: 20px !important;
  }
`

const ContentInner = styled.div.withConfig({
  shouldForwardProp: (prop) => !['pageType'].includes(prop),
})<{ pageType: PageType }>`
  position: relative;

  a {
    color: ${theme.colors.blue} !important;
  }

  ${({ pageType }) =>
    pageType === 'article-detail' &&
    `
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 18px;
    }
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 14px;
    }
  `}
`

export default Body
