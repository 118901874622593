import React from 'react'

import DeepDive from '@/components/DeepDive'
import Item from '@/components/UI/FullscreenSlider/item'
import ContainerVertical from '@/components/UI/ContainerVertical'

import { useModal } from '@/hooks'
import { Spacing } from '@/types'
import { Item as ItemProps } from './types'
import { useStateCallback } from '@/utils/useStateCallback'

interface Content {
  id: string
  slides: ItemProps[]
  spacing?: Spacing
}

interface Props {
  content: Content
}

const FullscreenSlider: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const [index, setIndex] = useStateCallback(null)
  const { modalIsOpen, onCloseModal, onOpenModal } = useModal('deepdive')
  const modalContent = content.slides[index]?.deepDive

  const sliderItems = content.slides.map((item, slideIndex) => {
    const onClick = (): void => {
      if (item.deepDive?.length > 0) {
        if (index === slideIndex) {
          onOpenModal(index).catch((e: unknown) => console.error(e))
        }
        setIndex(slideIndex, (s) => {
          onOpenModal(s).catch((e: unknown) => console.error(e))
        })
      }

      return null
    }

    return {
      ...item,
      action: item.deepDive?.length ? onClick : null,
      image: {
        alt: item.image.alt,
        filename: item.image.filename,
      },
    }
  })

  return (
    <>
      <ContainerVertical verticalSpacing={content.spacing}>
        {sliderItems.map((item, slideIndex: number) => (
          <Item
            action={item.action}
            deepDive={item.deepDive}
            description={item.description}
            id={slideIndex.toString()}
            image={item.image}
            index={slideIndex}
            key={item.title}
            label={item.label}
            link={item.link}
            linkText={item.linkText}
            title={item.title}
          />
        ))}
      </ContainerVertical>
      {modalContent?.length > 0 && (
        <DeepDive
          isOpen={modalIsOpen}
          onClickHandler={() => {
            onCloseModal()
          }}
          content={modalContent}
        />
      )}
    </>
  )
}

export default FullscreenSlider
