import React from 'react'
import { Heading, Icon, theme } from '@damen/ui'
import Modal from 'react-modal'
import styled, { css } from 'styled-components'

import { DefaultModalProps } from '../types'
import { scrollbarBlue } from '@/styles/scrollbars'

const styles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 99999,
  },
  content: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    padding: 0,
    border: 0,
    outline: 'none',
  },
}

const ReactModalAdapter: React.FC<React.PropsWithChildren<DefaultModalProps>> = ({
  className,
  children,
  isOpen,
  maxWidth,
  minHeight,
  onClose,
  headerButton: HeaderButton,
  headerTitle,
  hasBorder = true,
}) => {
  const wrapperElement = React.useRef<HTMLDivElement>(null)

  const handleClick = (event: any) => {
    if (!wrapperElement.current) return

    if (event.target === wrapperElement.current || wrapperElement.current.contains(event.target)) return

    onClose()
  }

  const headerHasMultipleElements = () => {
    if (HeaderButton && headerTitle) {
      return true
    }
    return false
  }

  return (
    <Modal
      ariaHideApp
      className={`${className}__content`}
      closeTimeoutMS={theme.timing.numDefault}
      contentLabel="Modal"
      isOpen={isOpen}
      overlayClassName={`${className}__overlay`}
      onRequestClose={onClose}
      portalClassName={className}
      role="dialog"
      style={styles}
      shouldCloseOnEsc
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
    >
      <Container hasBorder={hasBorder} onClick={(event) => handleClick(event)}>
        <Wrapper hasBorder={hasBorder} ref={wrapperElement} maxWidth={maxWidth} minHeight={minHeight}>
          <Header hasBorder={hasBorder} hasMultipleElements={headerHasMultipleElements()}>
            {headerTitle && <HeaderTitle>{headerTitle}</HeaderTitle>}
            {HeaderButton && <HeaderButton />}
            {hasBorder && !HeaderButton && !headerTitle && (
              <CloseButton onClick={onClose}>
                <Icon.Close height={17} width={18} fill={theme.colors.blue} />
              </CloseButton>
            )}
          </Header>
          <Content hasBorder={hasBorder}>{children}</Content>
        </Wrapper>
      </Container>
    </Modal>
  )
}

const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    background-color: transparent;
    transition: background-color 300ms ease;

    &.ReactModal__Overlay--after-open {
      background-color: rgba(0, 0, 0, 0.6);
    }

    &.ReactModal__Overlay--before-close {
      background-color: transparent;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    opacity: 0;
    transition-duration: ${theme.timing.default};
    transition-timing-function: ease-in-out;
    transition-property: transform, opacity;
    outline: none;

    &.ReactModal__Content--after-open {
      transform: scale(1);
      opacity: 1;
    }

    &.ReactModal__Content--before-close {
      transform: scale(0.5);
      opacity: 0;
    }
  }
`

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hasBorder'].includes(prop),
})<{ hasBorder: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;

  ${({ hasBorder }) =>
    hasBorder &&
    css`
      background-color: ${theme.colors.white};
    `}

  ${scrollbarBlue}

  @media ${theme.legacyMediaQueries.sm} {
    background: transparent;
    align-items: center;
    padding: 24px;
  }
`

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['maxWidth', 'minHeight', 'hasBorder'].includes(prop),
})<{ maxWidth: number; minHeight: number; hasBorder: boolean }>`
  pointer-events: all;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  @media ${theme.legacyMediaQueries.sm} {
    justify-content: center;
  }

  @media ${theme.legacyMediaQueries.sm} {
    display: block;
    margin: auto;
    max-width: ${({ maxWidth }) => maxWidth || 1208}px;

    ${({ hasBorder }) =>
      hasBorder &&
      css`
        background-color: ${theme.colors.white};
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.23);
      `}

    ${({ minHeight }) => minHeight && `${minHeight}px`}
  }
`

const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  transition: ${theme.timing.fast};
  display: flex;
  justify-content: center;
  align-items: center;

  :hover,
  :focus {
    outline: none;
    background: ${theme.colors.blueIce};
  }

  @media ${theme.legacyMediaQueries.md} {
    order: 1;
  }
`

const Header = styled.header.withConfig({
  shouldForwardProp: (prop) => !['hasMultipleElements', 'hasBorder'].includes(prop),
})<{ hasMultipleElements: boolean; hasBorder: boolean }>`
  display: flex;
  position: sticky;
  z-index: 999;
  padding: ${({ hasBorder }) => (!hasBorder ? '0 0 24px 0' : '8px')};
  justify-content: ${({ hasMultipleElements }) => (hasMultipleElements ? 'space-between' : 'flex-end')};
  align-items: center;
  top: 0;

  ${({ hasMultipleElements }) =>
    hasMultipleElements &&
    css`
      align-items: center;
    `}
`

const Content = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hasBorder'].includes(prop),
})<{ hasBorder: boolean }>`
  position: relative;
  padding: ${({ hasBorder }) => (!hasBorder ? '0' : '16px')};
  overflow: auto;
  ${scrollbarBlue}

  @media ${theme.legacyMediaQueries.sm} {
    max-height: 80vh;
    padding: ${({ hasBorder }) => (!hasBorder ? '0' : '0 32px 32px')};
  }
`

const HeaderTitle = styled(Heading.HeadingFour)`
  color: ${theme.colors.white};
  margin: 0;
`

export default StyledModal
