import React from 'react'
import { Action, Button, Icon, theme } from '@damen/ui'
import NextLink from 'next/link'
import styled, { css } from 'styled-components'
import { SvgProps } from '@damen/ui/lib/esm/types/icon'
import ButtonLink from '@/components/Link/Default/Button'
import { isHTTP } from '@/hooks/formattedRouter'
import { ButtonProps, ButtonType, Color, DataAttr } from '../types'
import {
  DEPARTMENT_CONTACT_MODAL_URL_REGEX,
  DEPARTMENT_NEWSLETTER_MODAL_URL_REGEX,
  DONATION_MODAL_URL_REGEX,
} from '@/components/Forms/constants'
import { StoryblokLink } from '@/queries/schema.generated'

const { LinkStyle } = Action
const { accessibility, colors, fonts, typography, timing } = theme

export interface Props {
  buttonType?: ButtonType
  className?: string
  colorType?: Color
  dataAttrs?: DataAttr
  hasIcon?: boolean
  icon?: React.ComponentType<React.PropsWithChildren<SvgProps>> | null
  hideText?: boolean
  iconPosition?: string
  scroll?: boolean
  iconSize?: number
  isSmall?: boolean
  link: StoryblokLink
  rounded?: boolean
  shadow?: boolean
  text: string
  action?: () => void
}
interface RenderLinkContentProps {
  hasIcon: boolean
  hideText: boolean
  iconPosition: string
  iconSize: number
  text: string
  icon?: React.ComponentType<React.PropsWithChildren<SvgProps>> | null
}

const renderLinkContent = ({
  hasIcon,
  hideText,
  iconPosition,
  iconSize,
  text,
  icon: IconComponent,
}: RenderLinkContentProps) => (
  <>
    <Button.TextWrapper hideText={hideText}>{text}</Button.TextWrapper>
    {hasIcon && (
      <Button.IconWrapper hideText={hideText} iconPosition={iconPosition}>
        {IconComponent && <IconComponent />}
        {!IconComponent && (
          <Icon.LeftChevron display="block" fill="currentColor" width={iconSize || 5} height={iconSize || 8} />
        )}
      </Button.IconWrapper>
    )}
  </>
)

/**
 * @deprecated The class should not be used
 */
const Default: React.FC<React.PropsWithChildren<Props>> = ({
  buttonType,
  className,
  colorType = 'blue',
  dataAttrs,
  scroll,
  hasIcon = true,
  hideText = false,
  iconPosition = 'right',
  iconSize,
  icon,
  isSmall = false,
  link,
  rounded = false,
  shadow = true,
  text,
  action,
}) => {
  if (
    action ||
    link.cachedUrl === 'contact' ||
    link.cachedUrl === 'newsletter' ||
    link.cachedUrl === 'map' ||
    DEPARTMENT_CONTACT_MODAL_URL_REGEX.test(link.cachedUrl) ||
    DONATION_MODAL_URL_REGEX.test(link.cachedUrl) ||
    DEPARTMENT_NEWSLETTER_MODAL_URL_REGEX.test(link.cachedUrl)
  ) {
    return (
      <ButtonLink
        dataAttrs={dataAttrs}
        buttonType={buttonType}
        className={className}
        colorType={colorType}
        hasIcon
        hideText={false}
        icon={icon ?? Icon.LeftChevron}
        iconPosition={iconPosition}
        iconSize={iconSize}
        link={link}
        isSmall={isSmall}
        rounded={rounded}
        shadow={shadow}
        text={text}
        action={action}
      />
    )
  }

  if (link.url?.includes('http') || link.url?.includes('www')) {
    let formattedLink = link.url

    if (isHTTP(link)) {
      formattedLink = formattedLink.replace('http', 'https')
    } else if (link.url.includes('www') && !link.url.includes('https://www')) {
      formattedLink = formattedLink.replace('www.', 'https://')
    }

    return (
      <Link
        buttonType={buttonType}
        className={className}
        colorType={colorType}
        hasIcon={!!Icon}
        hideText={hideText}
        isSmall={isSmall}
        rounded={rounded}
        shadow={shadow}
      >
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={formattedLink} rel="noopener" target="_blank">
          {renderLinkContent({ hasIcon, hideText, iconPosition, iconSize, text, icon })}
        </a>
      </Link>
    )
  }

  return (
    <Link
      buttonType={buttonType}
      className={className}
      colorType={colorType}
      hasIcon={hasIcon}
      hideText={hideText}
      isSmall={isSmall}
      rounded={rounded}
      shadow={shadow}
    >
      <NextLink href={`/${link.cachedUrl || link.url}`} scroll={scroll}>
        {renderLinkContent({ hasIcon, hideText, iconPosition, iconSize, text, icon })}
      </NextLink>
    </Link>
  )
}

export const ShadowStyle = `
  content: '';
  position: absolute;
  z-index: -1;
  width: 90%;
  height: 80%;
  filter: blur(10px);
  border-radius: 17px;
  left: 50%;
  bottom: -7%;
  transform: translate3d(-50%, 0, 0);
  background-color: #000000;
  transition: all ${timing.fast};
`
export const getButtonStyles = (type: Color) => {
  switch (type) {
    case 'white':
      return `
        color: ${colors.blue};
        background-color: ${colors.white};
        &:hover,
        &:focus {
          background-color: ${colors.blueIce};
        }
        &:disabled {
          color: ${colors.blue};
          background-color: #5068c5;
        }
      `
    case 'orange':
      return `
        color: ${colors.white};
        background-color: ${colors.orange};
        &:hover,
        &:focus {
          background-color: ${colors.orangeDark};
        }
        &:disabled {
          color: ${colors.white};
          background-color: ${colors.orange};
          opacity: 0.5;
        }
      `
    default:
      return `
        color: ${colors.white};
        background-color: ${colors.blue};
        &:hover,
        &:focus {
          background-color: #193ca5;
        }
        &:disabled {
          background-color: ${colors.greyDark};
        }
      `
  }
}

export const ButtonShadowStyle = `
  &:before {
    ${ShadowStyle}
  }
`

export const ButtonStyles = css<ButtonProps>`
  cursor: pointer;
  position: relative;
  font-family: ${fonts.body.style.fontFamily};
  font-weight: ${typography.fontWeightMedium};
  border: none;
  transition: background-color ${timing.fast};
  width: fit-content;

  &:focus {
    outline: ${accessibility.outline};
    outline-offset: 1px;
  }

  &:hover,
  &:focus {
    svg {
      transform: translateX(4px);
    }
  }

  ${({ hasIcon }) =>
    hasIcon
      ? `
        display: inline-flex;
        align-items: center;
      `
      : `
        display: inline-block;
        align-items: initial;
      `}
  ${({ hideText, isSmall }) =>
    isSmall
      ? `
        font-size: ${typography.fontSizeTextSmall}px;
        line-height: ${typography.lineHeightTextSmall};
        letter-spacing: 0.4px;
        padding: ${hideText ? '10px 11px' : '4px 16px'};
      `
      : `
        font-size: ${typography.fontSizeText}px;
        line-height: 1;
        letter-spacing: 0.5px;
        padding: ${hideText ? '12px 13px' : '16px 24px'};
      `}
  ${({ rounded, shadow }) =>
    rounded
      ? `
        border-radius: 24px;
        ${shadow && ButtonShadowStyle}
      `
      : `
        border-radius: 0;
      `}
${({ buttonType, colorType }) => {
    if (buttonType === 'text') {
      return `
      ${LinkStyle};
      &:focus {
        outline: ${accessibility.outline};
        outline-offset: 8px;
      }
    `
    }
    return getButtonStyles(colorType)
  }}
${({ buttonType, hasIcon }) =>
    buttonType === 'text' &&
    hasIcon &&
    `
  &:before {
    display: none;
  }
`}
`
const Link = styled.span.withConfig({
  shouldForwardProp: (prop) =>
    !['buttonType', 'colorType', 'dataAttrs', 'hasIcon', 'hideText', 'isSmall', 'rounded', 'shadow', 'action'].includes(
      prop,
    ),
})<ButtonProps>`
  a {
    ${ButtonStyles}
    text-decoration: none;
  }
`
export default Default
