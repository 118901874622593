import React from 'react'
import { Storytell, theme } from '@damen/ui'

import DeepDive from '@/components/DeepDive'
import { Container, ContainerVertical } from '@/components/UI/'

import Link from '@/components/Link'
import { useModal } from '@/hooks'
import { ImageType, Spacing } from '@/types'
import { Story } from './types'
import { renderRichText } from '@/utils/richTextUtils'

interface Content extends Story {
  id: string
  img?: ImageType
  linkText?: string
  spacing?: Spacing
}

interface Props {
  content: Content
}

const Storytelling: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { modalIsOpen, onCloseModal, onOpenModal } = useModal('deepdive')

  const mappedContent = {
    ...content,
    buttonComponent:
      content.linkText &&
      (() => (
        <Link.VNext
          type="button"
          onClick={content.deepDive?.length > 0 ? () => void onOpenModal() : null}
          link={content.link}
          text={content.linkText}
        />
      )),
    image: {
      alt: content.img?.alt,
      src: content.img?.filename,
      sizes: `${theme.legacyMediaQueries.md} 50vw, 100vw`,
    },
  }

  return (
    <ContainerVertical verticalSpacing={content.spacing}>
      <Container background={content.background} full>
        <Storytell.Default
          alignment={mappedContent.alignment}
          buttonComponent={mappedContent.buttonComponent}
          image={mappedContent.image}
          text={renderRichText(JSON.parse(mappedContent.text))}
          title={mappedContent.title}
        />
        {content.deepDive?.length > 0 && (
          <DeepDive isOpen={modalIsOpen} onClickHandler={onCloseModal} content={content.deepDive} />
        )}
      </Container>
    </ContainerVertical>
  )
}

export default Storytelling
