import React from 'react'
import { useInstantSearch } from 'react-instantsearch-core'
import styled from 'styled-components'
import { Pagination as PaginationComponent } from '@damen/ui'
import PaginationSkeleton from '@/skeletons/Pagination'

export interface PaginationOuterProps {
  paginationMargin?: string
  paginationMarginMedia?: string
}

type Props = {
  pages: number
  initialIndex?: number
  onClick: (index: number) => void
} & PaginationOuterProps

const Pagination: React.FC<React.PropsWithChildren<Props>> = ({
  pages,
  initialIndex,
  onClick,
  paginationMargin = '80px 0',
  paginationMarginMedia = '116px 0',
}) => {
  const { status } = useInstantSearch()

  if (status === 'loading' || status === 'stalled') {
    return (
      <PaginationOuter paginationMargin={paginationMargin} paginationMarginMedia={paginationMarginMedia}>
        <PaginationSkeleton />
      </PaginationOuter>
    )
  }

  if (pages <= 1) return null

  return (
    <PaginationOuter paginationMargin={paginationMargin} paginationMarginMedia={paginationMarginMedia}>
      <PaginationComponent.Default pages={pages} initialIndex={initialIndex} onClick={(index) => onClick(index)} />
    </PaginationOuter>
  )
}

const PaginationOuter = styled.div.withConfig({
  shouldForwardProp: (prop) => !['paginationMargin', 'paginationMarginMedia'].includes(prop),
})<PaginationOuterProps>`
  ${({ paginationMargin }) => `margin: ${paginationMargin};`}
  display: grid;
  place-items: center;

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    ${({ paginationMargin }) => `margin: ${paginationMargin};`}
  }
`

export default Pagination
