import React from 'react'
import { TextWithMedia } from '@damen/ui'

import Link from '@/components/Link'
import Container from '@/components/UI/Container'

import { useLazyLoad } from '@/hooks/lazyLoad'
import { Background, ImageType, Video } from '@/types'
import { renderRichText } from '@/utils/richTextUtils'
import { StoryblokLink } from '@/queries/schema.generated'

type AlignmentType = 'left' | 'right'

interface Content {
  background: Background
  button: boolean
  alignment: AlignmentType
  media: { video?: Video }
  label?: string
  link: StoryblokLink
  linkText: string
  text: string
  title: string
  id: string
  previewImage?: ImageType
}

interface Props {
  content: Content
}

const TextWithVideoSection: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { loading, ref } = useLazyLoad({
    options: {
      threshold: 1,
      triggerOnce: true,
    },
  })

  const buttonComponent = content.linkText
    ? () => <Link.VNext type="contentlink" link={content.link} text={content.linkText} />
    : null
    
  return (
    <Container background={content.background}>
      <div ref={ref}>
        <TextWithMedia.Default
          alignBody={content.alignment}
          buttonComponent={buttonComponent}
          key={content.id}
          label={content.label}
          loading={loading}
          media={{
            video: {
              id: content.media.video.id,
              type: content.media.video.type,
              previewImage: {
                alt: content.previewImage?.alt,
                src: content.previewImage?.filename,
                width: 700,
                height: 400
              }
            },
          }}
          text={renderRichText(JSON.parse(content.text))}
          title={content.title}
        />
      </div>
    </Container>
  )
}

export default TextWithVideoSection
