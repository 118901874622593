import React from 'react'
import { Feed } from '@damen/ui'

import { Container, Pagination } from '@/components/UI'
import Link from '@/components/Link'

import { usePagination } from '@/hooks'
import { StoryBlokLink } from '@/types'

interface Content {
  id: string
  component: string
  date: Date
  formattedDate: string
  items: any[]
  link: StoryBlokLink
  linkText: string
  max: number
  title: string
  type?: string
  onLinkClick?: ({ feed, type }: { feed: Content; type: string }) => void
}

interface Props {
  content: Content
}

// TODO NOT USED?
const CardFeedAlternative: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { component, formattedDate, items, link, linkText, onLinkClick, max, title, type } = content

  const {
    componentRef,
    items: pageItems,
    pages,
    pageIndex,
    showPagination,
    onClick,
  } = usePagination({ entries: items, pageAnimationDuration: 300, type })

  const mappedItems = pageItems.map(({ id, image, link: itemLink, text }, index) => ({
    buttonText: 'Read more',
    id: `${id}/${index}`,
    image: {
      alt: image?.alt,
      src: image?.filename,
    },
    label: formattedDate,
    linkComponent: ({ children }: any) => (
      <Link.VNext type="link" link={itemLink}>
        {children}
      </Link.VNext>
    ),
    title: text,
  }))

  return (
    <Container background="light">
      <div ref={componentRef}>
        <Feed.CardsAlternative
          buttonComponent={
            link
              ? () => (
                  <Link.VNext
                    type="contentlink"
                    onClick={() => onLinkClick({ feed: content, type: component })}
                    link={link}
                    text={linkText}
                  />
                )
              : null
          }
          items={mappedItems}
          max={max}
          title={title}
        />
        {max > 3 && showPagination && <Pagination pages={pages} initialIndex={pageIndex} onClick={onClick} />}
      </div>
    </Container>
  )
}

export default CardFeedAlternative
