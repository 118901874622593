import React from 'react'
import { InsightsCenter, theme } from '@damen/ui'
import styled from 'styled-components'

import Link from '@/components/Link'

import { Card, CardSize, RowType, Video } from '../types'

interface Props {
  cards: Card[]
  type: RowType
  handleModalToggle: React.Dispatch<React.SetStateAction<boolean>>
  setVideo: React.Dispatch<React.SetStateAction<Video>>
}

const Row: React.FC<React.PropsWithChildren<Props>> = ({ cards, type, handleModalToggle, setVideo }) => (
  <Inner>
    {cards.map((card, index) => {
      const { background, id: uid, image, label, link, size, title, videoId, videoType } = card

      if (type === 'combined') {
        if (index > 1) return null

        if (index === 1 && size === 'large' && cards[0].size === 'large') {
          return (
            <Block key={uid} size="small">
              <InsightsCenter.Card
                background={background}
                buttonComponent={
                  link &&
                  (({ children }) => (
                    <Link.VNext type="link" link={link}>
                      {children}
                    </Link.VNext>
                  ))
                }
                image={{
                  alt: image.alt,
                  src: image.filename,
                  width: 387,
                  height: 284,
                  aspectRatio: '387:284',
                  priority: true,
                }}
                label={label}
                title={title}
              />
            </Block>
          )
        }

        if (index === 1 && size === 'small' && cards[0].size === 'small') {
          return (
            <Block key={uid} size="large">
              <InsightsCenter.Card
                background={background}
                buttonComponent={
                  link &&
                  (({ children }) => (
                    <Link.VNext type="link" link={link}>
                      {children}
                    </Link.VNext>
                  ))
                }
                image={{
                  alt: image.alt,
                  src: image.filename,
                  aspectRatio: '789:284',
                  width: 789,
                  height: 284,
                  priority: true,
                }}
                label={label}
                title={title}
              />
            </Block>
          )
        }

        return (
          <Block key={uid} size={size}>
            <InsightsCenter.Card
              background={background}
              buttonComponent={
                link &&
                (({ children }) => (
                  <Link.VNext type="link" link={link}>
                    {children}
                  </Link.VNext>
                ))
              }
              image={{
                alt: image.alt,
                src: image.filename,
                width: 387,
                height: 284,
                aspectRatio: '387:284',
                priority: true,
              }}
              label={label}
              title={title}
            />
          </Block>
        )
      }

      return type === 'video' ? (
        <Block key={uid} size="full">
          <InsightsCenter.Video
            image={{
              alt: image.alt,
              src: image.filename,
              width: 1208,
              height: 420,
              aspectRatio: '1208:420',
              priority: true,
            }}
            onClick={() => {
              setVideo({ id: videoId, type: videoType })
              handleModalToggle(true)
            }}
          />
        </Block>
      ) : (
        <Block key={uid} size={size}>
          <InsightsCenter.Card
            background={background}
            buttonComponent={
              link &&
              (({ children }) => (
                <Link.VNext type="link" link={link}>
                  {children}
                </Link.VNext>
              ))
            }
            image={{
              alt: image.alt,
              src: image.filename,
              width: 387,
              height: 284,
              aspectRatio: '387:284',
            }}
            label={label}
            title={title}
          />
        </Block>
      )
    })}
  </Inner>
)

const Inner = styled.section`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 16px;
  }

  @media ${theme.legacyMediaQueries.md} {
    flex-direction: row;

    & + & {
      margin-top: 24px;
    }
  }
`

const setSize = (size: CardSize) => {
  switch (size) {
    case 'small':
      return 'calc((100% - 48px) / 3)'
    case 'large':
      return 'calc(((100% - 24px) / 3) * 2)'
    default:
      return '100%'
  }
}

const Block = styled.div.withConfig({
  shouldForwardProp: (prop) => !['size'].includes(prop),
})<{ size: CardSize }>`
  & + & {
    margin-top: 16px;
  }

  @media ${theme.legacyMediaQueries.md} {
    width: ${({ size }) => setSize(size)};

    & + & {
      margin: 0 0 0 24px;
    }
  }
`

export default Row
