import React from 'react'
import styled, { css } from 'styled-components'
import { Icon, NextImage, theme } from '@damen/ui'
import Link from '@/components/Link'
import { ImageType, StoryblokLink } from '@/queries/schema.generated'
import { transformImage } from '@/helpers/images'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.x1}px;
  padding-bottom: ${theme.spacing.x2}px;
  margin-bottom: ${theme.spacing.x2}px;
  border-bottom: 1px solid ${theme.colors.greyDark};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  padding: ${theme.spacing.x1}px;
`

// First 5 lines are needed to truncate text to two lines
const Text = styled.span`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: ${theme.spacing.x1}px;
  font-size: ${theme.typography.fontSizeTextSmall}px;
  font-weight: ${theme.typography.fontWeightRegular};
  line-height: ${theme.typography.lineHeightLabel};
  color: ${theme.colors.marineBlack};
`
const Title = styled.span`
  font-weight: ${theme.typography.fontWeightMedium};
  width: 100%;
`

const CurrentPageItem = styled.div`
  display: block;
  margin-bottom: ${theme.spacing.x4}px;
  font-size: ${theme.typography.fontSizeText}px;
  font-weight: ${theme.typography.fontWeightBold};
  line-height: ${theme.typography.lineHeightTextLarge};

  ${Title} {
    color: ${theme.colors.blue};
  }
`

const DefaultIconWrapperStyle = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.x05}px ${theme.spacing.x1}px;
`

const E3IconWrapper = styled.div`
  ${DefaultIconWrapperStyle}
  background-color: ${theme.colors.greenSeaweed};
  color: ${theme.colors.white};
`

const GreenIconWrapper = styled.div`
  ${DefaultIconWrapperStyle}
  background-color: transparent;
`

const ConceptIconWrapper = styled.div`
  ${DefaultIconWrapperStyle}
  background-color: ${theme.colors.orange};
  color: ${theme.colors.white};
`

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const IconsWrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.x1}px;
  align-items: flex-start;
`

const ImageWrapper = styled.div`
  position: relative;
  padding: 0 ${theme.spacing.x2}px;

  img {
    width: 100px;
    height: auto;
    object-fit: contain;
  }
`

const StyledLink = styled(Link.VNext)`
  &:hover {
    ${Title} {
      color: ${theme.colors.blue};
    }
  }
`

interface ProductIconProps {
  icon: string
}

interface ProductItemProps {
  image: string
  text: string
  titleWithIcon: () => React.JSX.Element
}

const ProductIcon: React.FC<ProductIconProps> = ({ icon }) => {
  if (icon === 'E3') {
    return (
      <E3IconWrapper>
        <Icon.SustainableMarkE3 height={14} width={13} fill={theme.colors.white} />{' '}
      </E3IconWrapper>
    )
  }
  if (icon === 'Green') {
    return (
      <GreenIconWrapper>
        <Icon.SustainableMarkGreen height={14} width={13} />
      </GreenIconWrapper>
    )
  }
  if (icon === 'Concept') {
    return (
      <ConceptIconWrapper>
        <Icon.Lighthouse height={14} width={14} fill={theme.colors.white} />
      </ConceptIconWrapper>
    )
  }
  return null
}

const ProductItem = ({ image, text, titleWithIcon }: ProductItemProps) => (
  <Container>
    {image && (
      <ImageWrapper>
        <NextImage
          alt={text}
          src={transformImage({
            image,
            options: { size: { width: 100 } },
          })}
          width={100}
          height={100}
        />
      </ImageWrapper>
    )}
    <Content>
      {titleWithIcon()}
      {text && <Text>{text}</Text>}
    </Content>
  </Container>
)

interface Props {
  id: string
  link: StoryblokLink
  title: string
  text: string
  image?: ImageType
  iconType?: string[]
  currentPage: string
}

const ProductSublevelLink: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  link,
  image,
  text,
  iconType,
  id,
  currentPage,
}) => {
  const isCurrentPage = link.url === currentPage
  const imageFilename = image?.filename

  const titleWithIcon = () => (
    <TitleWrapper>
      <Title>{title}</Title>
      <IconsWrapper>
        {iconType?.map((icon) => {
          return (
            <React.Fragment key={`${id}-${icon}`}>
              <ProductIcon icon={icon} />
            </React.Fragment>
          )
        })}
      </IconsWrapper>
    </TitleWrapper>
  )

  if (isCurrentPage) {
    return (
      <CurrentPageItem key={id}>
        <ProductItem image={imageFilename} text={text} titleWithIcon={titleWithIcon} />
      </CurrentPageItem>
    )
  }

  return (
    <StyledLink link={link} type="link" key={id} prefetch={false}>
      <ProductItem image={imageFilename} text={text} titleWithIcon={titleWithIcon} />
    </StyledLink>
  )
}

export default ProductSublevelLink
