import React from 'react'
import styled from 'styled-components'
import { legacyMediaQueries, colors, fonts, typography } from '@damen/ui/lib/cjs/styles'

export interface ToggleItem {
  label: string
  onClick: () => void
}

export interface Props {
  label: string
  onLabelClick?: () => void
  button?: React.ComponentType
}

const StickyBar: React.FC<React.PropsWithChildren<Props>> = ({
  button: ButtonComponent,
  label,
  onLabelClick,
  children,
}) => {
  return (
    <Container>
      <Flex>
        <Label onClick={onLabelClick}>{label}</Label>
        {children}
      </Flex>
      <ButtonWrapper>{ButtonComponent && <ButtonComponent />}</ButtonWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  height: 72px;
  padding: 0 32px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.18);
  justify-content: space-between;

  @media ${legacyMediaQueries.mdMax} {
    height: 62px;
    padding: 0;
  }
`

const Flex = styled.div`
  display: flex;
  align-items: center;

  @media ${legacyMediaQueries.mdMax} {
    width: 100%;
  }
`

const Label = styled.p`
  margin: 0 0 0 -24px;
  padding: 24px;
  font-family: ${fonts.overline.style.fontFamily};
  font-size: ${typography.fontSizeTextLarge}px;
  font-weight: ${typography.fontWeightMedium};
  line-height: ${typography.lineHeightTextLarge};
  color: ${colors.grey};
  cursor: ${({ onClick }) => (onClick ? `pointer` : `default`)};
  white-space: nowrap;

  @media ${legacyMediaQueries.lgMax} {
    display: none;
  }
`

const ButtonWrapper = styled.span`
  align-self: center;

  button {
    font-size: 12px;
    color: ${colors.white};
  }

  @media ${legacyMediaQueries.mdMax} {
    display: none;
  }
`

export default StickyBar
