import React from 'react'
import { Props } from './types'
import Contacts from '@/patterns/Contacts'

// TODO This should be moved to a bloks folder where all patterns are present.
// The components folder should only contain components (like buttons) that are used in patterns.
const Contact: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  return <Contacts {...content} />
}

export default Contact
