import React from 'react'
import { Icon, MultiColumn as MultiColumnComponent, theme } from '@damen/ui'
import styled from 'styled-components'

import Link from '@/components/Link'
import Container from '@/components/UI/Container'

import { Background } from '@/types'
import { MultiColumnFragment } from '@/queries/fragments/MultiColumnFragment.generated'
import type { Alignment } from '@damen/ui/lib/esm/types/alignment'

interface Props {
  content: MultiColumnFragment
}

const MultiColumn: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const columns =
    content.columns.map((item) => ({
      buttonComponent:
        item.linkText && (() => <Link.VNext type="contentlink" link={item.link} text={item.linkText} />),
      image: {
        alt: item.img?.alt,
        src: item.img?.filename,
        sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
      },
      text: item.text,
      title: item.title,
    })) || []

  return (
    <Container background={content.background as Background}>
      <MultiColumnComponent.Default
        alignment={content.alignment as Alignment}
        buttonComponent={
          content.linkText && (() => <Link.VNext type="button" link={content.link} text={content.linkText} />)
        }
        columns={columns}
        overline={content.label}
        text={content.text}
        title={content.title}
      />
      {content.bowWave && (
        <BowWave>
          <Icon.BowWave height={87} width={117} />
        </BowWave>
      )}
    </Container>
  )
}

const BowWave = styled.div`
  position: absolute;
  top: -34px;
  z-index: 2;
  display: none;

  @media ${theme.legacyMediaQueries.md} {
    display: block;
    right: 80px;
  }

  @media ${theme.legacyMediaQueries.lg} {
    top: -42px;
    right: 122px;
  }

  @media ${theme.legacyMediaQueries.lgMax} {
    svg {
      width: 96px !important;
      height: 72px !important;
    }
  }
`

export default MultiColumn
