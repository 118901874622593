import { useRouter } from 'next/router'
import { getPublicEnv, IS_PROD_BUILD } from '@/utils/env'
import { formatSlug } from '@/utils/slugUtils'

export const useBasePath = () => {
  const router = useRouter()

  const asPath = router.asPath
  const schema = IS_PROD_BUILD() ? 'https://' : 'http://'
  const hostName = getPublicEnv('NEXT_PUBLIC_PUBLICHOSTNAME') ?? 'localhost:3000'
  const environment = `${schema}${hostName}`
  const url = formatSlug(environment, asPath)

  const path = asPath.indexOf('?') > 0 ? asPath.substring(0, asPath.indexOf('?')) : asPath

  return { truncatedPath: environment, url, path }
}
