import React from 'react'
import styled from 'styled-components'

import BaseLayout, { Props as BaseProps } from '../Base'

const ErrorLayout: React.FC<React.PropsWithChildren<BaseProps>> = ({ children, title }) => (
  <BaseLayout title={title}>
    <Container>{children}</Container>
  </BaseLayout>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.marineBlack};
`

export default ErrorLayout
