import React, { useMemo } from 'react'

import { Partners as PartnersComponent, theme } from '@damen/ui'
import Container from '@/components/UI/Container'
import { Props } from './types'
import Link from '@/components/Link'

const Partners: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const mappedLogos = useMemo(
    () =>
      content.partnerItems.map((item) => ({
        ...item,
        image: {
          src: item.image.filename,
          sizes: `${theme.legacyMediaQueries.md} 205px, 175px`,
        },
        link: item.link.url,
        buttonComponent: ({ children }: any) =>
          item.link ? (
            <Link.VNext type="link" link={item.link}>
              {children}
            </Link.VNext>
          ) : null,
      })),
    [content.partnerItems],
  )

  return (
    <Container background={content.background}>
      <PartnersComponent.Default
        title={content.title}
        label={content.label}
        background={content.background}
        logos={mappedLogos}
      />
    </Container>
  )
}

export default Partners
