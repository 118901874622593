import { theme } from '@damen/ui'
import NextLink from 'next/link'
import styled from 'styled-components'

export const IconWrapper = styled.span`
  transition: all 0.2s ease-in-out;
`

export const Anchor = styled(NextLink).withConfig({
  shouldForwardProp: (prop) => !['outline', 'hasIcon'].includes(prop),
})<{ outline: boolean; hasIcon?: boolean }>`
  text-decoration: none;

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  &:hover {
    ${IconWrapper} {
      transform: translateX(4px);
    }
  }

  &:focus {
    outline: none;

    ${IconWrapper} {
      transform: translateX(4px);
    }
  }

  ${({ outline }) =>
    outline &&
    `
    &:focus {
      color: inherit;
      outline: ${theme.accessibility.outline};
      outline-offset: 8px;
    }
  `}

  ${({ hasIcon }) =>
    hasIcon &&
    `
      display: flex;
      align-items: center;
      gap: ${theme.spacing.x1}px;
  `}
`

export const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => !['outline', 'hasIcon'].includes(prop),
})<{ outline: boolean; hasIcon?: boolean }>`
  display: inline-flex;
  width: 100%;
  cursor: pointer;
  padding: 0;
  background: transparent;
  text-align: inherit;
  border: none;

  & > * {
    width: 100%;
  }

  &:focus {
    outline: none;
  }

  ${({ outline }) =>
    outline
      ? `
        &:focus {
          outline: ${theme.accessibility.outline};
          outline-offset: 8px;
        }
      `
      : `
        
  `}

  ${({ hasIcon }) =>
    hasIcon &&
    `
      display: flex;
      align-items: center;
      gap: ${theme.spacing.x1}px;

      &:hover {
        ${IconWrapper} {
          transform: translateX(4px);
        }
      }

      & > * {
        width: auto;
      }
  `}
`
