import React from 'react'
import { useQuery } from '@apollo/client'
import { Contacts as ContactsComponent } from '@damen/ui'
import { Container } from '@/components/UI'
import Link from '@/components/Link'

import { Background } from '@/types'
import { Contacts as ContactsType } from '@/queries/schema.generated'
import { ModifyTypeProps } from '@/helpers/typings'
import { GetTranslationsDocument, GetTranslationsQuery } from '@/queries/queries/Global/getTranslations.generated'

type OverridedContactsProps = ModifyTypeProps<
  ContactsType,
  {
    background: Background
  }
>

const Contacts = ({
  background,
  title,
  contacts,
  initialContacts,
  showLessText,
  showMoreText,
}: OverridedContactsProps) => {
  const translationData = useQuery<GetTranslationsQuery>(GetTranslationsDocument, { fetchPolicy: 'cache-only' })
  const emailSubject = translationData?.data?.site?.emailTranslations?.subject

  const mappedContacts = contacts
    ? contacts.map((contact) => {
        const image = contact.image.filename
          ? {
              src: contact.image.filename,
              alt: contact.name,
            }
          : null
        return {
          ...contact,
          linkedin: contact.linkedin
            ? ({ children }: any) => (
                <Link.VNext type="link" link={contact.linkedin}>
                  {children}
                </Link.VNext>
              )
            : null,
          image,
        }
      })
    : []

  return (
    <Container background={background}>
      <ContactsComponent.Default
        title={title}
        contacts={mappedContacts}
        background="none"
        initialItems={initialContacts}
        showLessText={showLessText}
        showMoreText={showMoreText}
        emailSubject={emailSubject}
      />
    </Container>
  )
}

export default Contacts
