import React from 'react'
import { Tabs as TabComponent } from '@damen/ui'
import type { TabItem } from '@damen/ui/lib/esm/components/Tabs/types'
import { useQuery } from '@apollo/client'
import { Container } from '@/components/UI'
import { ContactContact, Tab, TabsLink } from '@/queries/schema.generated'
import Link from '@/components/Link'
import { GetTranslationsDocument, GetTranslationsQuery } from '@/queries/queries/Global/getTranslations.generated'

interface Content {
  id: string
  tabs: [Tab]
}

interface Props {
  content: Content
}

const Tabs: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const translationData = useQuery<GetTranslationsQuery>(GetTranslationsDocument, { fetchPolicy: 'cache-only' })
  const emailSubject = translationData.data?.site?.emailTranslations?.subject

  const getType = (typename: string): 'Link' | 'Contact' => {
    switch (typename) {
      case 'TabsLinks':
        return 'Link'
      case 'TabsContacts':
        return 'Contact'
      default:
        return undefined
    }
  }

  const getButton = (item: TabsLink) => {
    return <Link.VNext type="contentlink" link={item.link} text={item.linkText} color="blue" />
  }

  const getContacts = (contacts: [ContactContact]) => {
    return contacts.map((contact) => {
      const image = contact.image.filename
        ? {
            src: contact.image.filename,
            alt: contact.name,
            sizes: `156px`,
          }
        : null
      return {
        ...contact,
        linkedin: contact.linkedin
          ? ({ children }: any) => (
              <Link.VNext type="link" link={contact.linkedin}>
                {children}
              </Link.VNext>
            )
          : undefined,
        image,
      }
    })
  }

  const getItems = (tab: any): any[] => {
    switch (tab.__typename) {
      case 'TabsLinks':
        return tab?.items.map((item: any) => ({
          ...item,
          buttonComponent: getButton(item),
        }))
      case 'TabsContacts':
        return tab?.contacts ? getContacts(tab?.contacts) : []
      default:
        return undefined
    }
  }

  const tabsData: TabItem[] = content.tabs.map((tab) => ({
    id: tab.id,
    type: getType(tab.__typename),
    title: tab.title,
    items: getItems(tab),
    emailSubject: tab.__typename === 'TabsContacts' ? emailSubject : undefined,
    showMoreText: tab.__typename === 'TabsContacts' ? tab.showMoreText : undefined,
    showLessText: tab.__typename === 'TabsContacts' ? tab.showLessText : undefined,
  }))

  return (
    <Container background="white">
      <TabComponent.Default tabs={tabsData} />
    </Container>
  )
}

export default Tabs
