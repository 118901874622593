import { isNullOrUndefined } from '../nullUtils'

export const formatCurrency = (value: string, locale = 'nl-NL', currency = 'EUR') => {
  const valueAsNumber = parseInt(value, 10)

  if (!Number.isNaN(valueAsNumber)) {
    if (currency) {
      return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(valueAsNumber)
    }
    return new Intl.NumberFormat(locale).format(valueAsNumber)
  }

  return value
}

export const formatCurrencyRange = (value: string, symbol = '€ ', locale = 'nl-NL'): string => {
  if (isNullOrUndefined(value)) {
    return null
  }

  let formattedCurrencyRange: string
  const values = value.split(' - ')
  const firstValue = values[0]
  const secondValue = values[1]
  // TODO When we start working with locales, the hardcoded euro sign should be made dynamic

  // In case when no split is possible, we return the value as is (currently used for 'On request' prices)
  if (values.length === 1) {
    formattedCurrencyRange = value
    // We do not always have a number and in that case we do not want the hyphen (-) between the range values
  } else if (Number.isNaN(parseInt(secondValue, 10))) {
    formattedCurrencyRange = `${symbol}${formatCurrency(firstValue, locale, null)} ${secondValue}`
  } else if (Number.isNaN(parseInt(firstValue, 10))) {
    formattedCurrencyRange = `${symbol}${firstValue} ${formatCurrency(secondValue, locale, null)}`
  } else {
    formattedCurrencyRange = `${symbol}${formatCurrency(firstValue, locale, null)} - ${formatCurrency(
      secondValue,
      locale,
      null,
    )}`
  }

  return formattedCurrencyRange
}
