import { renderRichText } from '@/utils/richTextUtils'

export const structuredData = ({ cards }: { cards: any }) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: [
    cards.map((card: any) => {
      return card.questions.map((question: any) => {
        return {
          '@type': 'Question',
          name: question.title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: renderRichText(question.content),
          },
        }
      })
    }),
  ],
})
