import React from 'react'
import styled from 'styled-components'
import { theme } from '@damen/ui'
import { SiteNavigationSupportingItem } from '@/queries/schema.generated'
import MenuBanner from './MenuBanner'
import Link from '@/components/Link'
import { BaseMenuItemStyle, GroupLabel, MenuList } from '../styles'
import MenuLinkItem from './MenuLinkItem'
import { NAVIGATION_BREAKPOINT_LARGE } from '../consts'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x4}px;
  margin-top: ${theme.spacing.x9}px;

  @media (${theme.mediaQueries.mediaQueryTablet}) {
    flex-direction: row;
    margin-top: 0;
  }

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    margin-top: ${theme.spacing.x8}px;
  }
`

const SupportMenuItem = styled.li`
  ${BaseMenuItemStyle}
  font-size: ${theme.typography.fontSizeTextSmall}px;

  margin-bottom: ${theme.spacing.x2}px;
`

export interface Props {
  supportingItem?: SiteNavigationSupportingItem
  activeItem: string
  currentPage: string
}

const SupportingItem: React.FC<Props> = ({ supportingItem, activeItem, currentPage }) => {
  if (!supportingItem) {
    return null
  }

  if (supportingItem.__typename === 'SiteNavigationBanner') {
    return (
      <MenuBanner
        key={supportingItem.id}
        image={{ alt: supportingItem.image.alt, src: supportingItem.image.filename }}
        title={supportingItem.title}
        subtitle={supportingItem.subtitle}
        icon={supportingItem.icon}
        buttonComponent={() => (
          <Link.VNext type="button" color="career" link={supportingItem.link} text={supportingItem.linkText} prefetch={false} />
        )}
      />
    )
  }

  if (supportingItem.__typename === 'SiteNavigationQuickLinks') {
    return (
      <Container key={supportingItem.id}>
        {supportingItem.items.map((item) => (
          <div key={item.id}>
            {item.label && <GroupLabel>{item.label}</GroupLabel>}
            <MenuList>
              {item.links?.map(({ id, title, link }) => (
                <SupportMenuItem key={id}>
                  <MenuLinkItem
                    title={title}
                    link={link}
                    currentPage={currentPage}
                    isActive={link?.url === activeItem || activeItem.startsWith(link?.url)}
                    variant="support"
                  />
                </SupportMenuItem>
              ))}
            </MenuList>
          </div>
        ))}
      </Container>
    )
  }

  return null
}

export default SupportingItem
