import React from 'react'
import { Breadcrumb, theme } from '@damen/ui'
import styled from 'styled-components'

import Link from '@/components/Link'

import { isValidLink } from '@/utils/uriUtil'
import { StoryblokLink } from '@/queries/schema.generated'

type Color = 'marineBlack' | 'white'

export const useBreadcrumb = (title: string, link: StoryblokLink, color: Color = 'white', mobileColor = 'white') => {
  if (isValidLink(link) && title) {
    const BreadcrumbComponent: React.ComponentType = () => (
      <BreadCrumbWrapper mobileColor={mobileColor}>
        <Link.VNext type="link" link={link}>
          <Breadcrumb.Small color={color} title={title} />
        </Link.VNext>
      </BreadCrumbWrapper>
    )

    return BreadcrumbComponent
  }
}

const BreadCrumbWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['mobileColor'].includes(prop),
})<{ mobileColor: string }>`
  @media ${theme.legacyMediaQueries.mdMax} {
    * {
      color: ${({ mobileColor }) => mobileColor};
    }
  }
`
