import React from 'react'
import { Icon } from '@damen/ui'
import { Props } from '../types'
import { Anchor, IconWrapper, Button as StyledButton } from './styles'
import { getLinkFromLink } from '../utils'
import { isExternalUrl } from '@/utils/uriUtil'

const Link: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { link, className, testId, scroll, children, target: passedTarget, icon: IconComponent, prefetch } = props
  const outline = true
  const href = getLinkFromLink(link) ?? ''
  const isExternalUrlValue = isExternalUrl(href)

  const rel = isExternalUrlValue ? 'noopener' : null
  const target = isExternalUrlValue ? '_blank' : null
  const isTextLink = typeof children === 'string'

  return (
    <Anchor
      href={href}
      scroll={scroll}
      className={className}
      data-testid={testId}
      outline={outline}
      rel={rel}
      target={passedTarget ?? target}
      hasIcon={isTextLink && (isExternalUrlValue || !!IconComponent)}
      prefetch={prefetch}
    >
      {children}
      {(IconComponent || isExternalUrlValue) && isTextLink && (
        <IconWrapper>
          {IconComponent && !isExternalUrlValue && <IconComponent />}
          {isExternalUrlValue && <Icon.OpenInNew display="block" fill="currentColor" width={14} height={14} />}
        </IconWrapper>
      )}
    </Anchor>
  )
}

const Button: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { className, testId, children, onClick, outline, icon: IconComponent } = props
  const isTextLink = typeof children === 'string'

  return (
    <StyledButton
      className={className}
      data-testid={testId}
      outline={outline}
      type="button"
      onClick={onClick}
      hasIcon={isTextLink && !!IconComponent}
    >
      {children}
      {IconComponent && isTextLink && <IconWrapper>{IconComponent && <IconComponent />}</IconWrapper>}
    </StyledButton>
  )
}

export default { Link, Button }
