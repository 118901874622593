import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

interface Props {
  entries: any[]
  pageAnimationDuration?: number
  type?: string
}

interface Offset {
  begin: number
  end: number
}

interface ReturnValues {
  componentRef: MutableRefObject<HTMLDivElement>
  items: any[]
  loading: boolean
  offset: Offset
  pages: number
  pageIndex: number
  showPagination: boolean
  onClick: (index: number) => void
}

const firstPageTotal = 9
const nextPageTotal = 12
const itemDifference = nextPageTotal - firstPageTotal

export const usePagination = ({ entries = [], pageAnimationDuration = 500, type = '' }: Props): ReturnValues => {
  const [active, setActive] = useState(false)
  const [items, setItems] = useState(entries)
  const [loading, setLoading] = useState<boolean>(false)
  const [offset, setOffset] = useState({ begin: 0, end: firstPageTotal })
  const [pages, setPages] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const router = useRouter()

  const ref = useRef<HTMLDivElement>(null)

  const handleOffset = (index: number) => {
    switch (index) {
      case 1: {
        setOffset({ begin: 0, end: firstPageTotal })
        return
      }
      case 2: {
        setOffset({ begin: (index - 1) * firstPageTotal, end: index * nextPageTotal - itemDifference })
        return
      }
      default: {
        setOffset({
          begin: (index - 1) * nextPageTotal - itemDifference,
          end: index * nextPageTotal - itemDifference,
        })
        return
      }
    }
  }

  const handleClick = (index: number) => {
    setLoading(true)
    if (!active) setActive(true)

    if (type && index) {
      void router.push({ pathname: '', query: { type, page: index } }, undefined, { shallow: true })
    }

    handleOffset(index)

    setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
      })

      setLoading(false)
    }, pageAnimationDuration)
  }

  useEffect(() => {
    setPages(Math.round((entries.length + firstPageTotal) / nextPageTotal))
  }, [entries])

  useEffect(() => {
    if (!active && JSON.stringify(entries) !== JSON.stringify(items)) {
      setItems(entries)
    }
  }, [entries])

  useEffect(() => {
    setItems(entries.slice(offset.begin, offset.end))
    setPages(Math.round((entries.length + firstPageTotal) / nextPageTotal))
  }, [offset, entries])

  useEffect(() => {
    const pageNr = Number(router.query.page)

    if (pageNr && pageNr > 1 && pages > 1) {
      if (pageNr <= pages) {
        setPageIndex(pageNr - 1)
        handleClick(pageNr)
      } else {
        handleClick(1)
        setPageIndex(0)
      }
    }
  }, [router.query.page, pages])

  return {
    componentRef: ref,
    items,
    loading,
    offset,
    pages,
    pageIndex,
    showPagination: entries.length > 9,
    onClick: handleClick,
  }
}
