import React from 'react'
import NextLink from 'next/link'
import { Props } from '../types'
import { getLinkFromLink } from '../utils'
import { isExternalUrl } from '@/utils/uriUtil'

const Link: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { link, className, testId, scroll, children, prefetch } = props
  const href = getLinkFromLink(link) ?? ''
  const isExternalUrlValue = isExternalUrl(href)

  const rel = isExternalUrlValue ? 'noopener' : null
  const target = isExternalUrlValue ? '_blank' : null
  return (
    <NextLink href={href} scroll={scroll} className={className} data-testid={testId} rel={rel} target={target} prefetch={prefetch}>
      {children}
    </NextLink>
  )
}

export default { Link }
