import { theme } from '@damen/ui'
import styled from 'styled-components'

const PaginationWrapper = styled.div`
  display: flex;
`

const PaginationItem = styled.div`
  & + & {
    margin-left: ${theme.layout.spacing};
  }
`

const PaginationNavigationWrapper = styled.span`
  display: flex;
  margin-left: ${theme.layout.spacingXL};

  span {
    &:first-child {
      margin-right: ${theme.layout.spacing};
    }
  }
`

export { PaginationWrapper, PaginationItem, PaginationNavigationWrapper }
