import throttle from 'lodash/throttle'
import { useLazyQuery } from '@apollo/client'
import { SuggestionsDocument, SuggestionsQuery } from '@/queries/queries/Search/querySuggest.generated'

const mapSuggestions = (suggestions: any[]) => {
  if (!suggestions) {
    return []
  }

  return suggestions.map(({ title, _highlightResult }) => ({
    label: _highlightResult.title.value,
    value: title,
  }))
}

export const useSearchSuggestions = () => {
  const [fetchSuggestions, { client, data, error, loading }] = useLazyQuery<SuggestionsQuery>(SuggestionsDocument)

  const getSuggestions = throttle((value: string) => {
    if (loading) return null

    if (value.length > 2 && !loading) {
      return fetchSuggestions({ variables: { query: value } })
    }

    if (value.length < 3 && client) {
      return fetchSuggestions({ variables: { query: '' } })
    }

    return null
  }, 0)

  const mappedData = data?.querySuggest ? mapSuggestions(data.querySuggest.hits) : []

  return { getSuggestions, data: mappedData, error, loading }
}
