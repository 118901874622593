import React from 'react'
import { StoryblokLink } from '@/queries/schema.generated'
import { Props } from './types'
import { useFormattedRouter } from '@/hooks'
import Button from './Button'

import Link from './Link'
import { isValidLink } from '@/utils/uriUtil'
import { getLinkFromLink } from './utils'
import ContentLink from './ContentLink'
import Raw from './Raw'
import {
  DEPARTMENT_CONTACT_MODAL_URL_REGEX,
  DEPARTMENT_NEWSLETTER_MODAL_URL_REGEX,
  DONATION_MODAL_URL_REGEX,
} from '@/components/Forms/constants'

const modals = new Set(['contact', 'map', 'newsletter', 'feedback', 'quote'])

const shouldOpenModal = (link: StoryblokLink) => {
  const url = link.url ?? link.cachedUrl
  if (!url) {
    return false
  }

  return (
    modals.has(url) ||
    DEPARTMENT_CONTACT_MODAL_URL_REGEX.test(url) ||
    DEPARTMENT_NEWSLETTER_MODAL_URL_REGEX.test(url) ||
    DONATION_MODAL_URL_REGEX.test(url)
  )
}

const useCurrentOnClick = (props: React.PropsWithChildren<Props>) => {
  const { link, onClick } = props
  const isDirectLink = typeof link === 'string'
  const extractedNextLink = isDirectLink ? null : link

  const [handleRouteClick] = useFormattedRouter(extractedNextLink)

  if (!isDirectLink) {
    return isValidLink(extractedNextLink) && shouldOpenModal(extractedNextLink) ? handleRouteClick : onClick
  }
  return null
}

const ButtonLink: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { type, onClick, link } = props
  const overriddenLink = getLinkFromLink(link)
  const currentOnClick = useCurrentOnClick(props)

  const parsedProperties: Props = {
    ...props,
    onClick: currentOnClick ?? onClick,
    link: overriddenLink,
  }

  if (!isValidLink(overriddenLink) && !currentOnClick) {
    // Not a valid link, do not render anything
    return null
  }

  if (type === 'button') {
    const overridenType = link && !parsedProperties.onClick ? 'link' : null
    return overridenType ? <Button.Link {...parsedProperties} /> : <Button.Button {...parsedProperties} />
  }
  if (type === 'link') {
    const overridenType = currentOnClick ? 'button' : null
    return overridenType ? <Link.Button {...parsedProperties} /> : <Link.Link {...parsedProperties} />
  }

  // Old "Button.Default with new behavior"
  if (type === 'contentlink') {
    const overridenType = currentOnClick ? 'button' : null
    return overridenType ? <Button.Button {...parsedProperties} /> : <ContentLink.Link {...parsedProperties} />
  }

  if (type === 'raw') {
    return <Raw.Link {...parsedProperties} />
  }

  return null
}

export default ButtonLink
