import React from 'react'
import { Stream } from '@damen/ui'
import styled from 'styled-components'

import Link from '@/components/Link'

import { Background } from '@/types'
import { StreamThumbnailsFragment } from '@/queries/fragments/StreamThumbnailsFragment.generated'
import { Label } from '@damen/ui/lib/esm/types/media'

interface Props {
  content: StreamThumbnailsFragment
}

const StreamThumbnails: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { formattedDate, items, itemsTitle, img, link, linkText, overlay = false, title, background } = content

  const highlight = {
    buttonComponent: linkText && (() => <Link.VNext type="button" link={link} text={linkText} />),
    image: {
      alt: img.alt,
      src: content.img.filename,
    },
    imageLabel: formattedDate,
    labelType: 'light' as Label,
    overlay,
    title,
  }

  const streamItems =
    items.map((item) => ({
      ...item,
      buttonComponent:
        item.link &&
        (({ children }: any) => (
          <Link.VNext type="link" link={item.link}>
            {children}
          </Link.VNext>
        )),
      id: item.id,
      image: {
        alt: item.title,
        src: item.img.filename,
      },
      label: item.formattedDate,
    })) || []

  return (
    <Section>
      <Stream.Default
        highlight={highlight}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // TODO fix types
        items={streamItems}
        title={itemsTitle}
        type="thumb"
        background={background as Background}
      />
    </Section>
  )
}

const Section = styled.section`
  margin: 80px 0 40px;

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    margin: 116px 0 80px;
  }

  @media ${({ theme }) => theme.legacyMediaQueries.lg} {
    margin: 200px 0 100px;
  }

  &:first-child {
    margin-top: 0;
  }
`

export default StreamThumbnails
