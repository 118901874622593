import dynamic from 'next/dynamic'

const ContactForm = dynamic(() => import('./Contact'))
const DepartmentContactForm = dynamic(() => import('./DepartmentContact'))
const DepartmentNewsletterForm = dynamic(() => import('./DepartmentNewsletter'))
const DonationForm = dynamic(() => import('./Donation'))
const DownloadForm = dynamic(() => import('./Download'))
const NewsletterForm = dynamic(() => import('./Newsletter'))
const QuoteForm = dynamic(() => import('./Quote'))
const FeedbackForm = dynamic(() => import('./Feedback'))
const CampaignSmallForm = dynamic(() => import('./CampaignSmall'))

export default {
  ContactForm,
  DepartmentContactForm,
  DepartmentNewsletterForm,
  DonationForm,
  DownloadForm,
  NewsletterForm,
  QuoteForm,
  FeedbackForm,
  CampaignSmallForm,
}
