import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useLazyQuery } from '@apollo/client'
import { Facet, FacetOption, SearchResult, SearchState } from '@/types'

import { SearchDocument, SearchQuery } from '@/queries/queries/Search/search.generated'
import { mapFacetOptionLabel, mapFacets, mapResults } from './map'

const EMPTY_SEARCH_RESULT: SearchResult = { media: [], products: [], totalHits: 0 }

export const useSearchResults = () => {
  const [fetchResults, { data, error }] = useLazyQuery<SearchQuery>(SearchDocument)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchState, setSearchState] = useState<SearchState>('initial')
  const [selectedFacetOptions, setSelectedFacetOptions] = useState<FacetOption[]>([])
  const [facets, setFacets] = useState<Facet[]>([])
  const [results, setResults] = useState<SearchResult>(EMPTY_SEARCH_RESULT)
  const router = useRouter()

  /**
   * Sets the query parameters for search
   *
   * @param query
   * @param facetOptions
   */
  const handleSetQueryParameters = (query: any) =>
    router.push(
      {
        pathname: '',
        query: { ...router.query, ...query },
      },
      null,
      { shallow: true },
    )

  /**
   * Handle the fetch of results
   *
   * @param value
   * @param facetOptions
   * @param initialLoad
   */
  const handleGetResults = useCallback(
    (value?: string, facetOptions?: FacetOption[]) => {
      setSearchState('loading')

      const query = value ?? searchQuery
      void fetchResults({
        variables: {
          query,
          options: {
            facets: ['*'],
            facetFilters: facetOptions ? facetOptions.map(({ value: optionValue }) => optionValue) : null,
          },
        },
      })

      if (value || facetOptions) {
        if (value) {
          setSearchQuery(value)
        }

        setSearchState('show')
      } else {
        setSearchState('initial')
      }
    },
    [fetchResults, searchQuery],
  )

  /**
   * Handles the change of the search query
   *
   * @param value
   * @returns
   */
  const handleOnSearch = (value?: string) => {
    return handleSetQueryParameters({ q: value })
  }

  /**
   * Handles the change of a facet
   *
   * @param key
   * @param selectedValue
   * @returns
   */
  const handleOnFacetChange = (key: string, selectedValue: string) => {
    const selectedFacetItem = { key, value: `${key}:${selectedValue}`, label: mapFacetOptionLabel(selectedValue) }
    const newSelectedFacetOptions = selectedFacetOptions.find(({ value }) => value === selectedFacetItem.value)
      ? selectedFacetOptions.filter((item) => item.value !== selectedFacetItem.value)
      : [...selectedFacetOptions, selectedFacetItem]

    return handleSetQueryParameters({ filters: newSelectedFacetOptions.map(({ value }) => value).join(',') })
  }

  /**
   * Handles the clear of all facets
   *
   * @returns
   */
  const clearAll = () => handleSetQueryParameters({ filters: '' })

  /**
   * Remove a single facet option, used within the filter tags to remove them outside of the Search Filter checkboxes
   *
   * @param facetValue
   * @returns
   */
  const removeFacetOption = (facetValue: string) => {
    const newSelectedFacetOptions = selectedFacetOptions.filter(({ value }) => value !== facetValue)
    return handleSetQueryParameters({ filters: newSelectedFacetOptions.map(({ value }) => value).join(',') })
  }

  /**
   * Reset all the results, facets and query, and set state to initial
   */
  const resetSearch = () => {
    setSearchState('initial')
    setSearchQuery('')
    setSelectedFacetOptions([])
    setResults(EMPTY_SEARCH_RESULT)
    setFacets([])
  }

  /**
   * useEffect hook to update facets loaded by data
   */
  useEffect(() => {
    const newFacets = data?.search.facets ? mapFacets(data.search.facets, selectedFacetOptions) : null

    if (newFacets && JSON.stringify(newFacets) !== JSON.stringify(facets)) {
      setFacets(newFacets)
    }
  }, [data?.search.facets, facets, selectedFacetOptions])

  /**
   * useEffect hook to update results loaded by data
   */
  useEffect(() => {
    const newResults = data?.search.hits ? mapResults(data.search.hits) : null

    if (newResults && JSON.stringify(newResults) !== JSON.stringify(results)) {
      setResults(newResults)
    }
  }, [data?.search.hits, results])

  /**
   * useEffect hook to read query parameters
   */
  useEffect(() => {
    const query = (router.query.q as string) || null
    const filters = (router.query.filters as string) || null

    let facetOptions: any[] = []

    if (query) {
      if (filters) {
        facetOptions = filters.split(',')
        facetOptions = facetOptions.map((value) => {
          const [key, label] = value.split(':')
          return {
            label: mapFacetOptionLabel(label),
            key,
            value,
          }
        })
      }

      setSearchQuery(query)
      setSelectedFacetOptions(facetOptions)
      handleGetResults(query, searchState !== 'initial' ? facetOptions : null)
    } else {
      resetSearch()
    }
  }, [router.query, handleGetResults, searchState])

  return {
    error,
    facets,
    results,
    searchQuery,
    searchState,
    selectedFacetOptions,
    clearAll,
    handleOnFacetChange,
    handleOnSearch,
    removeFacetOption,
  }
}
