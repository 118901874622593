import React, { forwardRef, memo, useEffect, VideoHTMLAttributes } from 'react'
import makeCloudinaryVideoUrl from '@/utils/makeCloudinaryVideoUrl'

interface Props extends VideoHTMLAttributes<HTMLVideoElement> {
  publicId: string
  transformations?: string[]
  formatBlacklist?: string[]
}

// eslint-disable-next-line react/display-name
const CloudinaryVideo = forwardRef<HTMLVideoElement, Props>(
  ({ publicId, transformations, formatBlacklist, ...others }: Props, ref) => {
    useEffect(() => {
      if (!transformations?.some((transformation) => transformation.startsWith('ki_'))) {
        return
      }

      if (!formatBlacklist?.includes('webm')) {
        // keyframe interval triggers a server crash in cloudinary for webm
        throw new Error(
          'webm does not support the keyframe interval transformation. If the keyframe interval is essential, you should blacklist webm instead',
        )
      }
    }, [transformations, formatBlacklist])

    return (
      <video ref={ref} {...others}>
        {!formatBlacklist?.includes('webm') && (
          <source
            src={makeCloudinaryVideoUrl({
              publicId,
              format: 'webm',
              transformations,
            })}
            type="video/webm"
          />
        )}
        <source
          src={makeCloudinaryVideoUrl({
            publicId,
            format: 'mp4',
            transformations,
          })}
          type="video/mp4"
        />
        <source
          src={makeCloudinaryVideoUrl({
            publicId,
            format: 'ogv',
            transformations,
          })}
          type="video/ogg"
        />
      </video>
    )
  },
)

export default memo(CloudinaryVideo)
