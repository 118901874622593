import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { NextImage } from '@damen/ui'

import DelayRender from './DelayRender'
import HighlightButton from './HighlightButton'
import { HotSpot, HotSpotSecondLevel, HotspotButtonColor } from '../types'

const StyledHighlightButton = styled(HighlightButton)(
  () => css`
    position: absolute;
    z-index: 2;
    will-change: opacity;

    &.transition-exit {
      opacity: 1;
    }
    &.transition-exit-active {
      opacity: 0;
      transition: opacity 400ms;
    }
  `,
)

const useRandomAttentionGrabbingButton = (buttonCount: number, active: boolean) => {
  const [buttonNo, setButtonNo] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!active) {
      setButtonNo(undefined)
      return
    }

    let timeout: ReturnType<typeof setTimeout>
    const selectNewButton = () => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      setButtonNo((buttonNo) => {
        if (buttonNo !== undefined && buttonCount < 2) {
          return undefined
        }

        let newButtonNo
        do {
          newButtonNo = Math.round(Math.random() * (buttonCount - 1))
        } while (newButtonNo === buttonNo)
        return newButtonNo
      })
      timeout = setTimeout(selectNewButton, 3000 + Math.random() * 2000)
    }
    timeout = setTimeout(selectNewButton, 3000)
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout)
  }, [buttonCount, active])

  return buttonNo
}

type ExtendedHotspot = HotSpot | HotSpotSecondLevel

interface Props<Button extends ExtendedHotspot = ExtendedHotspot> {
  image: {
    src: string
    alt?: string
  }
  buttons: Button[]
  onButtonClick?: (highlightButton: Button) => void
  showButtons?: boolean
  getLoadingProgress?: (highlightButton: Button) => number | undefined
}

const PortfolioHighlightsImage = <T extends ExtendedHotspot = ExtendedHotspot>({
  image,
  buttons,
  showButtons = true,
  onButtonClick,
  getLoadingProgress,
}: Props<T>) => {
  const attentionGrabbingButton = useRandomAttentionGrabbingButton(buttons.length, showButtons)

  return (
    <>
      {image && <NextImage src={image.src} alt={image.alt} fill />}

      <TransitionGroup component={null}>
        {showButtons &&
          buttons.map((locationBlock, index) => {
            const { key, x, y, title, color } = locationBlock

            return (
              <CSSTransition key={key} classNames="transition" timeout={400}>
                <DelayRender delay={index * 300}>
                  <StyledHighlightButton
                    style={{
                      left: x,
                      top: y,
                    }}
                    onClick={() => onButtonClick?.(locationBlock)}
                    data-testid={`highlightsBlok.highlight.${key}`}
                    grabAttention={attentionGrabbingButton === index}
                    loadingProgress={getLoadingProgress?.(locationBlock)}
                    color={color as HotspotButtonColor}
                  >
                    {title}
                  </StyledHighlightButton>
                </DelayRender>
              </CSSTransition>
            )
          })}
      </TransitionGroup>
    </>
  )
}

export default PortfolioHighlightsImage
