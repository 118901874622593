import React from 'react'
import { LinearBlur, Modal } from '@damen/ui'

// Using direct imports to prevent Circular Dependency Injections.
// TODO: Resolve different render method for deep dive modal content
import BodyContent from '@/components/UI/BodyContent'
import Collage from '@/components/UI/Collage'
import Image from '@/components/UI/Image'
import FullWidthImage from '@/components/UI/FullWidthImage'
import MultiColumn from '@/components/UI/MultiColumn'
import Storytell from '@/components/UI/Storytell'
import TextWithImage from '@/components/UI/TextWithImage'
import TextWithVideo from '@/components/UI/TextWithVideo'
import Video from '@/components/UI/Video'
import { BlokZone } from '../UI/BlokZone'

interface Props {
  content: any
  isOpen: boolean
  onClickHandler: () => void
}

// If a change is required here also check 'TypenameToComponentMap'
const TypenameToComponentMap = {
  BodyContent,
  Collage,
  Image,
  ImageFullscreen: FullWidthImage,
  MultiColumn,
  StoryTell: Storytell,
  TextWithImage,
  TextWithVideo,
  Video,
}

const DeepDive: React.FC<React.PropsWithChildren<Props>> = ({ content, isOpen, onClickHandler }) => {
  const items = content.modal || content

  return (
    <Modal.Overlay
      isOpen={isOpen}
      onClose={onClickHandler}
      background="transparent"
      contentBackground="white"
      contentWidth="content"
      closeButtonInModalBody
      allowContentScroll
    >
      <>
        <BlokZone bloks={items} componentLookup={TypenameToComponentMap} />
        <LinearBlur preventScrollbarOverlay />
      </>
    </Modal.Overlay>
  )
}

export default DeepDive
