import React from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@damen/ui'

interface Filter {
  label: string
  value: string
}

interface Props {
  clearText?: string
  filters: Filter[]
  onClear: () => void
  onDelete: (value: string) => void
}

const SelectedFilters: React.FC<React.PropsWithChildren<Props>> = ({
  clearText = 'Clear all filters',
  filters,
  onClear,
  onDelete,
}) => (
  <Wrapper>
    {filters.length > 0 && (
      <>
        {filters.map(({ value, label }) => (
          <FilterButton key={`${value}-${label}`} onClick={() => onDelete(value)}>
            <Icon.Close width={10} height={10} />
            <Label>{label}</Label>
          </FilterButton>
        ))}
        <ClearButton onClick={() => onClear()}>{clearText}</ClearButton>
      </>
    )}
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-flow: row wrap;
  margin: -${theme.layout.spacing};
`

const FilterButton = styled.button`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border: none;
  border-radius: 24px;
  margin: ${theme.layout.spacing};
  background: ${theme.colors.greyAccentLight};
  color: ${theme.colors.marineBlack};
  padding: ${theme.layout.spacing} ${theme.layout.spacingM};
  line-height: 0;
`

const ClearButton = styled.button`
  border: none;
  background: none;
  text-decoration: underline;
  margin: ${theme.layout.spacing};
  font-size: ${theme.typography.fontSizeLabel}px;
  color: ${theme.colors.marineBlack};
  cursor: pointer;
`

const Label = styled.span`
  margin-left: ${theme.layout.spacing};
  font-size: ${theme.typography.fontSizeLabel}px;
  font-weight: ${theme.typography.fontWeightMedium};
`

export default SelectedFilters
