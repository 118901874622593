import { useEffect } from 'react'
import router from 'next/router'
import { DOWNLOAD_QUERYSTRING } from '@/components/Forms/constants'

export const useDownloadModal = (handleDownloadModal: () => void) => {
  useEffect(() => {
    const handleHashChange = (url: string, { shallow }: any) => {
      if (shallow && url.includes(DOWNLOAD_QUERYSTRING)) {
        handleDownloadModal()
      }
    }

    router.events.on('hashChangeComplete', handleHashChange)

    return () => {
      router.events.off('hashChangeComplete', handleHashChange)
    }
  }, [handleDownloadModal])
}

export const onDownloadClick = () => {
  void router.push({ hash: DOWNLOAD_QUERYSTRING }, undefined, { shallow: true })
}
