import { CampaignSmallFormFragment } from '@/queries/fragments/CampaignSmallFormFragment.generated'

export const mapFormData = (content: CampaignSmallFormFragment) => ({
  labels: {
    email: content.email?.label,
    firstname: content.firstname?.label,
    lastname: content.lastname?.label,
    subscribe: content.subscribe?.label,
    privacy: content.privacy?.label,
  },
  placeholders: {
    email: content.email?.placeholder,
    firstname: content.firstname?.placeholder,
    lastname: content.lastname?.placeholder,
  },
  errors: {
    email: content.email?.error,
    firstname: content.firstname?.error,
    lastname: content.lastname?.error,
    privacy: content.privacy?.error,
  },
  validations: {
    email: content.email?.validation,
  },
  genericValidations: {
    containsUrl: content.genericValidations?.containsUrl,
  },
  ...content,
})
