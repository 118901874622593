import React, { useEffect, useRef, useCallback } from 'react'
import { Heading, NextImage, Paragraph } from '@damen/ui'
import { gsap, Linear } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import styled from 'styled-components'

import Link from '@/components/Link'

import { Item as ItemProps } from './types'

interface Props extends ItemProps {
  index: number
  id: string
}

type Alignment = 'left' | 'right'

const Item: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  description,
  image,
  index,
  label,
  link,
  linkText,
  title,
  action,
}) => {
  const cardRef = useRef<HTMLDivElement>(null)

  const alignment: Alignment = index % 2 === 0 ? 'left' : 'right'

  const setAnimations = useCallback(() => {
    if (typeof window === 'undefined' || !cardRef.current) return null

    const scrollTriggerEnd =
      window.innerWidth < 1024
        ? `${cardRef.current.clientHeight / 3.5} center`
        : `${cardRef.current.clientHeight / 2.5} center`

    const scrollTriggerStart =
      window.innerWidth < 1024
        ? `-${cardRef.current.clientHeight / 8} center`
        : `-${cardRef.current.clientHeight / 8} center`

    const scrollTriggerConfig = {
      end: scrollTriggerEnd,
      invalidateOnRefresh: true,
      start: scrollTriggerStart,
      trigger: cardRef.current,
    }

    gsap.to(`.fullscreen-overlay-${index}`, {
      opacity: 0.6,
      ease: Linear.easeIn,
      scrollTrigger: {
        ...scrollTriggerConfig,
        scrub: 0.8,
      },
    })

    gsap.to(`.fullscreen-background-${index}`, {
      scale: 1,
      ease: Linear.easeIn,
      scrollTrigger: {
        ...scrollTriggerConfig,
        scrub: 0.8,
      },
    })

    return gsap.to(`.fullscreen-item-${index}`, {
      y: 0,
      ease: Linear.easeIn,
      scrollTrigger: {
        ...scrollTriggerConfig,
        scrub: 0.4,
      },
    })
  }, [index])

  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger)
      setAnimations()
    }

    return () => {
      ScrollTrigger.refresh(true)
    }
  }, [setAnimations])

  return (
    <Container key={id} alignment={alignment} ref={cardRef}>
      <Background className={`fullscreen-background-${index}`}>
        <StyledImage src={image.filename} alt={image.alt} fill style={{ objectFit: 'cover' }} />
      </Background>
      <Overlay alignment={alignment} className={`fullscreen-overlay-${index}`} />
      <Content className={`fullscreen-item-${index}`}>
        {label && <Heading.Overline>{label}</Heading.Overline>}
        <Heading.HeadingTwo>{title}</Heading.HeadingTwo>
        {description && <Paragraph.Default>{description}</Paragraph.Default>}
        {link && <Link.VNext type="button" color="white" link={link} text={linkText} onClick={action} />}
      </Content>
    </Container>
  )
}

const Container = styled.div.withConfig({
  shouldForwardProp: (prop) => !['alignment'].includes(prop),
})<{ alignment: Alignment }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    ${({ alignment }) =>
      alignment === 'right' &&
      `
        text-align: right;
        align-items: flex-end;
    `}
  }
`

const Content = styled.div`
  position: relative;
  max-width: 584px;
  margin: 0 16px;
  color: #fff;
  transform: TranslateY(400px);

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    margin: 0 48px;
  }

  @media ${({ theme }) => theme.legacyMediaQueries.lg} {
    margin: 0 96px;
  }

  @media ${({ theme }) => theme.legacyMediaQueries.xl} {
    margin: 0 112px;
  }

  ${Heading.Overline} {
    margin: 0;
  }

  ${Heading.HeadingTwo} {
    ${Heading.HeadingOneStyle}
    margin: 16px 0 24px;
  }

  ${Paragraph.Default} {
    margin-bottom: 32px;

    @media ${({ theme }) => theme.legacyMediaQueries.md} {
      margin-bottom: 40px;
    }
  }
`

const Overlay = styled.div.withConfig({
  shouldForwardProp: (prop) => !['alignment'].includes(prop),
})<{ alignment: Alignment }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ alignment }) =>
    alignment === 'left'
      ? 'linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))'
      : 'linear-gradient(to left, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))'};
  opacity: 0;

  @media ${({ theme }) => theme.legacyMediaQueries.mdMax} {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  }
`

const Background = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
`

const StyledImage = styled(NextImage)`
  transform: scale(1.1);
`

export default Item
