import React, { useMemo } from 'react'
import { Slider } from '@damen/ui'

import Container from '../Container'
import { ExpertiseSliderFragment } from '@/queries/fragments/ExpertiseSliderFragment.generated'
import Link from '@/components/Link'
import { useIgnoreClickOnDrag } from '@/utils/useIgnoreClickOnDrag'

interface Props {
  content: ExpertiseSliderFragment
}

const ExpertiseSlider: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const ignoreClickOnDragProps = useIgnoreClickOnDrag()
  const mappedSlides = useMemo(
    () =>
      content.items.map((slide) => ({
        ...slide,
        title: slide.sliderItemTitle,
        buttonComponent: ({ children }: any) =>
          slide.link ? (
            <Link.VNext type="raw" link={slide.link}>
              {children}
            </Link.VNext>
          ) : null,
        id: slide.id,
        image: { src: slide.img.filename },
      })),
    [content.items],
  )

  return (
    <Container {...ignoreClickOnDragProps}>
      <Slider.Expertise id={content.id} title={content.sliderTitle} label={content?.label} items={mappedSlides} />
    </Container>
  )
}

export default ExpertiseSlider
