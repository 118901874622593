import { useEffect, useState } from 'react'
import { theme } from '@damen/ui'

export const useMobile = (breakpoint = theme.legacyBreakpointsNumerical.md) => {
  const [isMobile, setIsMobile] = useState(false)

  const handleResize = () => {
    setIsMobile(window.innerWidth < breakpoint)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return { isMobile }
}
