import React from 'react'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'
import { colors, fonts, layout, typography } from '@damen/ui/lib/cjs/styles'
import { Icon } from '@damen/ui'

export interface Item {
  handle: string
  text: string
}

export interface Props {
  items: React.ReactNode[]
  activeItem: any
  isOpen: any
  onClick: any
  inline?: boolean
}

const Dropdown: React.FC<Props> = ({ items, activeItem, isOpen, onClick, inline = false }) => {
  return (
    <>
      <Trigger isOpen={isOpen} onClick={onClick} inline={inline}>
        <>{activeItem.text}</>
        <Icon.CaretDown height={11} width={11} fill={colors.marineBlack} />
      </Trigger>
      <Toggle initial={{ height: 0 }} animate={{ height: isOpen ? 'auto' : 0 }} transition={{ duration: 0.3 }}>
        <ToggleContainer>{items}</ToggleContainer>
      </Toggle>
    </>
  )
}

const Trigger = styled.button.withConfig({
  shouldForwardProp: (prop) => !['isOpen', 'inline'].includes(prop),
})<{ isOpen: boolean; inline: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${({ inline }) => (inline ? `22px ${layout.spacingM}` : `30px 12px`)};
  font-family: ${fonts.body.style.fontFamily};
  font-size: ${typography.fontSizeText}px;
  font-weight: 500;
  color: ${colors.blue};
  background: transparent;
  border: none;
  text-align: left;

  ${({ isOpen }) =>
    isOpen
      ? css`
          &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            bottom: 0;
            left: 0;
            background: ${colors.greyDark};
          }
        `
      : ``};

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 12px;
    transform: ${({ isOpen }) => (isOpen ? `rotate(180deg)` : `none`)};
  }
`

const Toggle = styled(motion.div)`
  position: absolute;
  display: block;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${colors.white};
  box-shadow: 0 8px 12px 0 rgba(1, 23, 54, 0.1);
`

const ToggleContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding: ${layout.spacingM};
`

export default Dropdown
