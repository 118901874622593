import React from 'react'
import { CTA as CTAComponent, theme } from '@damen/ui'

import Link from '@/components/Link'
import Container from '@/components/UI/Container'

import { BackgroundExtra, PageType } from '@/types'
import { CtaFragment } from '@/queries/fragments/CtaFragment.generated'
import { Alignment } from '@damen/ui/lib/esm/types/alignment'
interface Props {
  content: CtaFragment
  pageType: PageType
}

const CTA: React.FC<React.PropsWithChildren<Props>> = ({ content, pageType }) => (
  <Container background={content.background as BackgroundExtra} pageType={pageType}>
    <CTAComponent.Default
      alignBody={content.alignment as Alignment}
      background={content.componentBackground as BackgroundExtra}
      buttonComponent={
        content.link &&
        (() => (
          <Link.VNext
            type="button"
            color={content.componentBackground === 'light' ? 'blue' : 'white'}
            link={content.link}
            text={content.linkText}
          />
        ))
      }
      image={{
        alt: content.img.alt,
        src: content.img.filename,
        sizes: `${theme.legacyMediaQueries.lg} 308px , 250px`,
      }}
      text={content.text}
      title={content.title}
    />
  </Container>
)

export default CTA
