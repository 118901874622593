import React, { useRef } from 'react'
import { Header, Icon } from '@damen/ui'
import styled, { keyframes } from 'styled-components'

import Link from '@/components/Link'

import { ImageType } from '@/types'
import { getSustainableMarkWithPopover, SustainableMarkProps } from '@/utils/helpers/sustainableIcon'
import { HEADER_ANIMATION_DELAY } from '@/constants/animations'

export interface HeaderProps {
  breadcrumb?: React.ComponentType<React.PropsWithChildren>
  buttonComponent?: React.ComponentType<React.PropsWithChildren>
  buttonType?: 'blue' | 'orange' | 'white'
  img: ImageType
  label?: string
  link?: any
  linkText: string
  overlay?: boolean
  text?: string
  title: string
  sustainableMark?: SustainableMarkProps
}

interface Content {
  id: string
  header: HeaderProps
}

interface Props {
  content: Content
}

const PrimaryHeader: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const headerRef = useRef(null)

  const { header } = content

  const scrollDown = () => {
    window.scrollTo({ top: headerRef?.current?.clientHeight, behavior: 'smooth' })
  }
  const showButton = header.linkText && (header.link?.url || header.link?.cachedUrl)

  const labelWithIcon = () => {
    return (
      <>
        {header.label}
        {getSustainableMarkWithPopover(header.sustainableMark)}
      </>
    )
  }

  return (
    <Wrapper ref={headerRef}>
      <Header.Default
        animationDelay={HEADER_ANIMATION_DELAY}
        breadcrumb={header.breadcrumb ? header.breadcrumb : null}
        buttonComponent={
          showButton
            ? () => <Link.VNext type="button" color={header.buttonType} link={header.link} text={header.linkText} />
            : null
        }
        image={{
          alt: header.img.alt,
          src: header.img.filename,
        }}
        overline={header.sustainableMark ? labelWithIcon() : header.label} // labelWithIcon()
        overlay={header.overlay}
        text={header.text}
        title={header.title}
      />
      <ScrollContainer onClick={scrollDown}>
        <Text>Scroll Down</Text>
        <Icon.ArrowLineDown fill="white" />
      </ScrollContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const moveUpDown = keyframes`
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
`

const ScrollContainer = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.layout.spacingM};
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  cursor: pointer;
  text-align: center;
  user-select: none;

  svg {
    animation: 1.5s ${moveUpDown} ease-in-out infinite;
  }
`

const Text = styled.div`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.overline};
  color: #fff;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.layout.spacing};
`

export default PrimaryHeader
