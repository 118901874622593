import Script from 'next/script'
import React from 'react'
import { getPublicConfig } from '@/utils/env'

interface Props {
  nonce: string
}

const SCRIPT_GTM = 'script-gtm'

export const SocialSciptsHead: React.FC<React.PropsWithChildren<Props>> = ({ nonce }) => {
  const { NEXT_PUBLIC_GTM_CODE } = getPublicConfig()
  // Note: All browsers hide the nonce if it is applied correctly :)
  return (
    NEXT_PUBLIC_GTM_CODE && (
      <Script
        id={SCRIPT_GTM}
        data-nonce={nonce}
        nonce={nonce}
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;var n=d.querySelector('[nonce]');
          n&&j.setAttribute('nonce',n.nonce||n.getAttribute('nonce'));f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${NEXT_PUBLIC_GTM_CODE}');
        `,
        }}
      />
    )
  )
}

export const SocialSciptsBody: React.FC<React.PropsWithChildren> = () => {
  const { NEXT_PUBLIC_GTM_CODE } = getPublicConfig()

  return (
    NEXT_PUBLIC_GTM_CODE && (
      <noscript>
        <iframe
          title="gtm"
          src={`https://www.googletagmanager.com/ns.html?id=${NEXT_PUBLIC_GTM_CODE}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    )
  )
}
