// TODO: Deprecate in favor of useDisableBodyScroll.ts
// After deprecation, remove this file and check if Bug #149980 does not occur
const setBodyScrollLock = (bodyScrollLock: boolean) => {
  if (bodyScrollLock) {
    document.body.classList.add('ReactModal__Body--open')
    document.documentElement.classList.add('ReactModal__Body--open')
  } else {
    document.body.classList.remove('ReactModal__Body--open')
    document.documentElement.classList.remove('ReactModal__Body--open')
  }
}

export default setBodyScrollLock
