import React from 'react'
import { DataVisualisation } from '@damen/ui'

interface TableContent {
  id: string
  description: string
  descriptionLarge?: boolean
  summary: string
  table: any
  title: string
}

interface Props {
  content: TableContent
}

const Table: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { description, descriptionLarge, summary, table, title } = content

  return (
    <DataVisualisation.Table
      description={description}
      descriptionLarge={descriptionLarge}
      table={{ ...table, summary }}
      title={title}
    />
  )
}

export default Table
