import React from 'react'
import { Feed, theme } from '@damen/ui'
import { motion } from 'framer-motion'
import styled from 'styled-components'

import Link from '@/components/Link'
import { Container } from '@/components/UI'

import { Props } from './types'

import { Tags } from '@/queries/schema.generated'
import { createSearchQs } from '@/utils/algoliaUtils'

const articleFeedToIndexName: Record<Props['content']['__typename'], string> = {
  NewsFeed: 'articles',
  ArticleFeed: 'articles',
}

const ArticleFeed: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { link, linkText, title } = content

  const isNewsValue = content.__typename === 'NewsFeed'

  const max = 3
  const articles = content.articles
  const pageItems = articles
  const tagsFilter: Pick<Tags, 'company' | 'market' | 'pillar' | 'product'> | null = isNewsValue
    ? content.tagsFilter
    : null
  const mapFeedItems = pageItems?.map((feedItem, index) =>
    feedItem
      ? {
          ...feedItem,
          buttonComponent:
            feedItem.link &&
            (({ children }: any) => (
              <Link.VNext type="link" link={feedItem.link}>
                {children}
              </Link.VNext>
            )),
          id: `${feedItem.id}-${index}`,
          image: {
            alt: feedItem.image?.alt,
            src: feedItem.image?.filename,
            sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
          },
          imageLabel: feedItem.__typename === 'ArticleFeedItem' && feedItem.readTime ? `${feedItem.readTime} read` : null,
          link: null,
          label: feedItem.formattedDate ?? null,
        }
      : null,
  )

  const filteredLink = {
    ...link,
    cachedUrl: createSearchQs({
      indexName: articleFeedToIndexName[content.__typename],
      pageUrl: link.cachedUrl,
      tagsFilters: tagsFilter,
    }),
  }

  return (
    <Container>
      <OffsetElement />
      <Items animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
        <Feed.Cards
          buttonComponent={linkText && (() => <Link.VNext type="contentlink" link={filteredLink} text={linkText} />)}
          items={mapFeedItems}
          max={max}
          title={title}
        />
      </Items>
    </Container>
  )
}

const OffsetElement = styled.div`
  height: 100px;
  transform: translateY(-100%);
  position: absolute;
`

const Items = styled(motion.div)`
  position: relative;
`

export default ArticleFeed
