import { Tags } from '@/queries/schema.generated'

interface CreateSearchQsParams {
  indexName: string
  pageUrl: string
  tagsFilters: Pick<Tags, 'company' | 'market' | 'pillar' | 'product'>
}

const createUrlSearchArguments = (args: CreateSearchQsParams): string | any[] => {
  const { indexName, tagsFilters } = args

  if (typeof tagsFilters === 'undefined' || tagsFilters == null) {
    return []
  }

  const result = Object.keys(args.tagsFilters)
    .flatMap((filterName) => {
      // @ts-expect-error TODO check this type
      const filterValues = args.tagsFilters[filterName]
      if (filterValues !== undefined && Array.isArray(filterValues)) {
        return filterValues.map((filterValueValue, index) => {
          return {
            field: `${indexName}[refinementList][tags.${filterName}][${index}]`,
            value: filterValueValue,
          }
        })
      }

      return null
    })
    .filter((x) => x !== null)

  // From nodeJS source
  const len = result.length
  if (len === 0) return ''

  let output = `?${encodeURIComponent(result[0].field)}=${encodeURIComponent(result[0].value)}`

  for (let i = 1; i < len; i += 1) {
    output += `&${encodeURIComponent(result[i].field)}=${encodeURIComponent(result[i].value)}`
  }

  return output
}

export const createSearchQs = (args: CreateSearchQsParams) => {
  return `${args.pageUrl}${createUrlSearchArguments(args).toString()}`
}
