import React, { useMemo } from 'react'
import { MediaGallery, theme } from '@damen/ui'
import type { Asset } from '@damen/ui/lib/esm/types/assets'

import { Container } from '@/components/UI'
import { Props } from './types'

const Gallery: React.FC<Props> = ({ content }) => {
  const { title, assets, galleryItems } = content

  const mappedAssets: Asset[] = useMemo(() => {
    return assets?.items?.map((item) => {
      const result: Asset = {
        ...item,
        thumbnail: {
          src: item?.thumbnail,
          alt: item?.title,
          sizes: `${theme.legacyMediaQueries.lg} 200px, ${theme.legacyMediaQueries.md} 150px, 50vw`,
        },
        overviewImage: {
          src: item?.thumbnail,
          alt: item?.title,
          sizes: `${theme.legacyMediaQueries.lg} 600px, ${theme.legacyMediaQueries.md} 460px, 100vw`,
        },
      }
      return result
    })
  }, [assets])

  return (
    <Container>
      <MediaGallery.Preview title={title} items={mappedAssets} galleryItems={galleryItems} previewLayout="wide" />
    </Container>
  )
}

export default Gallery
