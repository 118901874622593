import React from 'react'
import { Button as DamenUiButton, Icon } from '@damen/ui'
import NextLink from 'next/link'
import { Props } from '../types'
import { StyledLink } from './styles'
import { isExternalUrl } from '@/utils/uriUtil'
import { getLinkFromLink } from '../utils'

const Button: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    style: buttonStyle,
    className,
    color,
    dataAttrs,
    text,
    icon: IconComponent,
    iconPosition,
    iconSize,
    size,
    rounded,
    shadow,
    onClick,
    testId,
  } = props
  const hideText = !(text?.length > 0)
  const isSmall = size === 'small'
  return (
    <DamenUiButton.Default
      buttonType={buttonStyle}
      className={className}
      colorType={color}
      dataAttrs={dataAttrs}
      hideText={hideText}
      icon={IconComponent ?? Icon.LeftChevron}
      iconPosition={iconPosition}
      iconSize={iconSize}
      isSmall={isSmall}
      rounded={rounded}
      shadow={shadow}
      text={text}
      type="button"
      onClick={onClick}
      testId={testId}
    />
  )
}

const Link: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    style: buttonStyle,
    className,
    color,
    text,
    scroll,
    icon: IconComponent,
    iconPosition,
    iconSize,
    size,
    rounded,
    shadow,
    link: currentUrl,
    testId,
    prefetch,
  } = props
  const hideText = !(text?.length > 0)
  const isSmall = size === 'small'
  const hasIcon = true //! isNullOrUndefined(IconComponent)

  const href = getLinkFromLink(currentUrl) ?? ''
  const isExternalUrlValue = isExternalUrl(href)

  const rel = isExternalUrlValue ? 'noopener' : null
  const target = isExternalUrlValue ? '_blank' : null
  return (
    <StyledLink
      buttonType={buttonStyle}
      className={className}
      colorType={color}
      hasIcon={hasIcon}
      hideText={hideText}
      isSmall={isSmall}
      rounded={rounded}
      shadow={shadow}
    >
      <NextLink href={href} passHref scroll={scroll} rel={rel} target={target} data-testid={testId} prefetch={prefetch}>
        <DamenUiButton.TextWrapper hideText={hideText}>{text}</DamenUiButton.TextWrapper>
        {hasIcon && (
          <DamenUiButton.IconWrapper hideText={hideText} iconPosition={iconPosition}>
            {IconComponent && <IconComponent />}
            {!IconComponent && (
              <Icon.LeftChevron display="block" fill="currentColor" width={iconSize || 5} height={iconSize || 8} />
            )}
          </DamenUiButton.IconWrapper>
        )}
      </NextLink>
    </StyledLink>
  )
}

export default { Button, Link }
