import React, { useState } from 'react'
import { Breadcrumb, Heading, Icon, Paragraph, theme, Video as VideoComponent } from '@damen/ui'
import styled from 'styled-components'

import { Modal } from '@/components/UI'
import Container from '@/components/UI/Container'
import Row from '@/components/UI/InsightsHeader/Row'

import Link from '@/components/Link'
import { Content, Video } from './types'
import { useMobile } from '@/hooks/mobile'

interface Props {
  content: Content
}

const InsightsHeader: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { breadcrumb } = content
  const { isMobile } = useMobile()

  const [showModal, setShowModal] = useState(false)
  const [video, setVideo] = useState<Video>({ id: '', type: 'vimeo' })

  return (
    <Outer>
      <Wrapper>
        <Container>
          {breadcrumb?.title && (
            <BreadcrumbOuter>
              {isMobile && (
                <Link.VNext type="link" link={breadcrumb.link}>
                  <Breadcrumb.Small title={breadcrumb.title} />
                </Link.VNext>
              )}
            </BreadcrumbOuter>
          )}
          <InsightsContent>
            <Label>{content.label}</Label>
            <Title>{content.title}</Title>
            <Text>{content.text}</Text>
          </InsightsContent>
          {content.rows.length && (
            <>
              {content.rows.map(({ id, cards, type }) => (
                <Row key={id} cards={cards} type={type} handleModalToggle={setShowModal} setVideo={setVideo} />
              ))}
            </>
          )}
        </Container>
      </Wrapper>
      <HeaderBackground>
        <Icon.BowWave fill={theme.colors.blueIce} width={362} height={365} />
      </HeaderBackground>
      <Modal.Default isOpen={showModal} onClose={() => setShowModal(false)}>
        <VideoComponent.Default autoplay={1} mute={false} videoId={video.id} videoType={video.type} />
      </Modal.Default>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  overflow: hidden;
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`

const InsightsContent = styled.header`
  color: ${theme.colors.white};
  margin-bottom: 48px;

  @media ${theme.legacyMediaQueries.md} {
    margin-top: 106px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

// The breadcrumb should not be displayed from tablet and up.
// So for alignment purposes we add 9px padding. (Breadcrumb with content was 18px high)
const BreadcrumbOuter = styled.div`
  margin: 40px 0 48px;

  @media ${theme.legacyMediaQueries.md} {
    margin: 0;
    padding: 9px;
  }
`

const HeaderBackground = styled.div`
  width: 100%;
  height: calc(500px + 22vw);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: ${theme.colors.blue};

  svg {
    opacity: 0.1 !important;

    @media ${theme.legacyMediaQueries.lgMax} {
      display: none !important;
    }
  }

  @media ${theme.legacyMediaQueries.lg} {
    max-height: 820px;

    svg {
      display: block;
      position: absolute;
      right: -5%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

const Title = styled(Heading.HeadingOne)`
  margin: 16px 0;
`

const Text = styled(Paragraph.Default)`
  max-width: 342px;
`

const Label = styled(Heading.Overline)`
  color: ${theme.colors.blueIce};
`

export default InsightsHeader
