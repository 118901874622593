import React from 'react'
import { theme } from '@damen/ui'
import styled from 'styled-components'

const Loading: React.FC<React.PropsWithChildren> = () => (
  <Foreground data-testid="loading-default">
    <FgInner />
  </Foreground>
)

const Foreground = styled.div`
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  transform: scale(1.5);
  top: 0;
  left: 0;
  background: ${theme.colors.blue};

  svg,
  g,
  path {
    fill: #fff;
  }
`

const FgInner = styled.div`
  position: absolute;
  margin: 0;
  padding: 0em;
  border-radius: 0%;
  box-shadow: 0 0 0 300px ${theme.colors.blue};
  top: 0;
  left: 50%;
  transform: translate(-50%, -110%);
  width: 200%;
  height: 100%;
`

export default Loading
