import React from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import { Button, Heading, Icon, Paragraph, theme } from '@damen/ui'

import { Image } from '@/types'
import { DefaultModalProps } from '@/components/UI/Modal/types'

const { colors, legacyMediaQueries } = theme
const { HeadingTwo } = Heading

const styles: Modal.Styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
  },
  content: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    padding: 0,
    border: 0,
    outline: 'none',
  },
}

export interface Props extends DefaultModalProps {
  body: string
  image: Image
  primaryLabel: string
  secondaryLabel?: string
  title: string
  primaryAction: () => void
  secondaryAction?: () => void
}

const ActionModelAdapter: React.FC<React.PropsWithChildren<Props>> = ({
  body,
  className,
  image,
  isOpen,
  primaryLabel,
  secondaryLabel,
  title,
  onClose,
  primaryAction,
  secondaryAction,
}) => {
  const wrapperElement = React.useRef<HTMLDivElement>(null)

  const handleClick = (event: any) => {
    if (!wrapperElement.current) return

    if (event.target === wrapperElement.current || wrapperElement.current.contains(event.target)) return

    onClose()
  }

  return (
    <Modal
      ariaHideApp
      className={`${className}__content`}
      closeTimeoutMS={theme.timing.numDefault}
      contentLabel="Modal"
      isOpen={isOpen}
      overlayClassName={`${className}__overlay`}
      onRequestClose={onClose}
      portalClassName={className}
      role="dialog"
      style={styles}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      shouldFocusAfterRender
      shouldReturnFocusAfterClose
    >
      <Container onClick={(event) => handleClick(event)}>
        <Wrapper ref={wrapperElement}>
          <CloseButton onClick={onClose}>
            <Icon.Close fill={colors.white} width={18} height={17} />
          </CloseButton>
          <Inner>
            <MediaContainer>
              <MainImage src={image.source} />
            </MediaContainer>
            <TextContent>
              <Title>{title}</Title>
              <Body>{body}</Body>
            </TextContent>
            <Links>
              {secondaryLabel && secondaryAction && (
                <>
                  <SecondaryButton>
                    <Button.Default buttonType="text" isSmall text={secondaryLabel} onClick={secondaryAction} />
                  </SecondaryButton>
                  <ButtonDivider />
                </>
              )}
              <PrimaryButton>
                <Button.Default colorType="white" icon={Icon.LeftChevron} text={primaryLabel} onClick={primaryAction} />
              </PrimaryButton>
            </Links>
          </Inner>
        </Wrapper>
      </Container>
    </Modal>
  )
}

const StyledModal = styled(ActionModelAdapter)`
  &__overlay {
    background-color: transparent;
    transition: background-color 300ms ease;

    &.ReactModal__Overlay--after-open {
      background-color: rgba(0, 0, 0, 0.75);
    }

    &.ReactModal__Overlay--before-close {
      background-color: transparent;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    opacity: 0;
    transition-duration: ${theme.timing.default};
    transition-timing-function: ease-in-out;
    transition-property: transform, opacity;
    outline: none;

    &.ReactModal__Content--after-open {
      transform: scale(1);
      opacity: 1;
    }

    &.ReactModal__Content--before-close {
      transform: scale(0.5);
      opacity: 0;
    }
  }
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`

const Wrapper = styled.div`
  position: relative;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  border: 0;
  background-color: ${colors.blue};
  color: ${colors.white};
  text-align: center;
  overflow: hidden;

  @media ${legacyMediaQueries.md} {
    display: block;
    max-width: 696px;
    width: calc(9 / 12 * 100%);
    height: 100%;
    max-height: 672px;
  }

  @media ${legacyMediaQueries.lg} {
    width: calc(6 / 12 * 100%);
  }
`

const Inner = styled.div`
  padding: 16px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${legacyMediaQueries.md} {
    padding: 48px 144px 40px;
  }
`

const CloseButton = styled.button`
  appearance: none;
  z-index: 5001;
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;
  border-radius: 50%;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  transition: ${theme.timing.fast};
  display: flex;
  justify-content: center;
  align-items: center;

  :hover,
  :focus {
    outline: none;
    background: #5068c5;
  }

  @media ${legacyMediaQueries.md} {
    right: 40px;
  }
`

const MediaContainer = styled.div`
  width: calc(8 / 12 * 100%);
  max-width: 376px;
  margin: 56px auto 0;
  padding: 0;
  box-sizing: border-box;

  @media ${legacyMediaQueries.md} {
    width: 100%;
    margin: auto;
    padding: 0 32px;
  }

  @media ${legacyMediaQueries.lg} {
    padding: 0 64px;
  }
`

const MainImage = styled.img`
  width: 100%;
`

const TextContent = styled.div`
  flex-grow: 1;
  margin: 16px 0 0;
  width: 304px;

  @media ${legacyMediaQueries.md} {
    width: auto;
    margin-top: 32px;
  }
`

const Title = styled(HeadingTwo)`
  margin: 0;
`

const Body = styled(Paragraph.Default)`
  margin-top: 16px;
  overflow-y: auto;

  @media ${legacyMediaQueries.md} {
    margin-top: 24px;
  }
`

const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  width: 100%;

  @media ${legacyMediaQueries.xs} {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
`

const ButtonDivider = styled.div`
  margin: 0 20px;

  @media ${legacyMediaQueries.xs} {
    margin: 8px 0;
  }
`

const PrimaryButton = styled.div`
  width: auto;

  button {
    width: auto;
    justify-content: inherit;
  }

  @media ${legacyMediaQueries.xs} {
    width: 100%;

    button {
      width: 100%;
      justify-content: center;
    }
  }
`

const SecondaryButton = styled.div`
  button {
    color: white;
  }
`

export default StyledModal
