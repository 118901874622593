import React from 'react'
import { TextWithMedia, theme } from '@damen/ui'
import styled from 'styled-components'

import Link from '@/components/Link'
import Container from '@/components/UI/Container'

import { Background, ImageType } from '@/types'
import { renderRichText } from '@/utils/richTextUtils'

type AlignmentType = 'left' | 'right' | 'center'

interface Content {
  alignment: AlignmentType
  background: Background
  bowWave?: boolean
  button: string
  label?: string
  link: any
  linkText: string
  media: {
    image?: ImageType
  }
  text: string
  title: string
  id: string
}

interface Props {
  content: Content
}

const TextWithImageSection: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  let media = null

  if (content.media.image?.filename) {
    media = {
      image: {
        alt: content.media.image.alt,
        src: content.media.image.filename,
      },
    }
  }

  return (
    <>
      <Container background={content.background}>
        <TextWithMedia.Default
          buttonComponent={
            content.linkText &&
            (() => (
              <Link.VNext type={content.button ? 'button' : 'link'} link={content.link} text={content.linkText} />
            ))
          }
          alignBody={content.alignment}
          bowWave={content.bowWave}
          media={media}
          key={content.id}
          text={renderRichText(JSON.parse(content.text))}
          title={content.title}
          label={content.label}
        />
      </Container>
      <Line textOnly={!media} />
    </>
  )
}

const Line = styled.hr.withConfig({
  shouldForwardProp: (prop) => !['textOnly'].includes(prop),
})<{ textOnly: boolean }>`
  display: none;

  @media ${theme.legacyMediaQueries.mdMax} {
    ${({ textOnly }) =>
      textOnly &&
      `
        display: block;
        border-top: none;
        margin: 0 16px;
        border-bottom: 1px solid ${theme.colors.blueLight};
    `}
  }
`

export default TextWithImageSection
