import React from 'react'
import { Heading, NextImage, Paragraph } from '@damen/ui'
import styled from 'styled-components'
import { StaticImageData } from 'next/image'

import { Container } from '@/components/UI'

interface Props {
  img: string | StaticImageData
  text: string
  title: string
}

const NoResults: React.FC<React.PropsWithChildren<Props>> = ({ img, text, title }) => (
  <Container>
    <Wrapper>
      <NextImage src={img} alt="No results" width={232} height={55} aspectRatio="232:55" />
      <Heading.HeadingThree>{title}</Heading.HeadingThree>
      <Paragraph.Default>{text}</Paragraph.Default>
    </Wrapper>
  </Container>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    max-width: 232px;
  }

  h3 {
    margin: 32px 0 16px;
  }
`

export default NoResults
