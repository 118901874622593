import React from 'react'
import { DynamicImage as DynamicImageComponent, theme } from '@damen/ui'

import Link from '@/components/Link'
import { Container } from '@/components/UI'

import { DynamicImageFragment } from '@/queries/fragments/DynamicImageFragment.generated'

interface Content {
  content: DynamicImageFragment
}

const DynamicImage: React.FC<React.PropsWithChildren<Content>> = ({ content }) => {
  const items = content.items.map((item) => ({
    buttonComponent:
      item.link &&
      item.linkText &&
      (() => <Link.VNext type="contentlink" link={item.link} text={item.linkText} size="small" />),
    image: {
      alt: item.img.alt,
      src: item.img.filename,
      sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 1.5), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2)`,
    },
    label: item.label,
    text: item.text,
  }))

  const mainImage = {
    alt: content.img.alt,
    src: content.img.filename,
    sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 1.5), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2)`,
  }

  return (
    <Container>
      <DynamicImageComponent bowWave={content.bowWave} items={items} title={content.title} image={mainImage} />
    </Container>
  )
}

export default DynamicImage
