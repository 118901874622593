import { useState } from 'react'
import { useRouter } from 'next/router'
import setBodyScrollLock from '@/utils/bodyScrollLock'

export const useModal = (modalId: string, component = 'default') => {
  const [modalIsOpen, openModal] = useState(false)
  const [activeModal, setActiveModal] = useState(0)
  const [idString, setIdString] = useState('')
  const router = useRouter()

  const onCloseModal = () => {
    openModal(false)
    // Remove class from html/body that prevents scrolling
    setBodyScrollLock(false)
    router.back();
  }

  const onOpenModal = async (index = 0) => {
    if (component !== 'default' && index >= 0) {
      setIdString(`${component}${index}`)
      setActiveModal(index)
    }

    await router.push({ hash: modalId || idString }, undefined, { shallow: true, scroll: false })
    openModal(true)
  }

  return { activeModal, modalIsOpen, onCloseModal, onOpenModal }
}
