import React from 'react'
import styled from 'styled-components'
import { Icon, theme } from '@damen/ui'
import Link from '@/components/Link'
import { StoryblokLink } from '@/queries/schema.generated'

const MainLink = styled(Link.VNext).withConfig({
  shouldForwardProp: (prop) => !['size'].includes(prop),
})<{ size: 'large' | 'small' }>`
  a {
    justify-content: space-between;
    width: 100%;
    margin-bottom: ${theme.spacing.x4}px;
    font-size: ${({ size }) => (size === 'large' ? 24 : 18)}px;
    font-weight: ${theme.typography.fontWeightMedium};
    line-height: ${theme.typography.lineHeightTextLarge};
    letter-spacing: 0px;
    color: ${theme.colors.marineBlack};

    // Icon size is part of UI library component and could not be adjusted without adding !important
    svg {
      width: 14px !important;
      height: 14px !important;
    }
  }

  &:hover,
  &:focus {
    color: ${theme.colors.blue};
  }

  &:focus {
    outline: ${theme.accessibility.outline};
    outline-offset: 8px;
  }
`

const CurrentPageItem = styled.span.withConfig({
  shouldForwardProp: (prop) => !['size'].includes(prop),
})<{ size: 'large' | 'small' }>`
  display: block;
  margin-bottom: ${theme.spacing.x4}px;
  font-size: ${({ size }) => (size === 'large' ? 24 : 18)}px;
  font-weight: ${theme.typography.fontWeightBold};
  line-height: ${theme.typography.lineHeightTextLarge};
  color: ${theme.colors.blue};
`

interface Props {
  link: StoryblokLink
  title: string
  onClick?: () => void
  size?: 'large' | 'small'
  currentPage: string
}

const MainSublevelLink: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  link,
  onClick,
  currentPage,
  size = 'small',
  ...props
}) => {
  const isCurrentPage = link.url === currentPage

  if (isCurrentPage && link && !onClick) {
    return (
      <CurrentPageItem size={size} {...props}>
        {title}
      </CurrentPageItem>
    )
  }

  return (
    <MainLink
      link={link}
      size={size}
      type="contentlink"
      icon={Icon.LeftChevron}
      iconSize={20}
      text={title}
      prefetch={false}
      {...props}
    />
  )
}

export default MainSublevelLink
