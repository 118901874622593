import React from 'react'
import { motion } from 'framer-motion'
import { theme } from '@damen/ui'
import styled from 'styled-components'
import { Logo } from '@/components/Logo'

import { containerVariants1, containerVariants2 } from './consts'

const Loading: React.FC<React.PropsWithChildren> = () => (
  <>
    <Foreground
      animate="animate"
      key="child"
      initial="initial"
      variants={containerVariants1}
      data-testid="loading-animated"
    >
      <LogoWrapper animate={{ opacity: 1 }} initial={{ opacity: 0 }}>
        <Logo fill={theme.colors.white} width={200} />
      </LogoWrapper>
      <FgInner
        animate={{ borderRadius: '0%', transition: { duration: 0.4, delay: 1.9 } }}
        initial={{ borderRadius: '50%' }}
      />
    </Foreground>
    <Background animate="animate" initial="initial" variants={containerVariants2}>
      <BgInner
        animate={{ borderRadius: '0%', transition: { duration: 0.5, delay: 1.9 } }}
        initial={{ borderRadius: '50%' }}
      />
    </Background>
  </>
)

const Foreground = styled(motion.div)`
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  transform: scale(1.5);
  top: 0;
  left: 0;
  background: ${theme.colors.blue};
  pointer-events: none;

  svg,
  g,
  path {
    fill: #fff;
  }
`

const FgInner = styled(motion.div)`
  position: absolute;
  margin: 0;
  padding: 0em;
  border-radius: 0%;
  box-shadow: 0 0 0 300px ${theme.colors.blue};
  top: 0;
  left: 50%;
  transform: translate(-50%, -110%);
  width: 200%;
  height: 100%;
`

const LogoWrapper = styled(motion.div)`
  position: relative;
  z-index: 1;
`

const Background = styled(motion.div)`
  background: ${theme.colors.marineBlack};
  height: 100vh;
  width: 100vw;
  z-index: 99;
  position: fixed;
  pointer-events: none;
`

const BgInner = styled(motion.div)`
  position: absolute;
  margin: 0;
  padding: 0em;
  border-radius: 0%;
  box-shadow: 0 0 0 300px ${theme.colors.marineBlack};
  top: 0;
  left: 50%;
  transform: translate(-50%, -110%);
  width: 200%;
  height: 100%;
`

export default Loading
