import React, { useMemo } from 'react'
import { Breadcrumb as BreadcrumbComponent, Icon, theme } from '@damen/ui'
import type { Color } from '@damen/ui/lib/esm/components/Breadcrumb/Default'
import Link from '@/components/Link'
import { BreadcrumbContainer, BreadcrumbInner } from './styles'
import { useMobile } from '@/hooks/mobile'

export type Variant = 'desktop' | 'mobile'

interface Content {
  id: string
  breadcrumbItems: any[]
  background: string
  mobileBackground: string
}

interface Props {
  content: Content
  variant: Variant
}

const getColor = (color: Color) => {
  switch (color) {
    case 'blue':
    case 'marineBlack':
      return theme.colors.white
    case 'white':
    case 'transparent':
    case 'silver':
    default:
      return theme.colors.blue
  }
}

const Breadcrumb: React.FC<Props> = ({ content, variant = 'desktop' }) => {
  const { breadcrumbItems, background, mobileBackground } = content
  const { isMobile } = useMobile()
  const color = isMobile ? mobileBackground : background
  const mappedItems = useMemo(
    () =>
      breadcrumbItems.map((item) => {
        return {
          ...item,
          icon:
            item?.icon !== null && item?.icon?.length > 0
              ? () => item?.icon === 'Home' && <Icon.Home width={16} height={16} fill={getColor(color as Color)} />
              : null,
          linkComponent: item.link?.url
            ? ({ children }: any) => (
                <Link.VNext type="link" link={item.link}>
                  {children}
                </Link.VNext>
              )
            : null,
          title: item.text,
        }
      }),
    [breadcrumbItems, color],
  )

  return (
    <BreadcrumbContainer background={isMobile ? mobileBackground : background} variant={variant}>
      <BreadcrumbInner>
        <BreadcrumbComponent.Default color={color as Color} items={mappedItems} />
      </BreadcrumbInner>
    </BreadcrumbContainer>
  )
}

export default Breadcrumb
