import { Variants } from 'framer-motion'

export const containerVariants1: Variants = {
  initial: {
    y: '0%',
  },
  animate: {
    y: '110%',
    opacity: 0,
    transition: {
      duration: 0.7,
      ease: 'easeInOut',
      delay: 1.6,
      opacity: {
        delay: 2.2,
        duration: 0.1,
      },
    },
  },
}

export const containerVariants2 = {
  initial: {
    y: '0%',
  },
  animate: {
    y: '110%',
    opacity: 0,
    transition: {
      duration: 0.9,
      ease: 'easeInOut',
      delay: 1.6,
      opacity: {
        delay: 2.4,
        duration: 0.1,
      },
    },
  },
}
