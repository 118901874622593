import React from 'react'
import { Collage, theme } from '@damen/ui'

import Link from '@/components/Link'
import { ContainerVertical } from '@/components/UI'

import { BackgroundExtra, ImageType, Spacing } from '@/types'

interface Content {
  id: string
  background?: BackgroundExtra
  label?: string
  link: any
  linkText?: string
  imageLarge: ImageType
  imageSmall: ImageType
  spacing?: Spacing
  text?: string
  title: string
}

interface Props {
  content: Content
}

const Default: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const images = {
    large: {
      alt: content.imageLarge.alt,
      src: content.imageLarge.filename,
      sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 2 + 120px), ${theme.legacyMediaQueries.md} 50vw, 100vw`,
    },
    small: {
      alt: content.imageSmall.alt,
      src: content.imageSmall.filename,
      sizes: `${theme.legacyMediaQueries.lg} 300px, ${theme.legacyMediaQueries.md} 200px, 215px`,
    },
  }

  return (
    <ContainerVertical verticalSpacing={content.spacing}>
      <Collage.Default
        background={content.background}
        buttonComponent={
          content.linkText &&
          (() => (
            <Link.VNext
              type="button"
              color={content.background === 'light' ? 'blue' : 'white'}
              link={content.link}
              text={content.linkText}
            />
          ))
        }
        images={images}
        overline={content.label}
        title={content.title}
        text={content.text}
        key={content.id}
      />
    </ContainerVertical>
  )
}

export default Default
