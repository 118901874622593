import React, { useState } from 'react'
import { Feed, Video as VideoComponent } from '@damen/ui'
import styled from 'styled-components'

import { motion } from 'framer-motion'
import Link from '@/components/Link'
import { Container, Modal, Pagination } from '@/components/UI/'

import { usePagination } from '@/hooks'
import { ImageType } from '@/types'

interface Video {
  id: string
  image: ImageType
  label?: string
  title: string
  videoId?: string
}

interface Content {
  videos: Video[]
  max?: number
  link: string
  linkText: string
  title: string
}

interface Props {
  content: Content
}

const VideoFeed: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { max, title, videos } = content

  const [videoId, setVideoId] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const {
    componentRef,
    items: pageItems,
    loading,
    pages,
    pageIndex,
    showPagination,
    onClick,
  } = usePagination({ entries: videos, type: 'video' })

  const mappedVideos =
    pageItems.map((video, index) => ({
      ...video,
      buttonComponent:
        video.videoId &&
        (({ children }: any) => (
          <Link.VNext type="link" link={null} onClick={() => handleCardClick(video.videoId)}>
            {children}
          </Link.VNext>
        )),
      id: `${video.id}-${index}`,
      image: {
        alt: video.image?.alt,
        src: video.image?.filename,
      },
      label: video.label,
      showPlayButton: !!video.videoId,
    })) || []

  const handleCardClick = (id: string) => {
    setVideoId(id)
    setShowModal(true)
  }

  return (
    <Container>
      <OffsetElement ref={componentRef} />
      <motion.div animate={loading ? { opacity: 0 } : { opacity: 1 }} initial={{ opacity: 0 }}>
        <Feed.Cards items={mappedVideos} title={title} max={max || mappedVideos.length} />
      </motion.div>
      {showPagination && <Pagination pages={pages} initialIndex={pageIndex} onClick={onClick} />}
      <Modal.Default isOpen={showModal} onClose={() => setShowModal(false)}>
        <VideoComponent.Default videoId={videoId} autoplay={1} mute={false} />
      </Modal.Default>
    </Container>
  )
}

const OffsetElement = styled.div`
  height: 100px;
  transform: translateY(-100%);
  position: absolute;
`

export default VideoFeed
