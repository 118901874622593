import React from 'react'
import { Button } from '@damen/ui'

import { useFormattedRouter } from '@/hooks'
import { StoryBlokLink } from '@/types'
import { ButtonProps } from '../../types'

interface Props extends ButtonProps {
  className?: string
  icon?: React.ComponentType<React.PropsWithChildren<any>> | null
  iconPosition?: string
  iconSize?: number
  link: StoryBlokLink
  text: string
}

const ButtonLink: React.FC<React.PropsWithChildren<Props>> = ({
  buttonType = 'default',
  className,
  colorType = 'blue',
  dataAttrs,
  hideText = false,
  icon: Icon,
  iconPosition = 'right',
  iconSize,
  link,
  isSmall = false,
  rounded = false,
  shadow = true,
  text,
  action,
}) => {
  const [handleRouteClick] = useFormattedRouter(link)

  return (
    <Button.Default
      buttonType={buttonType}
      className={className}
      colorType={colorType}
      dataAttrs={dataAttrs}
      hideText={hideText}
      icon={Icon}
      iconPosition={iconPosition}
      iconSize={iconSize}
      isSmall={isSmall}
      rounded={rounded}
      shadow={shadow}
      text={text}
      type="button"
      onClick={action || handleRouteClick}
    />
  )
}

export default ButtonLink
