import React from 'react'
import { Header, theme } from '@damen/ui'
import styled from 'styled-components'
import Link from '@/components/Link'
import { HeaderErrorFragment } from '@/queries/fragments/HeaderErrorFragment.generated'

interface Content {
  id: string
  header: HeaderErrorFragment
}

interface Props {
  content: Content
}

const Container = styled.div`
  padding-top: 52px;
  background-color: ${theme.colors.silverGrey200};

  @media ${theme.legacyMediaQueries.md} {
    padding-top: 68px;
  }
`

// Implement static image according to docs: https://nextjs.org/docs/app/building-your-application/optimizing/static-assets
// Header.Error is already using a next/image for optimisation
const HeaderError: React.FC<Props> = ({ content }) => {
  const { header } = content
  const image = {
    alt: 'Bottle of Rum',
    src: '/assets/images/404-Bottle-of-Rum.svg',
    sizes: '260px',
  }

  const buttonComponent = () =>
    header.link ? (
      <Link.VNext type="button" link={header.link.url ? header.link : '/'} text={header.linkText} />
    ) : (
      <Link.VNext type="button" onClick={() => window.location.reload()} text={header.linkText} />
    )

  return (
    <Container>
      <Header.Error
        buttonComponent={buttonComponent}
        title={header.headerTitle}
        text={header.headerText}
        image={image}
      />
    </Container>
  )
}

export default HeaderError
