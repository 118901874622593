import React from 'react'
import { Article, Header, theme } from '@damen/ui'
import styled from 'styled-components'

import { HeaderProps } from './types'
import SocialShare from '@/components/SocialShare'

interface Content {
  id: string
  header: HeaderProps
}

interface Props {
  content: Content
}

const ArticleHeader: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { header } = content

  const headerImg = header.img.filename
    ? {
        alt: header.img.alt,
        src: header.img.filename,
        sizes: `${theme.legacyMediaQueries.lg} 1216px, ${theme.legacyMediaQueries.md} 916px, 100vw`,
      }
    : null

  return (
    <HeaderOuter>
      <Header.Article
        breadcrumb={header.breadcrumb ? header.breadcrumb : null}
        image={headerImg}
        date={header.formattedDate}
        detailsComponent={() => (
          <Article.Details author={header.author} readTime={header.readTime} shareComponent={() => <SocialShare />} />
        )}
        title={header.title}
        intro={header.intro}
      />
    </HeaderOuter>
  )
}

const HeaderOuter = styled.div`
  margin: 64px auto 16px;
  max-width: ${theme.layout.gridWidth};
  width: 100%;

  @media ${theme.legacyMediaQueries.md} {
    margin: 100px auto 32px;
  }
`

export default ArticleHeader
