import React from 'react'
import { Highlights as HighlightsComponent, theme } from '@damen/ui'

import Link from '@/components/Link'
import Container from '@/components/UI/Container'

import { Background } from '@/types'
import { HighlightsFragment } from '@/queries/fragments/HighlightFragment.generated'

interface Props {
  content: HighlightsFragment
}

const Highlights: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const highlights =
    content.items.map((highlight) => ({
      buttonComponent:
        highlight.link &&
        (({ children }: any) => (
          <Link.VNext type="link" link={highlight.link}>
            {children}
          </Link.VNext>
        )),
      image: {
        alt: highlight.img?.alt,
        src: highlight.img?.filename,
        sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 2), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
      },
      linkText: highlight.linkText,
      overline: highlight.label,
      title: highlight.title,
      text: highlight.text,
    })) || []

  return (
    <Container background={content.background as Background}>
      <HighlightsComponent.Default highlights={highlights} title={content.title} />
    </Container>
  )
}

export default Highlights
