import React from 'react'
import { Icon, theme } from '@damen/ui'
import styled from 'styled-components'
import Link from '@/components/Link'
import { NAVIGATION_BREAKPOINT_LARGE } from '../consts'
import { LinkWrapper, MenuItem, MenuList } from '../styles'
import { SiteNavigationOverflowItem } from '@/queries/schema.generated'

const ButtonMenuItem = styled(MenuItem)`
  &::before {
    display: none;
  }

  a,
  button {
    &:hover {
      // base hover color of MenuItems is blue, but without important it is not overriding the color for the button
      color: ${theme.colors.white} !important;
    }
  }
`

const OverflowMenuItemsList = styled(MenuList)`
  display: none;

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`

const ButtonWrapper = styled.span`
  align-self: center;
  transition: opacity 0.2s ease-out;

  button,
  a {
    transition: all 200ms ease 0s;
    font-size: ${theme.typography.fontSizeTextXSmall}px;
    padding: 6px ${theme.spacing.x3}px;

    span:last-child {
      display: none;
    }
  }

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    button,
    a {
      span:last-child {
        display: inline-block;
      }
    }
  }
`

const CurrentPageIconWrapper = styled.div`
  svg path {
    fill: currentColor;
  }
`

const MenuItemWithIcon = styled(MenuItem)`
  ${LinkWrapper} {
    gap: ${theme.spacing.x05}px;
  }

  svg {
    max-width: 10px;
  }
`

const IconView = ({ icon }: { icon: string}) => {
  if (icon === 'Search') {
    return <Icon.Search />
  }

  if (icon === 'Boat') {
    return <Icon.Boat />
  }

  if (icon === 'LifeRing') {
    return <Icon.LifeRing />
  }
  return null
}

interface Props {
  overflowMenu: SiteNavigationOverflowItem[]
  variant: 'light' | 'dark'
  isAtTop?: boolean
  isMenuOpen?: boolean
  currentPage: string
}

const OverflowMenu: React.FC<React.PropsWithChildren<Props>> = ({
  overflowMenu,
  variant,
  isAtTop,
  isMenuOpen,
  currentPage,
  ...props
}) => (
  <OverflowMenuItemsList {...props}>
    <>
      {overflowMenu.map((item) => {
        if (item.type === 'button') {
          return (
            <ButtonMenuItem key={`${item.title}${item.id}`}>
              <ButtonWrapper>
                <Link.VNext
                  type="button"
                  // @ts-expect-error TODO iconType should always match 
                  icon={Icon[item.iconType]}
                  iconSize={12}
                  size="small"
                  rounded
                  shadow={false}
                  text={item.iconDisplay !== 'with' && item.iconDisplay !== '' ? null : item.title}
                  link={item.link}
                />
              </ButtonWrapper>
            </ButtonMenuItem>
          )
        }

        if (item.type === 'icon') {
          if (item.link.url === currentPage) {
            return (
              <MenuItem key={`${item.title}${item.id}`} isMenuOpen={isMenuOpen} variant={variant} isAtTop={isAtTop}>
                <CurrentPageIconWrapper>
                  <IconView icon={item.iconType} />
                </CurrentPageIconWrapper>
              </MenuItem>
            )
          }

          return (
            <MenuItem key={item.id} isMenuOpen={isMenuOpen} variant={variant} isAtTop={isAtTop}>
              <Link.VNext link={item.link} type="link">
                <IconView icon={item.iconType} />
              </Link.VNext>
            </MenuItem>
          )
        }

        if (item.type === 'default') {
          if (item.link.url === currentPage) {
            return (
              <MenuItem key={`${item.title}${item.id}`} isMenuOpen={isMenuOpen} variant={variant} isAtTop={isAtTop}>
                {item.title}
              </MenuItem>
            )
          }

          if (item.link.url !== '') {
            return (
              <MenuItemWithIcon
                key={`${item.title}${item.id}`}
                isMenuOpen={isMenuOpen}
                variant={variant}
                isAtTop={isAtTop}
              >
                <LinkWrapper type="link" link={item.link}>
                  {item.title}
                </LinkWrapper>
              </MenuItemWithIcon>
            )
          }
        }

        return null
      })}
    </>
  </OverflowMenuItemsList>
)

export default OverflowMenu
