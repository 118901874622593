import React from 'react'
import { Stream } from '@damen/ui'
import styled from 'styled-components'

import Link from '@/components/Link'
import { useFormattedRouter } from '@/hooks'
import { Background } from '@/types'
import { StreamFragment } from '@/queries/fragments/StreamFragment.generated'
import type { Label } from '@damen/ui/lib/esm/types/media'

interface Props {
  content: StreamFragment
}

const ArticleStream: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { label, items, img, link, linkText, overlay = false, title, background } = content
  const [, handleDynamicRouteClick] = useFormattedRouter()

  const highlight = {
    buttonComponent: link && (() => <Link.VNext type="button" link={link} text={linkText} />),
    image: {
      alt: img.alt,
      src: img.filename,
    },
    imageLabel: `${label} min read`,
    labelType: 'black' as Label,
    overlay,
    title,
  }

  const streamItems =
    items.map((item) => ({
      ...item,
      buttonComponent:
        item.link &&
        (({ children }: any) => (
          <Link.VNext type="link" link={item.link}>
            {children}
          </Link.VNext>
        )),
      id: item.id,
      image: {
        alt: item.image.alt,
        src: item.image.filename,
      },
      imageLabel: `${item.imageLabel} min read`,
      link: item.linkText,
      onClick: () => handleDynamicRouteClick(item.link),
    })) || []

  return (
    <Section>
      <Stream.Default highlight={highlight} items={streamItems} title={null} type="card" background={background as Background} />
    </Section>
  )
}

const Section = styled.section`
  margin: 0 0 40px;

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    margin: 0 0 80px;
  }

  @media ${({ theme }) => theme.legacyMediaQueries.lg} {
    margin: 0 0 100px;
  }
`

export default ArticleStream
