/* eslint-disable no-nested-ternary */
import React from 'react'
import { theme } from '@damen/ui'
import styled from 'styled-components'

import { BackgroundExtra, ComponentType, PageType } from '@/types'

export interface Props {
  id?: string
  background?: BackgroundExtra
  className?: string
  componentType?: ComponentType
  full?: boolean
  maxWidth?: number
  pageType?: PageType
  verticalSpacing?: boolean
  cssPosition?: 'relative' | 'none'
}

export const containerSpacing = (
  componentType: ComponentType,
  pageType: PageType,
  full: boolean,
  verticalSpacing: boolean,
) => {
  if (componentType === 'bow-wave' || componentType === 'quick-nav') {
    return `
      padding: 40px 16px;

      @media ${theme.legacyMediaQueries.md} {
        padding: ${full ? '0' : '50px'};
      }

      @media ${theme.legacyMediaQueries.lg} {
        padding: ${full ? '0' : '50px 120px'};
      }
  `
  }

  if (componentType === 'image-full') {
    return `
      padding: 0 16px;

      @media ${theme.legacyMediaQueries.md} {
        padding: ${full ? '0' : '0 80px'};
      }

      @media ${theme.legacyMediaQueries.lg} {
        padding: ${full ? '0' : '0 120px'};
      }
  `
  }

  switch (pageType) {
    case 'long-form':
      return `
        padding: 40px 16px;

        @media ${theme.legacyMediaQueries.md} {
          padding: ${full ? '0' : '64px 80px'};
        }

        @media ${theme.legacyMediaQueries.lg} {
          padding: ${full ? '0' : '64px 120px'};
        }
      `

    case 'article-detail':
      return `
          padding: 16px;

          @media ${theme.legacyMediaQueries.md} {
            padding: ${full ? '0' : '32px 80px'};
          }

          @media ${theme.legacyMediaQueries.lg} {
            padding: ${full ? '0' : '32px 120px'};
          }
        `

    case 'no-padding':
      return `padding: 0px;`

    default:
      return `
        padding: ${full ? '0' : '40px 16px'};

        @media ${theme.legacyMediaQueries.md} {
          padding: ${full ? (verticalSpacing ? '80px 0' : '0') : '80px'};
        }

        @media ${theme.legacyMediaQueries.lg} {
          padding: ${full ? (verticalSpacing ? '80px 0' : '0') : '80px 120px'};
        }
      `
  }
}

const getBackgroundColor = (background: BackgroundExtra) => {
  switch (background) {
    case 'blue':
      return theme.colors.blue
    case 'light':
      return theme.colors.blueIce
    default:
      return `transparent`
  }
}

const Container: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  background,
  className,
  componentType = 'default',
  children,
  full,
  maxWidth,
  pageType = 'default',
  verticalSpacing = false,
  cssPosition = 'relative',
  ...other
}) => (
  <Wrapper
    id={id}
    background={background}
    className={className}
    componentType={componentType}
    full={full}
    pageType={pageType}
    verticalSpacing={verticalSpacing}
    cssPosition={cssPosition}
    {...other}
  >
    <Inner full={full} maxWidth={maxWidth}>
      {children}
    </Inner>
  </Wrapper>
)

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !['cssPosition', 'background', 'componentType', 'pageType', 'full', 'verticalSpacing'].includes(prop),
})<Props>`
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ cssPosition }) => (cssPosition !== 'none' ? `position: relative;` : ``)}

  background: ${({ background }) => getBackgroundColor(background)};
  box-sizing: border-box;

  ${({ componentType, pageType, full, verticalSpacing }) =>
    containerSpacing(componentType, pageType, full, verticalSpacing)}

  ${({ componentType }) =>
    componentType === 'bow-wave' &&
    `
    @media ${theme.legacyMediaQueries.mdMax} {
      svg {
        width: 116px !important;
      }
    }
  `}
`

const Inner = styled.div.withConfig({
  shouldForwardProp: (prop) => !['full', 'maxWidth'].includes(prop),
})<Props>`
  width: 100%;

  ${({ full, maxWidth }) => !full && `max-width: ${maxWidth || 1208}px`}
`

export default Container
export { Inner }
