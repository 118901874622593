import React, { useState } from 'react'
import { Icon, IconCards, theme } from '@damen/ui'

import styled from 'styled-components'
import { Container, FAQItems } from '@/components/UI'

import { trimAndLowerCaseString } from '@/helpers/common'
import { FAQItem } from '@/types'
import { structuredData } from './structured-data'

type IconType = 'Anchor' | 'File' | 'LifeRing' | 'Wrench'

interface Card {
  icon: IconType
  questions: FAQItem[]
  title: string
}

interface Content {
  id: string
  bowWave?: boolean
  cards: Card[]
  label?: string
  title: string
}

interface Props {
  content: Content
}

const FAQIconCards: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const [selectedOption, setSelectedOption] = useState<React.ReactText>(
    trimAndLowerCaseString(content.cards ? content.cards[0]?.title : ''),
  )

  const cards =
    content.cards.length > 1
      ? content.cards.map((card: Card) => ({
          icon: card.icon,
          title: card.title,
          onClick: () => {
            setSelectedOption(trimAndLowerCaseString(card.title))
          },
        }))
      : []

  return (
    <Container>
      <IconCards.Default
        cards={cards}
        label={content.label}
        title={content.title}
        onSelect={(card) => setSelectedOption(card)}
      />
      <Inner>
        <FAQItems items={content.cards} selectedOption={selectedOption} verticalSpacing={cards.length > 1} />
        {content.bowWave && (
          <BowWave>
            <Icon.BowWave width={480} height={356} fill={theme.colors.blueIce} />
          </BowWave>
        )}
      </Inner>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            structuredData({
              cards: content.cards,
            }),
          ),
        }}
      />
    </Container>
  )
}

const Inner = styled.div`
  position: relative;
`

const BowWave = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: none;
  opacity: 0.5;

  @media ${theme.legacyMediaQueries.md} {
    display: block;
    transform: translateX(-75%);
  }

  @media ${theme.legacyMediaQueries.lgMax} {
    svg {
      width: 265px !important;
      height: 196px !important;
    }
  }
`

export default FAQIconCards
