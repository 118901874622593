import React from 'react'
import { TestimonialCards as TestimonialCardsComponent } from '@damen/ui'
import { TestimonialCardsFragment } from '@/queries/fragments/TestimonialCardsFragment.generated'
import Container from '../Container'
import setBodyScrollLock from '@/utils/bodyScrollLock'

interface Props {
  content: TestimonialCardsFragment
}

const TestimonialCards: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const items = content.items.map((item) => {
    return item.type === 'text'
      ? {
          ...item,
          type: item.type as 'text',
          image: {
            alt: item.testimonialCardsImage.alt,
            src: item.testimonialCardsImage.filename,
          },
          quote: item.testimonialCardsQuote,
        }
      : {
          ...item,
          type: item.type as 'video',
          image: {
            alt: item.testimonialCardsImage.alt,
            src: item.testimonialCardsImage.filename,
          },
          video: item.video.filename,
          buttonText: item.buttonTitle,
        }
  })

  return (
    <Container>
      {/* TODO: This logic should be in component library for onCloseModal */}
      <TestimonialCardsComponent title={content.testimonialCardsTitle} items={items} onCloseModal={() => setBodyScrollLock(false)} />
    </Container>
  )
}

export default TestimonialCards
