import { useEffect, useState } from 'react'
import { IntersectionOptions, useInView } from 'react-intersection-observer'

interface Props {
  options?: IntersectionOptions
}

export const useLazyLoad = ({ options = { rootMargin: '25%', triggerOnce: true } }: Props) => {
  const [loading, setLoading] = useState(true)
  const [ref, inView] = useInView({ ...options })

  useEffect(() => {
    if (inView) {
      setLoading(false)
    }
  }, [inView])

  return { loading, ref }
}
