import React from 'react'

export interface SizeTransformations {
  height?: number
  width?: number
}

export type CropOptions = 'c_fill,g_auto' | 'c_fit' | 'c_scale' | 'c_auto' | 'c_fill,g_center' | 'c_fill_pad'

interface TransformationOptions {
  crop?: CropOptions
  size?: SizeTransformations
}

interface TransformImageProps {
  image: string
  options?: TransformationOptions
}

interface Dimensions {
  mobile: SizeTransformations
  tablet?: SizeTransformations
  desktop: SizeTransformations
  desktopLarge: SizeTransformations
}

interface CropSettings {
  mobile?: CropOptions
  tablet?: CropOptions
  desktop?: CropOptions
  desktopLarge?: CropOptions
}

// Taken from https://github.com/mayashavin/cloudinary-api
// eslint-disable-next-line
const CLOUDINARY_REGEX =
  /^.+\.cloudinary\.com\/(?:[^/]+\/)(?:(image|video|raw)\/)?(?:(upload|fetch|private|authenticated|sprite|facebook|twitter|youtube|vimeo)\/)?(?:(?:[^_/]+_[^,/]+,?)*\/)?(?:v(\d+|\w{1,2})\/)?([^.^\s]+)(?:\.(.+))?$/

const CLOUDINARY_CUSTOM_REGEX =
  /media\.damen\.com\/(?:[^/]+\/)(?:(image|video|raw)\/)?(?:(upload|fetch|private|authenticated|sprite|facebook|twitter|youtube|vimeo)\/)?(?:(?:[^_/]+_[^,/]+,?)*\/)?(?:v(\d+|\w{1,2})\/)?([^.^\s]+)(?:\.(.+))?$/

const extractPublicId = (link: string): string => {
  if (!link) return ''
  const parts = CLOUDINARY_REGEX.exec(link)
  return parts && parts.length > 2 ? parts[parts.length - 2] : link
}

const extractCustomPublicId = (link: string): string => {
  if (!link) return ''
  const parts = CLOUDINARY_CUSTOM_REGEX.exec(link)
  return parts && parts.length > 2 ? parts[parts.length - 2] : link
}

const prefixSchema = (link: string): string => {
  return link.startsWith('//') ? `https://${link.substring(2)}` : link
}

const handleCloudinaryTransformations = (options: TransformationOptions) => {
  const transformations = Object.entries(options).map(([key, value]) => {
    if (!value && process.env.NODE_ENV === 'development') {
      console.warn(`No transformation values has been provided for ${key}`)
    }

    switch (key) {
      case 'size': {
        const typedValue = value as SizeTransformations
        const height = typedValue.height ? `h_${typedValue.height},` : ''
        const width = typedValue.width ? `w_${typedValue.width},` : ''
        const sizes = `${height}${width}`

        return sizes
      }

      case 'crop': {
        const typedValue = value as CropOptions
        return typedValue
      }

      default: {
        return ''
      }
    }
  })

  return transformations.join('')
}

const transformImageRemote = (image: string, options?: TransformationOptions) => {
  const basePath = 'https://media.damen.com/image/fetch/'
  const imageId = image // toLowercase breaks Storyblok

  const defaultTransformations = '/q_auto,f_auto/'

  let formattedImage = `${basePath}${defaultTransformations}${imageId}`

  if (options) {
    const transformations = handleCloudinaryTransformations({ ...options, crop: options.crop ?? 'c_fill,g_auto' })

    formattedImage = `${basePath}${transformations}${defaultTransformations}${imageId}`
  }

  return formattedImage
}

const transformImageCloudinary = (imageId: string, options?: TransformationOptions) => {
  const basePath = 'https://media.damen.com/'

  // For now the default transformations are equal, we would like to change this in the future
  // If an extension is passed 'f_auto' should not be rendered
  const defaultTransformations = '/q_auto,f_auto/'

  let formattedImage = `${basePath}${defaultTransformations}${imageId}`

  if (options) {
    const transformations = handleCloudinaryTransformations({ ...options, crop: options.crop ?? 'c_fill,g_auto' })

    formattedImage = `${basePath}${transformations}${defaultTransformations}${imageId}`
  }

  return formattedImage
}

export const transformImage = ({ image, options }: TransformImageProps): string => {
  if (!image) return ''

  const prefixedImage = prefixSchema(image)
  if (prefixedImage.startsWith('https://a.storyblok.com/')) {
    return transformImageRemote(prefixedImage, options)
  }

  // Fix images which are added as fetch= in Storyblok
  if (image.includes('a.storyblok.com/')) {
    const imageId = prefixSchema(image.substring(image.lastIndexOf('//a.storyblok.com/')))
    return transformImageRemote(imageId, options)
  }

  if (prefixedImage.startsWith('https://res.cloudinary.com/')) {
    const imageId = extractPublicId(prefixedImage)
    return transformImageCloudinary(imageId, options)
  }

  if (prefixedImage.startsWith('https://media.damen.com/')) {
    const imageId = extractCustomPublicId(prefixedImage)
    return transformImageCloudinary(imageId, options)
  }

  if (process.env.NODE_ENV === 'development') {
    console.info(`No transformations were found for ${image} | ${prefixedImage}`)
  }

  return image
}

export const generateImageSourceSet = (image: string, dimensions: Dimensions, crop?: CropSettings) => {
  return (
    <>
      {/* Tablet landscape - Desktop */}
      <source
        media="(min-width: 1284px)"
        srcSet={transformImage({
          image,
          options: {
            size: { height: dimensions.desktopLarge.height, width: dimensions.desktopLarge.width },
            crop: crop?.desktopLarge,
          },
        })}
      />
      {/* Tablet portrait - Desktop */}
      <source
        media="(min-width: 768px)"
        srcSet={transformImage({
          image,
          options: {
            size: { height: dimensions.desktop.height, width: dimensions.desktop.width },
            crop: crop?.desktop,
          },
        })}
      />
      {/* Mobile - Tablet */}
      {dimensions.tablet && (
        <source
          media="(min-width: 401px)"
          srcSet={transformImage({
            image,
            options: {
              size: { height: dimensions.tablet.height, width: dimensions.tablet.width },
              crop: crop?.tablet,
            },
          })}
        />
      )}
      {/* Mobile */}
      <source
        media="(max-width: 400px)"
        srcSet={transformImage({
          image,
          options: { size: { height: dimensions.mobile.height, width: dimensions.mobile.width }, crop: crop?.mobile },
        })}
      />
    </>
  )
}
