import React, { useEffect } from 'react'
import styled from 'styled-components'

import CardWrapper from './CardWrapper'
import { FamilyModel } from '@/queries/schema.generated'
import { ModalProps } from '../Modal/types'

interface Props {
  models: FamilyModel[]
  setOpenModal: (props: ModalProps) => void
  enableDirectProductSheetDownload: boolean
}

const ModelList: React.FC<React.PropsWithChildren<Props>> = ({
  models,
  setOpenModal,
  enableDirectProductSheetDownload,
}) => {
  useEffect(() => {
    if (window.location.href.includes('view=models')) {
      const queryElementId = window.location.hash.substring(1)
      const element = document.getElementById(queryElementId)

      if (queryElementId && element) {
        const headerOffset = 72 // Height of StickyBar ui component
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset

        window.scrollTo({
          top: offsetPosition,
        });
      }
    }
    return undefined
  }, [])

  return (
    <Container data-testid="model-list">
      {models.map((model, index) => (
        <CardWrapper
          key={model.id}
          model={model}
          setOpenModal={setOpenModal}
          enableDirectProductSheetDownload={enableDirectProductSheetDownload}
          testId={index.toString()}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    margin: 64px 0;
  }

  @media ${({ theme }) => theme.legacyMediaQueries.xl} {
    margin: 64px 0;
  }
`

export default ModelList
