import React from 'react'
import { Feed } from '@damen/ui'

import { Container, Pagination } from '@/components/UI'
import Link from '@/components/Link'

import { usePagination } from '@/hooks'

import { StoryBlokLink } from '@/types'

interface Content {
  id: string
  component: string
  items: any[]
  link: StoryBlokLink
  linkText: string
  max: number
  title: string
  type?: string
  onLinkClick?: ({ feed, type }: { feed: Content; type: string }) => void
}

interface Props {
  content: Content
}

// TODO NOT USED
const BrandFeed: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { component, items, link, linkText, max, onLinkClick, title, type } = content

  const {
    componentRef,
    items: pageItems,
    pages,
    pageIndex,
    showPagination,
    onClick,
  } = usePagination({ entries: items, pageAnimationDuration: 300, type })

  const mappedItems = pageItems.map(({ id, link: itemLink, size, text, title: itemTitle }, index: number) => ({
    id: `${id}/${index}`,
    linkComponent: ({ children }: any) => (
      <Link.VNext type="link" link={itemLink}>
        {children}
      </Link.VNext>
    ),
    size,
    text,
    title: itemTitle,
  }))

  return (
    <Container background="light">
      <div ref={componentRef}>
        <Feed.Brand
          buttonComponent={
            link
              ? () => (
                  <Link.VNext
                    type="contentlink"
                    onClick={() => onLinkClick({ feed: content, type: component })}
                    link={link}
                    text={linkText}
                  />
                )
              : null
          }
          items={mappedItems}
          max={max}
          title={title}
        />
        {max > 3 && showPagination && <Pagination pages={pages} initialIndex={pageIndex} onClick={onClick} />}
      </div>
    </Container>
  )
}

export default BrandFeed
