import React from 'react'

import { ContactInfoCompany as ContactInfoCompanyComponent, theme } from '@damen/ui'
import { Container } from '@/components/UI'
import Link from '@/components/Link'
import { Props } from './types'
import { StyledContactLink, StyledObfuscateLink } from './styles'

const Default: React.FC<React.PropsWithChildren<Props>> = ({ content, emailSubject }) => {
  const PhoneComponent = () => (
    <>
      {content.contactInfo?.phone?.label ? `${content.contactInfo.phone.label} ` : ''}
      <StyledContactLink type="link" link={`tel:${content.contactInfo?.phone?.value}`}>
        {content.contactInfo?.phone?.value}
      </StyledContactLink>
    </>
  )

  const MailComponent = () => (
    <>
      {content.contactInfo?.email?.label ? `${content.contactInfo.email.label} ` : ''}
      <StyledObfuscateLink email={content.contactInfo?.email?.value} headers={{ subject: emailSubject }} />
    </>
  )

  const FaxComponent = () => (
    <>
      {content.contactInfo?.fax?.label} {content.contactInfo?.fax?.value}
    </>
  )

  const contactInformation = {
    ...content.contactInfo,
    phone: content.contactInfo?.phone?.value && content.contactInfo.phone.label ? PhoneComponent : null,
    email: content.contactInfo?.email?.value && content.contactInfo.email.label ? MailComponent : null,
    fax: content.contactInfo?.fax?.value && content.contactInfo.fax.label ? FaxComponent : null,
  }
  const formattedCoordinates = `${content.formattedLatitude} ${content.formattedLongitude}`

  return (
    <Container>
      <ContactInfoCompanyComponent.Default
        title={content.title}
        text={content.text}
        buttonComponent={
          content.link &&
          content.linkText &&
          (() => <Link.VNext type="button" link={content.link} text={content.linkText} color="orange" />)
        }
        location={{
          image: {
            alt: content.title,
            src: content.locationImage,
            sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc(100vw - 160px), 100vw`,
          },
          link: () => (
            <Link.VNext
              type="contentlink"
              link={content.location.mapsLocation}
              text={content.mapLinkText}
              size="small"
            />
          ),
        }}
        address={content.address}
        postalAddress={content.postalAddress}
        coordinates={formattedCoordinates}
        contactInfo={contactInformation}
        testId="component-contact-info-company"
      />
    </Container>
  )
}

export default Default
