import React from 'react'
import { Icon, theme } from '@damen/ui'
import styled from 'styled-components'
import { FooterSocialLink } from '@/queries/schema.generated'

const { accessibility, colors } = theme

export interface SocialLinksProps {
  socialLinks?: (FooterSocialLink | null)[]
}

const RenderSocialLinkIcon = ({ type }: { type: string }) => {
  const dimensions = 15
  const XDimensions = 12
  switch (type) {
    case 'X':
    case 'twitter':
      return <Icon.X width={XDimensions} height={XDimensions} fill="white" />

    case 'facebook':
      return <Icon.Facebook width={dimensions} height={dimensions} fill="white" />

    case 'linkedin':
      return <Icon.LinkedIn width={dimensions} height={dimensions} fill="white" />

    case 'youtube':
      return <Icon.Youtube width={dimensions} height={dimensions} fill="white" />

    case 'instagram':
      return <Icon.Instagram width={dimensions} height={dimensions} fill="white" />

    default:
      return null
  }
}

export const RenderSocialLinks: React.FC<React.PropsWithChildren<SocialLinksProps>> = ({ socialLinks }) => (
  <>
    {socialLinks.map((item: FooterSocialLink | null) => {
      if (item.link.url) {
        return (
          <SocialItem href={item.link.url} key={`footer_social_links_${item.type}`} rel="noopener" target="_blank">
            <RenderSocialLinkIcon type={item.type} />
          </SocialItem>
        )
      }
    })}
  </>
)

const SocialItem = styled.a`
  color: ${colors.white};
  text-decoration: none;
  cursor: pointer;

  &:focus {
    outline: ${accessibility.outlineInverse};
    outline-offset: 8px;
  }
`
