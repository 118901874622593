import React from 'react'
import { Banner as BannerComponent, theme } from '@damen/ui'

import Link from '@/components/Link'
import { Container } from '@/components/UI'

import { Color } from '@/components/Link/types'
import { useLazyLoad } from '@/hooks/lazyLoad'
import { BannerFragment } from '@/queries/fragments/BannerFragment.generated'

interface Content {
  content: BannerFragment
}

const Banner: React.FC<React.PropsWithChildren<Content>> = ({ content }) => {
  const { loading, ref } = useLazyLoad({})

  return (
    <Container>
      <div ref={ref}>
        <BannerComponent.Default
          buttonComponent={
            content.link &&
            content.linkText &&
            (() => (
              <Link.VNext
                type="button"
                color={content.buttonType as Color}
                link={content.link}
                text={content.linkText}
              />
            ))
          }
          image={{
            alt: content.img.alt,
            src: content.img.filename,
            sizes: `${theme.legacyMediaQueries.lg} calc(100vw - 240px), ${theme.legacyMediaQueries.md} calc(100vw - 160px), 100vw`,
          }}
          loading={loading}
          text={content.text}
          overlay={content.overlay}
        />
      </div>
    </Container>
  )
}

export default Banner
