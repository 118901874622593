const makeCloudinaryVideoUrl = ({
  publicId,
  format = 'mp4',
  transformations = [],
}: {
  publicId: string
  format?: string
  transformations?: string[]
}) => {
  const path = [
    process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
    'video',
    'upload',
    ...transformations,
    `${publicId}.${format}`,
  ].filter((x) => x)

  return `${process.env.NEXT_PUBLIC_MEDIA_URL ?? 'https://media.damen.com'}/${path.join('/')}`
}

export default makeCloudinaryVideoUrl
