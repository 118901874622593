/**
 * Plays a video in reverse.
 * Browsers do not natively support reverse video playing, but this works well
 * as an alternative and seems to be used widely across the web.
 */
const reverseVideo = (video: HTMLVideoElement, fps = 25) =>
  new Promise<void>((resolve) => {
    video.pause() // allows interrupting a currently playing video
    const startVideoTime = video.currentTime
    const start = Date.now()
    const tick = () => {
      if (video.currentTime <= 0) {
        video.pause()
        resolve()
      } else {
        const elapsed = (Date.now() - start) / 1000.0
        // eslint-disable-next-line no-param-reassign
        video.currentTime = Math.max(0, startVideoTime - elapsed * video.playbackRate)
        setTimeout(tick, 1000 / fps)
      }
    }
    setTimeout(tick, 1000 / fps)
  })

export default reverseVideo
