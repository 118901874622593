import React from 'react'
import { Media, theme } from '@damen/ui'
import styled from 'styled-components'
import NextLink from 'next/link'
import { Props } from './types'

const TagLabels: React.FC<React.PropsWithChildren<Props>> = ({ tags }) => {
  return (
    <Container>
      {tags.map((tag) => (
        <LabelWrapper key={tag.label}>
          {tag.link && (
            <NextLink href={tag.link}>
              <Media.Label background={theme.colors.blueIce} color={theme.colors.grey} text={tag.label} />
            </NextLink>
          )}

          {!tag.link && <Media.Label background={theme.colors.blueIce} color={theme.colors.grey} text={tag.label} />}
        </LabelWrapper>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
`

const LabelWrapper = styled.div`
  margin: 16px 16px 0 0;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;
  }
`

export default TagLabels
