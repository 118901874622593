import { theme } from '@damen/ui'
import type { Color } from '@damen/ui/lib/esm/components/Breadcrumb/Default'
import styled, { css } from 'styled-components'
import { Variant } from '@/components/UI/Breadcrumb'

const getBackgroundColor = (background: Color) => {
  switch (background) {
    case 'blue':
      return theme.colors.blue
    case 'marineBlack':
      return theme.colors.marineBlack
    case 'white':
      return theme.colors.white
    case 'transparent':
      return 'transparent'
    case 'silver':
    default:
      return theme.colors.blueIce
  }
}

const responsiveStyles = (variant: Variant) => {
  switch (variant) {
    case 'mobile':
      return css`
        display: flex;

        @media ${theme.legacyMediaQueries.md} {
          display: none;
        }
      `
    case 'desktop':
    default:
      return css`
        display: none;

        @media ${theme.legacyMediaQueries.md} {
          display: flex;
        }
      `
  }
}

export const BreadcrumbContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['background', 'variant'].includes(prop),
})<{ background: any; variant: Variant }>`
  position: relative;
  justify-content: center;
  width: 100%;
  background-color: ${({ background }) => getBackgroundColor(background)};
  ${({ variant }) => responsiveStyles(variant)};

  padding: ${theme.layout.spacingL} 16px;

  @media ${theme.legacyMediaQueries.md} {
    padding: ${theme.layout.spacingL} 80px;
  }

  @media ${theme.legacyMediaQueries.lg} {
    padding: ${theme.layout.spacingL} 120px;
  }
`

export const BreadcrumbInner = styled.div`
  width: 100%;
  max-width: 1208px;
`
