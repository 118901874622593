import { css, styled } from 'styled-components'
import { theme } from '@damen/ui'
import Link from '@/components/Link'
import { NAVIGATION_BREAKPOINT_LARGE } from './consts'

export const LinkWrapper = styled(Link.VNext)`
  color: inherit;
`

export const MenuList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const BaseMenuItemStyle = css`
  list-style: none;
  font-size: ${theme.typography.fontSizeText}px;
  font-weight: ${theme.typography.fontWeightRegular};
  color: inherit;
  letter-spacing: 0.5px;

  button {
    color: inherit;
    letter-spacing: 0.5px;
  }

  a,
  button {
    &:hover {
      color: ${theme.colors.blue};
    }
  }
`

export const LogoWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen?: boolean }>`
  max-width: 200px;

  svg {
    width: 100% !important;
    height: 24px !important;
  }
`

export const UnderlineStyle = `
  text-decoration: underline;
  text-underline-offset: ${theme.spacing.x05}px;
`

export const MenuItem = styled.li.withConfig({
  shouldForwardProp: (prop) => !['isMenuOpen', 'variant', 'isAtTop'].includes(prop),
})<{ isMenuOpen?: boolean; variant?: 'light' | 'dark'; isAtTop?: boolean }>`
  ${BaseMenuItemStyle}
  font-size: ${theme.typography.fontSizeTextSmall}px;

  &:not(:last-of-type) {
    margin-right: ${theme.spacing.x2}px;
  }

  a svg path {
    fill: currentColor;
  }

  ${({ variant, isMenuOpen, isAtTop }) =>
    variant === 'light' &&
    !isMenuOpen &&
    isAtTop &&
    `
      a,
      button {
        &:hover {
          ${UnderlineStyle}
          color: ${theme.colors.white};
        }

        &:focus {
          outline-color: ${theme.colors.white};
          color: ${theme.colors.white};
        }
      }
    `}

    @media ${theme.mediaQueries.mediaQueryDesktopLarge} {
      &:not(:last-of-type) {
        margin-right: ${theme.spacing.x3}px;
      }
    }
`

export const GroupLabel = styled.span`
  position: relative;
  display: block;
  margin-bottom: ${theme.spacing.x3}px;
  font-family: ${theme.fonts.overline.style.fontFamily};
  font-size: ${theme.typography.fontSizeTextSmall}px;
  color: ${theme.colors.grey};
  text-transform: uppercase;
`

export const MenuGroup = styled.div`
  display: block;
  margin-bottom: ${theme.spacing.x5}px;
`

export const MenuGroupMobile = styled.div`
  display: block;
  // List items already have 24px so we add on till 40px
  margin-bottom: ${theme.spacing.x2}px;
`

export const SubMenuItem = styled.li`
  ${BaseMenuItemStyle}

  margin-bottom: ${theme.spacing.x3}px;
`

export const MenuFooter = styled.div`
  min-height: 40px;
  border-top: 2px solid ${theme.colors.blueIce};
  background-color: ${theme.colors.white};
`

export const MenuFooterContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: ${theme.spacing.x05}px ${theme.spacing.x2}px;

  @media ${theme.mediaQueries.mediaQueryTablet} {
    padding: ${theme.spacing.x05}px ${theme.spacing.x4}px;
  }

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 1040px;
    pointer-events: all;
    padding: ${theme.spacing.x05}px 0;
  }

  @media (${theme.mediaQueries.mediaQueryDesktopLarge}) {
    max-width: ${theme.layout.gridWidthInner};
  }
`
