import React from 'react'
import { Slider, theme } from '@damen/ui'

import Link from '@/components/Link'

import { Background } from '@/types'
import { isValidLink } from '@/utils/slugUtils'
import { ArticleSliderFragment } from '@/queries/fragments/ArticleSliderFragment.generated'
import Container from '../Container'

interface Props {
  content: ArticleSliderFragment
}

const ArticleSlider: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const slides = content.category.slides.map((slide) => ({
    ...slide,
    title: slide.title ?? '',
    buttonComponent: isValidLink(slide.link)
      ? ({ children }: any) => (
          <Link.VNext type="link" link={slide.link}>
            {children}
          </Link.VNext>
        )
      : undefined,
    id: slide.id,
    img: {
      alt: slide.img.alt,
      src: slide.img.filename,
      sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 448px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 3), (min-width: 722px) calc(100vw / 3), 100vw`,
    },
  }))

  const mappedCategory: any = {
    ...content.category,
    buttonComponent:
      content.category.linkText &&
      isValidLink(content.category.link) &&
      (() => <Link.VNext type="button" link={content.category.link} text={content.category.linkText} />),
    slides,
  }

  return (
    <Container background={content.background as Background}>
      <Slider.Article id={content.id} background={content.background as Background} category={mappedCategory} />
    </Container>
  )
}

export default ArticleSlider
