/* eslint-disable */
// This is only used in the old navigation which will be removed in the near future
import React from 'react'
import { theme } from '@damen/ui'
import NextLink from 'next/link'
import styled from 'styled-components'

import { isHTTP, useFormattedRouter } from '@/hooks/formattedRouter'
import { StoryBlokLink } from '@/types'

interface Props {
  className?: string
  link: StoryBlokLink
  outline?: boolean
  scroll?: boolean
  testId?: string
  action?: () => void
}

/**
 * @deprecated The class should not be used
 */
const Next: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  link,
  scroll,
  outline = true,
  testId = null,
  action,
}) => {
  const [handleRouteClick] = useFormattedRouter(link)
  const contactModal = link.cachedUrl === 'contact'
  const mapModal = link.cachedUrl === 'map'
  const newletterModal = link.cachedUrl === 'newsletter'
  const feedbackModal = link.cachedUrl === 'feedback'
  const departmentContactModal = /^(global\/contact-forms\/)(.*)$/.test(link.cachedUrl)
  const departmentNewsletterModal = /^global\/newsletter-forms\/.*$/.test(link.cachedUrl)
  const donationModal = /^global\/donation-forms\/.*$/.test(link.cachedUrl)

  if (
    action &&
    link.cachedUrl &&
    !contactModal &&
    !mapModal &&
    !newletterModal &&
    !feedbackModal &&
    !departmentContactModal &&
    !donationModal &&
    !departmentNewsletterModal
  ) {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      action()
      handleRouteClick()
    }

    return (
      <Anchor
        className={className}
        data-testid={testId}
        href={`/${link.cachedUrl || link.url || ''}`}
        outline={outline}
        rel="noreferrer"
        onClick={(e) => handleClick(e)}
      >
        {children}
      </Anchor>
    )
  }

  if (
    action ||
    contactModal ||
    mapModal ||
    newletterModal ||
    feedbackModal ||
    departmentContactModal ||
    donationModal ||
    departmentNewsletterModal
  ) {
    return (
      <Button
        className={className}
        data-testid={testId}
        outline={outline}
        type="button"
        onClick={action || handleRouteClick}
      >
        {children}
      </Button>
    )
  }

  if (link.url?.includes('http') || link.url?.includes('www')) {
    let formattedLink = link.url

    if (isHTTP(link)) {
      formattedLink = formattedLink.replace('http', 'https')
    } else if (link.url.includes('www') && !link.url.includes('https://www')) {
      formattedLink = formattedLink.replace('www.', 'https://')
    }

    return (
      <Anchor
        className={className}
        data-testid={testId}
        href={formattedLink}
        outline={outline}
        rel="noopener"
        target="_blank"
      >
        {children}
      </Anchor>
    )
  }

  const url = link.anchor
    ? `${link.cachedUrl}#${link.anchor}` || `${link.url}#${link.anchor}`
    : link.cachedUrl || link.url

  return (
    <Anchor
      href={`/${url || ''}`}
      passHref
      scroll={scroll}
      className={className}
      data-testid={testId}
      outline={outline}
    >
      {children}
    </Anchor>
  )
}

const Anchor = styled(NextLink).withConfig({
  shouldForwardProp: (prop) => !['outline'].includes(prop),
})<{ outline: boolean }>`
  text-decoration: none;

  &,
  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  &:focus {
    outline: none;
  }

  ${({ outline }) =>
    outline &&
    `
      &:focus {
        color: inherit;
        outline: ${theme.accessibility.outline};
        outline-offset: 8px;
      }
  `}
`

const Button = styled.button.withConfig({
  shouldForwardProp: (prop) => !['outline'].includes(prop),
})<{ outline: boolean }>`
  display: inline-flex;
  width: 100%;
  cursor: pointer;
  padding: 0;
  background: transparent;
  text-align: inherit;
  border: none;

  & > * {
    width: 100%;
  }

  ${({ outline }) =>
    outline
      ? `
        &:focus {
          color: inherit;
          outline: ${theme.accessibility.outline};
          outline-offset: 8px;
        }
      `
      : `
        &:focus {
          outline: none;
        }
  `}
`

export default Next
