import React from 'react'
import { PardotForm as PardotFormComponent } from '@damen/ui'
import Container from '@/components/UI/Container'
import { PardotFormFragment } from '@/queries/fragments/PardotFormFragment.generated'

interface Props {
  content: PardotFormFragment
}

const PardotForm: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { title, subTitle, url, defaultHeight } = content
  return (
    <Container>
      <PardotFormComponent.Default
        title={title}
        subTitle={subTitle}
        src={url}
        width="100%"
        scrolling="no"
        height={defaultHeight}
        loading="lazy"
      />
    </Container>
  )
}

export default PardotForm
