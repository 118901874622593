import React, { useMemo } from 'react'

import { ProductQuickView as ProductQuickViewComponent, theme } from '@damen/ui'
import { Container } from '@/components/UI'
import Link from '@/components/Link'
import { formatCurrencyRange } from '@/utils/helpers/formatCurrency'
import { isValidLink } from '@/utils/uriUtil'

import { Props } from './types'

const ProductQuickView = ({ content: { title, link, linkText, products } }: Props) => {
  const mappedProducts = useMemo(
    () =>
      products?.map((product) => ({
        ...product,
        title: product.name,
        image: {
          alt: product.name,
          src: product.image?.filename,
          sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
        },
        specifications: product.specifications?.slice(0, 4).map((specification) => ({
          id: specification.id,
          label: specification.name,
          value: specification.value,
        })),
        price: product.price ? formatCurrencyRange(product.price) : undefined,
        buttonComponent: isValidLink(product.link)
          ? ({ children }: any) => (
              <Link.VNext type="link" link={product.link}>
                {children}
              </Link.VNext>
            )
          : null,
      })),
    [products],
  )
  const buttonComponent = useMemo(
    () => isValidLink(link) && linkText && (() => <Link.VNext type="button" link={link} text={linkText} />),
    [link, linkText],
  )
  return (
    <Container>
      <ProductQuickViewComponent title={title} items={mappedProducts} buttonComponent={buttonComponent} />
    </Container>
  )
}

export default ProductQuickView
