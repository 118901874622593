import React, { useCallback, useMemo } from 'react'
import { QuickNav as QuickNavComponent } from '@damen/ui'
import Link from '@/components/Link'
import Container from '@/components/UI/Container'

import { trimAndLowerCaseString } from '@/helpers/common'
import { QuickNavFragment } from '@/queries/fragments/QuickNavFragment.generated'
import { StoryblokLink } from '@/queries/schema.generated'

interface Props {
  content: QuickNavFragment
}

type MapItemsType = QuickNavFragment['mainItems'][0]

const QuickNav: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { title, label, links, subItems, mainItems } = content

  const handleOnClick = (link: StoryblokLink): void => {
    const id = link.anchor ? trimAndLowerCaseString(link.anchor) : null

    if (id) {
      const element = document.querySelector(`#${id}`)

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }

  const mapItems = useCallback(
    (array?: MapItemsType[]) =>
      array?.map((item) => ({
        ...item,
        buttonComponent:
          item.link &&
          (() => (
            <Link.VNext
              type="link"
              link={!item.link.anchor ? item.link : null}
              onClick={item.link.anchor ? () => handleOnClick(item.link) : null}
            >
              {item.title}
            </Link.VNext>
          )),
      })),
    [],
  )
  const mappedMainItems = useMemo(() => mapItems(mainItems), [mainItems, mapItems])
  const mappedSubItems = useMemo(() => mapItems(subItems), [subItems, mapItems])
  const mappedLinks = links?.map((x, index) => {
    return {
      id: index.toString(),
      // eslint-disable-next-line react/no-unstable-nested-components
      buttonComponent: () => <Link.VNext type="button" link={x.url} text={x.title} />,
    }
  })
  return (
    <Container background={content.background as any} componentType="quick-nav">
      <QuickNavComponent.Default
        title={title}
        overline={label}
        subSectionLinks={mappedSubItems}
        mainSectionLinks={mappedMainItems}
        links={mappedLinks}
      />
    </Container>
  )
}

export default QuickNav
