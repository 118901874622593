import React from 'react'
import { Heading, Tiles as TilesComponent } from '@damen/ui'

import Link from '@/components/Link'
import Container from '@/components/UI/Container'

import { trimAndLowerCaseString } from '@/helpers/common'
import { Background, ImageType } from '@/types'

type TileType = 'square' | 'rectangle' | 'small'

interface Tile {
  image?: ImageType
  title: string
  onClick: () => void
}

interface Row {
  id: string
  tiles: Tile[]
  type: TileType
}

interface Content {
  id: string
  background: Background
  rows: Row[]
  title?: string
}

interface Props {
  content: Content
}

const Tiles: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const setMax = (type: TileType) => {
    switch (type) {
      case 'rectangle':
        return 2
      case 'square':
        return 3
      default:
        return 4
    }
  }

  const rows =
    content.rows.map((row) => ({
      ...row,
      id: row.id,
      max: setMax(row.type),
      tiles: row.tiles.map((tile: any) => {
        if (row.type === 'small') {
          return {
            ...tile,
            image: null,
            buttonComponent:
              tile.link &&
              (({ children }: any) => (
                <Link.VNext type="link" link={tile.link}>
                  {children}
                </Link.VNext>
              )),
            id: tile.id,
            tileType: row.type,
          }
        }

        return {
          ...tile,
          buttonComponent:
            tile.link &&
            (({ children }: any) => (
              <Link.VNext type="link" link={tile.link}>
                {children}
              </Link.VNext>
            )),
          id: tile.id,
          image: {
            alt: tile.image?.alt,
            src: tile?.image?.filename,
          },
          tileType: row.type,
        }
      }),
    })) || []

  return (
    <Container background={content.background}>
      {content.title && (
        <Heading.HeadingThree id={trimAndLowerCaseString(content.title)}>{content.title}</Heading.HeadingThree>
      )}
      <TilesComponent.Default rows={rows} />
    </Container>
  )
}

export default Tiles
