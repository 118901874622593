import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { Heading, Layout, Paragraph, theme } from '@damen/ui'
import dynamic from 'next/dynamic'
import styled, { css } from 'styled-components'

import { useRouter } from 'next/router'
import Link from '@/components/Link'
import Overlay from '@/components/UI/Modal/Overlay'

import { trimAndLowerCaseString } from '@/helpers/common'
import {
  mapKey,
  showContactModal,
  showMapModal,
  showNewsletterModal,
  showFeedbackModal,
  showDepartmentContactModal,
  showDonationModal,
  showDepartmentNewsletterModal,
} from '@/lib/apollo/useApollo'

import { RenderSocialLinks } from './render'
import { Logo } from '@/components/Logo'
import { getNextPublicSite } from '@/utils/env'
import { MAP_QUERYSTRING } from '@/components/Forms/constants'
import setBodyScrollLock from '@/utils/bodyScrollLock'
import { FooterContent, Sites } from '@/queries/schema.generated'

const Map = dynamic(() => import('@/components/Map'), {
  ssr: false,
})

const ContactModal = dynamic(() => import('@/components/Modal/Contact'), {
  ssr: false,
})
const DepartmentContactModal = dynamic(() => import('@/components/Modal/DepartmentContact'), {
  ssr: false,
})
const DonationModal = dynamic(() => import('@/components/Modal/Donation'), {
  ssr: false,
})
const DepartmentNewsletterModal = dynamic(() => import('@/components/Modal/DepartmentNewsletter'), {
  ssr: false,
})
const NewsletterModal = dynamic(() => import('@/components/Modal/Newsletter'), {
  ssr: false,
})
const FeedbackModal = dynamic(() => import('@/components/Modal/Feedback'), {
  ssr: false,
})

const { Container } = Layout
const { accessibility, legacyMediaQueries, colors, typography, layout } = theme
const { Overline } = Heading

export interface NavigationItem {
  title: string
  onClick: (event: React.MouseEvent) => void
}

const mapSecondaryLinks = (secondaryLinks: any): { secondaryRowLeft: any[]; secondaryRowRight: any[] } => {
  const secondaryRowLeft: any[] = []
  const secondaryRowRight: any[] = []

  secondaryLinks.map((link: any, index: number) => {
    if (index % 2 === 0) {
      return secondaryRowLeft.push(link)
    }

    return secondaryRowRight.push(link)
  })

  return { secondaryRowLeft, secondaryRowRight }
}

interface Props {
  id: string
  content: FooterContent
  contactData?: any
  departmentsContactData?: any
  departmentsNewsletterData?: any
  donationData?: any
  feedbackData?: any
  newsletterData?: any
}

const Footer: React.FC<React.PropsWithChildren<Props>> = ({
  id,
  content,
  contactData,
  departmentsContactData,
  departmentsNewsletterData,
  donationData,
  newsletterData,
  feedbackData,
}) => {
  const router = useRouter()
  const showContact = useReactiveVar(showContactModal)
  const departmentContactSlug = useReactiveVar(showDepartmentContactModal)
  const donationSlug = useReactiveVar(showDonationModal)
  const departmentNewsletterSlug = useReactiveVar(showDepartmentNewsletterModal)
  const showNewsletter = useReactiveVar(showNewsletterModal)
  const showMap = useReactiveVar(showMapModal)
  const showFeedback = useReactiveVar(showFeedbackModal)
  const site = getNextPublicSite()
  const { secondaryRowLeft, secondaryRowRight } = mapSecondaryLinks(content.secondaryLinks)
  const hasSocialLinks = content.socialLinks.length > 0

  const departmentContact = departmentContactSlug
    ? departmentsContactData.find((x: any) => x?.slug === departmentContactSlug)
    : undefined
  const departmentNewsletter = departmentNewsletterSlug
    ? departmentsNewsletterData.find((x: any) => x?.slug === departmentNewsletterSlug)
    : undefined
  const donationForm = donationSlug ? donationData.find((x: any) => x?.slug === donationSlug) : undefined

  const closeMapModel = () => {
    if (router.asPath.includes(MAP_QUERYSTRING)) {
      router.back()
    }
  }

  return (
    <>
      <FooterContainer id={id}>
        <Container>
          <ScreenreaderTitle>{content.screenreaderTitle}</ScreenreaderTitle>
          {site === Sites.Albwardydamen && (
            <TitleContainer link="/" outline type="link">
              <Logo width={300} height={32} fill={theme.colors.white} />
              <SubTitle>{content.subTitle}</SubTitle>
            </TitleContainer>
          )}
          <Content hasSocialLinks={hasSocialLinks}>
            <Promotional>
              {site !== Sites.Albwardydamen && (
                <TitleContainer link="/" outline type="link">
                  <Logo width={170} height={32} fill={theme.colors.white} />
                  <SubTitle>{content.subTitle}</SubTitle>
                </TitleContainer>
              )}
              <PromotionLinks>
                {content.primaryLinks.map((item: any) => (
                  <StyledLink
                    key={`footer_promo_links_${item.label}`}
                    link={item?.link}
                    outline
                    testId={`button-${trimAndLowerCaseString(item.label)}`}
                    type="link"
                  >
                    {item.label}
                  </StyledLink>
                ))}
              </PromotionLinks>
            </Promotional>
            <NavigationLinks>
              {secondaryRowLeft.length > 0 && (
                <Navigation>
                  {secondaryRowLeft.map((item) => (
                    <StyledLink key={`footer_nav_items_left_${item.label}`} link={item.link} outline type="link">
                      {item.label}
                    </StyledLink>
                  ))}
                </Navigation>
              )}
              {secondaryRowRight.length > 0 && (
                <Navigation>
                  {secondaryRowRight.map((item) => (
                    <StyledLink key={`footer_nav_items_right_${item.label}`} link={item.link} outline type="link">
                      {item.label}
                    </StyledLink>
                  ))}
                </Navigation>
              )}
            </NavigationLinks>
            <Divider />
          </Content>
          {hasSocialLinks && (
            <Social>
              <RenderSocialLinks socialLinks={content.socialLinks} />
            </Social>
          )}
          <Disclaimer>
            {content.legal.disclaimer.label && (
              <DisclaimerLink
                key={`footer_disclaimer_links_${content.legal.disclaimer.label}`}
                link={content.legal.disclaimer.link}
                outline
                type="link"
              >
                {content.legal.disclaimer.label}
              </DisclaimerLink>
            )}
            {content.legal.privacy.label && (
              <DisclaimerLink
                key={`footer_disclaimer_links_${content.legal.privacy.label}`}
                link={content.legal.privacy.link}
                outline
                type="link"
              >
                {content.legal.privacy.label}
              </DisclaimerLink>
            )}
            {content.legal.termsAndConditions.label && (
              <DisclaimerLink
                key={`footer_disclaimer_links_${content.legal.termsAndConditions.label}`}
                link={content.legal.termsAndConditions.link}
                outline
                type="link"
              >
                {content.legal.termsAndConditions.label}
              </DisclaimerLink>
            )}
            <DisclaimerLink
              key={`footer_disclaimer_links_${content.legal.sitemap.label}`}
              link={content.legal.sitemap.link}
              outline
              type="link"
            >
              {content.legal.sitemap.label}
            </DisclaimerLink>
            <CopyWrightText>{content.legal.copyright}</CopyWrightText>
          </Disclaimer>
        </Container>
      </FooterContainer>

      {showContact && (
        <ContactModal
          content={contactData}
          showModal={showContact}
          handleCloseModal={(): void => {
            showContactModal(false)
            setBodyScrollLock(false)
            return null
          }}
        />
      )}
      {departmentContactSlug && (
        <DepartmentContactModal
          content={departmentContact?.content}
          showModal={!!departmentContactSlug}
          handleCloseModal={(): void => {
            showDepartmentContactModal(undefined)
            setBodyScrollLock(false)
            return null
          }}
          id={departmentContact.id}
          formName={departmentContact.formName}
        />
      )}
      {donationSlug && (
        <DonationModal
          content={donationForm?.content}
          showModal={!!donationSlug}
          handleCloseModal={(): void => {
            showDonationModal(undefined)
            setBodyScrollLock(false)
            return null
          }}
          id={donationForm.id}
        />
      )}
      {departmentNewsletterSlug && (
        <DepartmentNewsletterModal
          content={departmentNewsletter?.content}
          showModal={!!departmentNewsletterSlug}
          handleCloseModal={(): void => {
            showDepartmentNewsletterModal(undefined)
            setBodyScrollLock(false)
            return null
          }}
          id={departmentNewsletter.id}
          formName={departmentNewsletter?.formName}
        />
      )}
      {showNewsletter && (
        <NewsletterModal
          content={newsletterData}
          showModal={showNewsletter}
          handleCloseModal={(): void => {
            showNewsletterModal(false)
            setBodyScrollLock(false)
            return null
          }}
        />
      )}
      {showFeedback && (
        <FeedbackModal
          content={feedbackData}
          showModal={showFeedback}
          handleCloseModal={(): void => {
            showFeedbackModal(false)
            setBodyScrollLock(false)
            return null
          }}
        />
      )}

      {mapKey() && (
        <Overlay
          background={theme.colors.marineBlack}
          closeColorMobile={theme.colors.white}
          hasPadding={false}
          isOpen={showMap}
          onClose={closeMapModel}
        >
          <Map />
        </Overlay>
      )}
    </>
  )
}

const StyledLink = styled(Link.VNext)`
  &:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
`

const FooterContainer = styled.footer`
  background-color: ${colors.marineBlack};
  padding: 40px 0;

  @media ${legacyMediaQueries.md} {
    padding: 117px 0 78px;
  }

  button:focus {
    outline: ${accessibility.outlineInverse};
    outline-offset: 8px;
  }

  form button:focus {
    outline-offset: 2px;
  }
`

const Content = styled.div.withConfig({
  shouldForwardProp: (prop) => !['hasSocialLinks'].includes(prop),
})<{ hasSocialLinks?: boolean }>`
  margin-bottom: 32px;
  color: ${theme.colors.white};

  @media ${legacyMediaQueries.md} {
    display: flex;
    margin-bottom: ${({ hasSocialLinks }) => (hasSocialLinks ? '44px' : theme.layout.spacingXXL)};
  }
`

const Promotional = styled.div`
  width: 100%;

  @media ${legacyMediaQueries.md} {
    width: auto;
    flex-grow: 1;
  }
`

const TitleContainer = styled(Link.VNext)`
  width: 50%;
  margin-bottom: 24px;
  cursor: pointer;
  display: block;

  @media ${legacyMediaQueries.md} {
    width: 100%;
  }
`

const SubTitle = styled(Overline)`
  color: ${colors.white};
  font-size: ${typography.fontSizeLabel}px;
  letter-spacing: 1.38px;
  margin-top: 8px;
`

const PromotionLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  > ${StyledLink} {
    color: ${colors.white};
    letter-spacing: 0.75px;
    font-weight: normal;
  }

  > ${StyledLink} + ${StyledLink} {
    margin-top: 24px;
  }
`

const NavigationLinks = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 40px;

  @media ${legacyMediaQueries.md} {
    width: calc(4 / 12 * 100%);
    padding: 0;
    margin: 0 0 0 48px;
    flex-grow: 1;
  }
`

const Navigation = styled.div`
  display: flex;
  flex-direction: column;

  > ${StyledLink} {
    color: ${colors.white};
    letter-spacing: 0.6px;
    font-size: ${typography.fontSizeLabel}px;
    font-weight: normal;
  }

  > ${StyledLink} + ${StyledLink} {
    margin-top: 24px;
  }

  &:first-child {
    margin-right: 32px;
  }

  @media ${legacyMediaQueries.md} {
    &:first-child {
      margin-right: 40px;
    }
  }
`

const Divider = styled.div`
  width: calc(100% + 32px);
  border-top: 1px solid ${colors.white};
  border-bottom: 1px solid ${colors.white};
  opacity: 0.2;
  margin: 0 -16px;

  @media ${legacyMediaQueries.md} {
    display: none;
  }
`

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px 0;
  margin: 0 auto;

  @media ${legacyMediaQueries.md} {
    width: calc(3 / 12 * 100%);
    margin: 32px 0;
  }

  @media ${legacyMediaQueries.lg} {
    width: calc(2 / 12 * 100%);
  }
`

const Disclaimer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${layout.spacing};
  color: ${theme.colors.silverGrey500};

  @media ${legacyMediaQueries.md} {
    margin-top: ${layout.spacingM};
    width: 100%;
    align-items: inherit;
  }

  @media ${legacyMediaQueries.lg} {
    flex-direction: row;
    align-items: center;
  }
`

const disclaimerStyle = css`
  background-color: transparent;
  font-size: ${typography.fontSizeLabel}px;
  line-height: 18.8px;
  letter-spacing: 0.23px;
  text-align: left;
  padding: ${layout.spacingSM} 0;
  margin: 0;
  border: 0;
`

const DisclaimerLink = styled(Link.VNext)`
  ${disclaimerStyle}
  color:${theme.colors.silverGrey500};

  cursor: pointer;
  margin-right: 32px;
  text-decoration: underline;
  transition: text-decoration-color 200ms ease;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 6px;
  }

  @media ${legacyMediaQueries.mdMax} {
    & + & {
      margin-top: ${layout.spacing};
    }
  }
`

const CopyWrightText = styled(Paragraph.Default)`
  ${disclaimerStyle}

  @media ${legacyMediaQueries.mdMax} {
    margin-top: 8px;
  }

  @media ${legacyMediaQueries.lg} {
    flex-grow: 1;
    text-align: right;
  }
`

const ScreenreaderTitle = styled.h2`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`

export default Footer
