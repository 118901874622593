import { ReactNode } from 'react'
import ReactDOM from 'react-dom'

import { usePortalElement } from './PortalRoot'

interface Props {
  children: ReactNode
}

const Portal = ({ children }: React.PropsWithChildren<Props>) => {
  const portalElement = usePortalElement()

  return ReactDOM.createPortal(children, portalElement)
}

export default Portal
