import React, { useEffect, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import { theme } from '@damen/ui'

import { useReactiveVar } from '@apollo/client'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { StickyNavigation as StickyNavigationProps } from '@/queries/schema.generated'
import { showFamilyNav } from '@/lib/apollo/useApollo'
import { useOnRouteChangeStart } from '@/hooks/router/useOnRouteChange'
import TabBar from './components/Tabbar'
import StickyBar from './components/StickyBar'
import Link from '@/components/Link'
import { ButtonColor } from '@/components/Link/vNext/types'

interface Props {
  content: StickyNavigationProps
}

const StickyBarWrapper = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => !['isVisible'].includes(prop),
})<{ isVisible: boolean }>`
  position: fixed;
  display: flex;
  z-index: 12;
  top: 0;
  width: 100%;
  background: #fff;

  ${({ isVisible }) => isVisible && `opacity: 0; `}

  & > div {
    width: 100%;
  }

  @media ${theme.legacyMediaQueries.md} {
    & > div {
      width: 100%;
      height: 68px;
    }
  }
`

const getDistanceFromViewportCenter = (elem: HTMLElement, windowHeight: number) => {
  const middleWindow = window.scrollY + windowHeight / 2
  const centerPoint = elem.getBoundingClientRect().top + window.scrollY + elem.getBoundingClientRect().height / 2
  const distance = middleWindow - centerPoint

  return distance
}

const getMostCenteredElem = (elems: HTMLElement[]) => {
  return elems.reduce<HTMLElement>((match, elem) => {
    const distance = getDistanceFromViewportCenter(elem, window.visualViewport.height)

    if (Math.abs(getDistanceFromViewportCenter(match, window.visualViewport.height)) > Math.abs(distance)) {
      return elem
    }
    return match
  }, elems[0])
}

const StickyNavigation: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { items, buttonText, buttonLink, buttonColor } = content
  const router = useRouter()
  const componentRef = useRef<HTMLDivElement>(null)
  const isShowFamilyNav = useReactiveVar<boolean>(showFamilyNav)
  const [activeValue, setActiveValue] = useState(null)

  const clickHandler = (tab: string) => {
    setActiveValue(tab)
    const element = document.getElementById(tab)
    const y = element.getBoundingClientRect().top + window.scrollY + -150

    const scrollToSmoothly = (pos: number, time: number) => {
      const currentPos = window.scrollY
      let start: number | null = null
      window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start
        const progress = currentTime - start
        if (currentPos < pos) {
          window.scrollTo(0, ((pos - currentPos) * progress) / time + currentPos)
        } else {
          window.scrollTo(0, currentPos - ((currentPos - pos) * progress) / time)
        }
        if (progress < time) {
          window.requestAnimationFrame(step)
        } else {
          window.scrollTo(0, pos)
        }
      })
    }

    // window.scrollTo does not seem to work on mobiles so had to create an alternative
    scrollToSmoothly(y, 400)
    void router.push({ hash: tab }, undefined, { shallow: true, scroll: false })
  }

  const scrollToTop = (type: 'auto' | 'smooth') => {
    window.scroll({
      top: 0,
      behavior: type,
    })
  }

  const enrichedTabs = items.map((tab) => ({
    ...tab,
    text: tab.title,
    handle: tab.id,
  }))

  useEffect(() => {
    if (window.location.hash !== undefined && items.some((item) => item.id === window.location.hash.replace('#', ''))) {
      setActiveValue(window.location.hash.replace('#', ''))
    } else {
      setActiveValue(items[0].id)
    }
  }, [items])

  useEffect(() => {
    const component = componentRef.current
    const documentElements = items.map((item) => document.getElementById(item.id))

    let pendingFrame: number | undefined
    const onScroll = () => {
      if (pendingFrame) {
        return
      }

      pendingFrame = requestAnimationFrame(() => {
        const element = getMostCenteredElem(documentElements)
        setActiveValue(element.id)

        if (component) {
          const { clientHeight } = component

          showFamilyNav(component.getBoundingClientRect().top < 0 - clientHeight)
        }
        pendingFrame = undefined
      })
    }

    document.addEventListener('scroll', onScroll, {
      passive: true,
    })
    return () => document.removeEventListener('scroll', onScroll)
  }, [items])

  useOnRouteChangeStart(() => {
    showFamilyNav(false)
  })

  return (
    <>
      <div ref={componentRef}>
        <TabBar
          background="default"
          initialValue={activeValue}
          items={enrichedTabs}
          onTabClick={(tab: string) => clickHandler(tab)}
        />
      </div>
      {isShowFamilyNav && items.length > 0 && (
        <StickyBarWrapper animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }} isVisible>
          <StickyBar
            label={content.title}
            onLabelClick={() => scrollToTop('smooth')}
            button={() => (
              <Link.VNext type="button" text={buttonText} color={buttonColor as ButtonColor} link={buttonLink} />
            )}
          >
            <TabBar
              background="default"
              initialValue={activeValue}
              items={enrichedTabs}
              onTabClick={(tab: string) => clickHandler(tab)}
              inline
              button={() => (
                <Link.VNext type="button" text={buttonText} color={buttonColor as ButtonColor} link={buttonLink} />
              )}
            />
          </StickyBar>
        </StickyBarWrapper>
      )}
    </>
  )
}

export default StickyNavigation
