import React from 'react'
import { Video } from '@damen/ui'
import styled from 'styled-components'

import Container from '@/components/UI/Container'

import { useLazyLoad } from '@/hooks/lazyLoad'
import { Background, PageType, VideoType } from '@/types'

interface Content {
  id: string
  background: Background
  videoId: string
  videoType: VideoType
}

interface Props {
  content: Content
  maxWidth: number
  pageType: PageType
}

const DefaultVideo: React.FC<React.PropsWithChildren<Props>> = ({ content, maxWidth, pageType }) => {
  const { loading, ref } = useLazyLoad({})

  return (
    <Container background={content.background} maxWidth={maxWidth} pageType={pageType}>
      <Inner ref={ref}>
        <Video.Default loading={loading} videoId={content.videoId} videoType={content.videoType} />
      </Inner>
    </Container>
  )
}

const Inner = styled.div`
  max-width: ${({ theme }) => theme.layout.textContainerWidth};
  margin: 0 auto;
`

export default DefaultVideo
