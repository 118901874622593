export const chunkArray = <T>(arr: T[], n: number): T[][] => {
  const chunkLength = Math.max(arr.length / n, 1)
  const chunks = []
  for (let i = 0; i < n; i += 1) {
    if (chunkLength * (i + 1) <= arr.length) {
      chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)))
    }
  }
  return chunks
}

// https://stackoverflow.com/questions/8188548/splitting-a-js-array-into-n-arrays/51514813#answer-8189268
export const arrayIntoColumns = <T>(arr: T[], cols: number, balanced = true): T[][] => {
  if (cols < 2) return [arr]

  const arrayLength = arr.length
  const out = []
  let i = 0
  let size

  if (arrayLength % cols === 0) {
    size = Math.floor(arrayLength / cols)
    while (i < arrayLength) {
      out.push(arr.slice(i, (i += size)))
    }
  } else if (balanced) {
    while (i < arrayLength) {
      /* eslint-disable-next-line no-plusplus */ /* eslint-disable-next-line no-param-reassign */
      size = Math.ceil((arrayLength - i) / cols--)
      out.push(arr.slice(i, (i += size)))
    }
  } else {
    /* eslint-disable-next-line no-plusplus */ /* eslint-disable-next-line no-param-reassign */
    cols--
    size = Math.floor(arrayLength / cols)
    if (arrayLength % size === 0) size -= 1
    while (i < size * cols) {
      out.push(arr.slice(i, (i += size)))
    }
    out.push(arr.slice(size * cols))
  }

  return out
}

export const isArrayOfLenghtOne = (array?: unknown): boolean => array !== undefined && Array.isArray(array) && array.length === 1

export const isArrayOfAtLeastLenghtOne = (array?: unknown) =>
  array !== undefined && Array.isArray(array) && array.length >= 1

export const isArray = (array?: unknown) => array !== undefined && Array.isArray(array) && array.length > 0
