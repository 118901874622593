import React from 'react'
import { LinearBlur, theme } from '@damen/ui'
import styled, { css } from 'styled-components'

import {
  SiteNavigationSupportingItem,
  SiteNavigationItem,
  SiteNavigationNeedHelp,
  SiteNavigationOverflowItem,
  SiteNavigationProduct,
  SiteNavigationSecondSubItemsGroup,
  StoryblokLink,
} from '@/queries/schema.generated'
import { GroupLabel, MenuFooter, MenuFooterContent, MenuGroupMobile, MenuItem, MenuList, SubMenuItem } from './styles'
import MainMenuItem from './components/MobileMainMenuItem'
import { NAVIGATION_BREAKPOINT_LARGE } from './consts'
import MainSublevelLink from './components/MainSublevelLink'
import MenuLinkItem from './components/MenuLinkItem'
import { toggleColumn } from './SiteNavigationDesktop'
import ProductSublevelLink from './components/ProductSublevelLink'
import NeedHelp from './components/NeedHelp'
import SupportingItem from './components/SupportingItem'

const MenuContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 10;
  left: 0;
  right: 0;
  top: 56px;
  height: calc(100dvh - 56px);
  max-height: 0;
  overflow: hidden;
  margin: 0;
  background-color: ${theme.colors.white};
  transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);

  ${({ isOpen }) =>
    isOpen &&
    `
    max-height: 99999px;
    color: ${theme.colors.marineBlack};
    transition: max-height 0.6s cubic-bezier(1,0,1,0);
  `}

  @media (min-width: ${NAVIGATION_BREAKPOINT_LARGE}px) {
    display: none;
  }
`

const StyledMenuItem = styled(MenuItem)`
  font-size: ${theme.typography.fontSizeHeadingThreeMobile}px;
`

const StyledMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.x3}px;
  // Make sure we can scroll main items out under the gradient
  padding-bottom: ${theme.spacing.x10}px;
`

const MenuWrapper = styled.div`
  position: relative;
  width: 100%;
  // Subtract height of menu footer
  height: calc(100% - 40px);

  @media ${theme.mediaQueries.mediaQueryTablet} {
    width: 70%;
  }
`

const ScrollContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`

const AllItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${theme.spacing.x2}px ${theme.spacing.x2}px 0 ${theme.spacing.x2}px;

  @media ${theme.mediaQueries.mediaQueryTablet} {
    padding: ${theme.spacing.x3}px ${theme.spacing.x4}px ${theme.spacing.x11}px ${theme.spacing.x4}px;
  }
`

const BaseLevelMenu = css`
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.white};
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.2s ease-in-out,
    visibility 0.2s ease-in-out,
    transform 0.2s ease-in-out;
`

const OtherItemsWrapper = styled.div`
  margin-top: auto;
  // Offset so you can scroll items out of the LinearBlur element
  padding-bottom: ${theme.spacing.x11}px;

  @media ${theme.mediaQueries.mediaQueryTablet} {
    margin-top: ${theme.spacing.x3}px;
  }
`

const MobileMainSublevelLink = styled(MainSublevelLink)`
  display: flex;
  padding-right: ${theme.spacing.x05}px;
`

const StyledLinearBlur = styled(LinearBlur)`
  z-index: 0;
`

const FirstLevelMenu = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  ${BaseLevelMenu}
  z-index: 2;

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    visibility: visible;
    transform: translateX(100%);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.3s ease-in-out;
  `}
`

const SecondLevelMenu = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  ${BaseLevelMenu}
  width: 100%;
  z-index: 3;

  @media ${theme.mediaQueries.mediaQueryTablet} {
    width: calc(100% + 1px);
  }

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    visibility: visible;
    transform: translateX(100%);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.5s ease-in-out;
  `}
`

const ThirdLevelMenu = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isOpen'].includes(prop),
})<{ isOpen: boolean }>`
  ${BaseLevelMenu}
  z-index: 4;

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    visibility: visible;
    transform: translateX(100%);
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.5s ease-in-out;
  `}
`

interface Props {
  isMenuOpen?: boolean
  items?: SiteNavigationItem[]
  overflowItems?: SiteNavigationOverflowItem[]
  needHelp?: SiteNavigationNeedHelp
  activeItem: string
  setActiveItem: React.Dispatch<React.SetStateAction<string>>
  currentPage: string
}

interface SecondLevelProps {
  activeItem: string
  currentPage: string
  subLink: StoryblokLink
  setActiveItem: React.Dispatch<React.SetStateAction<string>>
  linkText?: string
  subItems: SiteNavigationSecondSubItemsGroup[]
  supportingItems?: SiteNavigationSupportingItem
}

interface ThirdLevelProps {
  activeItem: string
  currentPage: string
  subLink: StoryblokLink
  linkText?: string
  subItems: SiteNavigationProduct[]
}

const isFirstColumnItemActive = (activeItem: string, currentPage: string, link: StoryblokLink) => {
  // Check if the link is active
  // The currentPage check works as follows:
  // - currentPage is structured as /vessels/tugs/asd-tugs
  // - a split gives us ['', 'vessels', 'tugs', 'asd-tugs']
  // - on this level in the menu we have links like /vessels/tugs
  return (
    link.url === activeItem ||
    activeItem.startsWith(link.url) ||
    link.url === currentPage.split('/').slice(0, 3).join('/')
  )
}

const isSecondColumnItemActive = (activeItem: string, currentPage: string, link: StoryblokLink) => {
  // Check if the link is active
  // The currentPage check works as follows:
  // - currentPage is structured as /vessels/tugs/asd-tugs
  // - a split gives us ['', 'vessels', 'tugs', 'asd-tugs']
  // - on this level in the menu we have links like /vessels/tugs/asd-tugs
  return link.url === activeItem || link.url === currentPage.split('/').slice(0, 4).join('/')
}

const ThirdLevel: React.FC<React.PropsWithChildren<ThirdLevelProps>> = ({
  activeItem,
  subLink,
  linkText,
  subItems,
  currentPage,
}) => (
  <ThirdLevelMenu isOpen={subLink.url === activeItem}>
    <ScrollContainer>
      <AllItemsWrapper>
        {linkText && <MobileMainSublevelLink link={subLink} size="large" title={linkText} currentPage={currentPage} />}
        <MenuList>
          {subItems?.map(({ id, title, link, image, description, iconType }) => (
            <li key={id}>
              <ProductSublevelLink
                key={id}
                id={id}
                title={title}
                link={link}
                image={image}
                text={description}
                iconType={iconType}
                currentPage={currentPage}
              />
            </li>
          ))}
        </MenuList>
      </AllItemsWrapper>
    </ScrollContainer>
  </ThirdLevelMenu>
)

const SecondLevel: React.FC<React.PropsWithChildren<SecondLevelProps>> = ({
  activeItem,
  subLink,
  setActiveItem,
  linkText,
  subItems,
  currentPage,
  supportingItems,
}) => {
  const isSecondColumnActive = subLink?.url === activeItem || activeItem.startsWith(subLink?.url)

  return (
    <SecondLevelMenu isOpen={isSecondColumnActive}>
      <ScrollContainer>
        <AllItemsWrapper>
          {linkText && (
            <MobileMainSublevelLink link={subLink} size="large" title={linkText} currentPage={currentPage} />
          )}
          {subItems?.map(({ label: secondLevelLabel, subItems: secondLevelGroupSubItems, id: secondLevelId }) => (
            <MenuGroupMobile key={secondLevelId}>
              {secondLevelLabel && <GroupLabel>{secondLevelLabel}</GroupLabel>}
              <MenuList>
                {secondLevelGroupSubItems?.map(
                  ({
                    id: secondLevelSubId,
                    title: secondLevelSubTitle,
                    link: secondLevelSubLink,
                    linkText: secondLevelSubLinkText,
                    products: secondLevelSubItems,
                  }) => (
                    <SubMenuItem key={secondLevelSubId}>
                      <MenuLinkItem
                        title={secondLevelSubTitle}
                        link={secondLevelSubLink}
                        currentPage={currentPage}
                        isActive={isSecondColumnItemActive(activeItem, currentPage, secondLevelSubLink)}
                        onClick={toggleColumn(
                          secondLevelSubItems,
                          secondLevelSubLink,
                          activeItem,
                          setActiveItem,
                          subLink,
                        )}
                      />
                      {/* THIRD LEVEL */}
                      {isSecondColumnActive && (
                        <ThirdLevel
                          activeItem={activeItem}
                          currentPage={currentPage}
                          subLink={secondLevelSubLink}
                          linkText={secondLevelSubLinkText}
                          subItems={secondLevelSubItems}
                        />
                      )}
                    </SubMenuItem>
                  ),
                )}
              </MenuList>
            </MenuGroupMobile>
          ))}
          <OtherItemsWrapper>
            <SupportingItem supportingItem={supportingItems} activeItem={activeItem} currentPage={currentPage} />
          </OtherItemsWrapper>
        </AllItemsWrapper>
      </ScrollContainer>
    </SecondLevelMenu>
  )
}

const SiteNavigationMobile: React.FC<React.PropsWithChildren<Props>> = ({
  activeItem,
  isMenuOpen,
  items,
  overflowItems,
  needHelp,
  setActiveItem,
  currentPage,
}) => {
  return (
    <MenuContainer isOpen={isMenuOpen}>
      <MenuWrapper>
        <ScrollContainer>
          <AllItemsWrapper>
            <StyledMenuList>
              {items.map(({ id, title, link, linkText, subItems, supportingItem }) => {
                const Component = StyledMenuItem
                const isFirstLevelOpen = link.url === activeItem || activeItem.startsWith(link.url)

                return (
                  <Component key={id} isMenuOpen={isMenuOpen}>
                    <MainMenuItem
                      id={id}
                      title={title}
                      activeItem={activeItem}
                      link={link}
                      setActiveItem={setActiveItem}
                      subItems={subItems}
                      isMenuOpen={isMenuOpen}
                      currentPage={currentPage}
                    />
                    {/* FIRST LEVEL */}
                    <FirstLevelMenu isOpen={isFirstLevelOpen}>
                      <ScrollContainer>
                        <AllItemsWrapper>
                          {linkText && (
                            <MobileMainSublevelLink
                              link={link}
                              size="large"
                              title={linkText}
                              currentPage={currentPage}
                            />
                          )}
                          {subItems?.map(({ label, subItems: groupSubItems, id: subItemId }) => (
                            <MenuGroupMobile key={subItemId}>
                              {label && <GroupLabel>{label}</GroupLabel>}
                              <MenuList>
                                {groupSubItems?.map(
                                  ({
                                    id: subId,
                                    title: subTitle,
                                    link: subLink,
                                    linkText: subLinkText,
                                    subItems: firstLevelSubItems,
                                  }) => (
                                    <SubMenuItem key={subId}>
                                      <MenuLinkItem
                                        title={subTitle}
                                        link={subLink}
                                        currentPage={currentPage}
                                        isActive={isFirstColumnItemActive(activeItem, currentPage, subLink)}
                                        onClick={toggleColumn(
                                          firstLevelSubItems,
                                          subLink,
                                          activeItem,
                                          setActiveItem,
                                          link,
                                        )}
                                      />
                                      {/* SECOND LEVEL */}
                                      {firstLevelSubItems?.length > 0 && isFirstLevelOpen && (
                                        <SecondLevel
                                          activeItem={activeItem}
                                          currentPage={currentPage}
                                          subLink={subLink}
                                          setActiveItem={setActiveItem}
                                          linkText={subLinkText}
                                          subItems={firstLevelSubItems}
                                          supportingItems={supportingItem}
                                        />
                                      )}
                                    </SubMenuItem>
                                  ),
                                )}
                              </MenuList>
                            </MenuGroupMobile>
                          ))}
                          <OtherItemsWrapper>
                            <SupportingItem
                              supportingItem={supportingItem}
                              activeItem={activeItem}
                              currentPage={currentPage}
                            />
                          </OtherItemsWrapper>
                        </AllItemsWrapper>
                      </ScrollContainer>
                    </FirstLevelMenu>
                  </Component>
                )
              })}
              {overflowItems?.map(({ id, title, link }) => {
                const Component = StyledMenuItem

                return (
                  <Component key={id} isMenuOpen>
                    <MainMenuItem
                      id={id}
                      title={title}
                      activeItem={activeItem}
                      link={link}
                      setActiveItem={setActiveItem}
                      subItems={null}
                      isMenuOpen={isMenuOpen}
                      currentPage={currentPage}
                    />
                  </Component>
                )
              })}
            </StyledMenuList>
          </AllItemsWrapper>
        </ScrollContainer>
        <StyledLinearBlur />
      </MenuWrapper>
      <MenuFooter>
        <MenuFooterContent>{needHelp && <NeedHelp {...needHelp} />}</MenuFooterContent>
      </MenuFooter>
    </MenuContainer>
  )
}

export default SiteNavigationMobile
