import React from 'react'
import { render, MARK_LINK, NODE_PARAGRAPH, NODE_UL } from 'storyblok-rich-text-react-renderer'
import styled from 'styled-components'
import { Icon, NextImage, theme } from '@damen/ui'
import Link from '@/components/Link'

type RichTextMode = 'plain' | 'html'

interface BynderImage {
  files: {
    transformBaseUrl: {
      url: string
      width: number | null
      height: number | null
      fileSize: number | null
    }
  }
}

interface BynderImageBlok {
  image?: BynderImage[]
  alt?: string
}

const StyledLiWithArrow = styled.li`
  position: relative;
  padding-left: 21px;
  text-align: left;

  > p:first-of-type {
    margin: 0;
  }

  > p:not(:last-of-type) {
    margin-top: ${theme.layout.spacing};
    margin-bottom: 0;
  }

  svg {
    position: absolute;
    left: 0;
    top: 8px;
    width: 9px !important;
    margin: 0;
  }
`

const StyledLiWithDot = styled.li.withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop),
})<{ color: string }>`
  position: relative;
  padding-left: 20px;
  text-align: left;

  > p:first-child {
    margin: 0;
  }

  > p:not(:first-child) {
    margin-top: ${theme.layout.spacing};
    margin-bottom: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: ${({ color }) => color};
    border-radius: 50%;
  }
`

const ImageWrapper = styled.p`
  // Image position is absolute and could not be adjusted without adding !important
  img {
    position: relative !important;
  }
`

export const renderRichText = (content: any, emailSubject?: string, mode: RichTextMode = 'html') => {
  
  return render(content, {
    markResolvers: {
      [MARK_LINK]: (children, props) => {
        const { href, linktype } = props
        if (linktype === 'email') {
          // Add subject if href has no subject
          const subject = href.includes('?') ? `&subject=${emailSubject}` : `?subject=${emailSubject}`
          const emailLink = href.includes('subject') ? href : `${href}${subject}`
          // TODO Add support for mail links to VNext
          return <a href={`mailto:${emailLink}`}>{children}</a>
        }
        return (
          <Link.VNext type="raw" link={href}>
            {children}
          </Link.VNext>
        )
      },
    },
    blokResolvers: {
      bynder_image: (children: BynderImageBlok) => {
        return <ImageWrapper>
          <NextImage
            src={children.image[0].files.transformBaseUrl.url}
            alt={children.alt}
            fill
          />
        </ImageWrapper>
      }
    },    
    nodeResolvers: {
      [NODE_PARAGRAPH]: (children) => {
        if (mode === 'plain') {
          return <>{children}</>
        }
        return <p>{children}</p>
      },
      [NODE_UL]: (children: any) => {
        // below is the structure of how Storyblok returns list data
        const className = children[0]?.props?.children[0]?.props?.children[0]?.props?.className
        const listClassName = className?.startsWith('ul-') ? 'ul-bare' : ''

        return (
          <ul className={listClassName}>
            {children.map((item: any) => {
              if (className && (className === 'ul-arrow-blue' || className === 'ul-arrow-orange')) {
                return (
                  <StyledLiWithArrow key={item.key}>
                    <Icon.LeftChevron
                      fill={className === 'ul-arrow-orange' ? theme.colors.orange : theme.colors.blue}
                      height={12}
                    />
                    {item.props.children}
                  </StyledLiWithArrow>
                )
              }
              if (className && (className === 'ul-dot-blue' || className === 'ul-dot-orange')) {
                return (
                  <StyledLiWithDot key={item.key} color={className === 'ul-dot-orange' ? theme.colors.orange : theme.colors.blue}>
                    {item.props.children}
                  </StyledLiWithDot>
                )
              }
              return item
            })}
          </ul>
        )
      },
    },
  })
}
