import React from 'react'

import { EventDetails as EventDetailsComponent, theme } from '@damen/ui'
import { Container } from '@/components/UI'
import { EventDetailsFragment } from '@/queries/fragments/EventDetailsFragment.generated'
import Link from '@/components/Link'
import { renderRichText } from '@/utils/richTextUtils'
import { isNullOrUndefined } from '@/utils/nullUtils'
import SocialShare from '@/components/SocialShare'

interface Props {
  content: EventDetailsFragment
}

const EventDetails: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const logo = content.sidebar?.logo?.filename
    ? {
        src: content.sidebar.logo.filename,
        alt: content.sidebar.logo.alt,
        sizes: `${theme.legacyMediaQueries.md} 328px, 100vw`,
      }
    : null
  const image = content.contentImage.filename
    ? {
        src: content.contentImage.filename,
        alt: content.contentImage.alt,
        sizes: `${theme.legacyMediaQueries.lg} calc(100vw - 616px), ${theme.legacyMediaQueries.md} calc(100vw - 536px), 100vw`,
      }
    : null
  const programText = content.programText ? renderRichText(JSON.parse(content.programText)) : null
  const websiteLink =
    !isNullOrUndefined(content.websiteLink?.url) && content.websiteLinkText
      ? () => <Link.VNext type="contentlink" link={content.websiteLink} text={content.websiteLinkText} />
      : null

  const mappedData = {
    ...content,
    introduction: renderRichText(JSON.parse(content.introduction)),
    programText,
    linkComponent: websiteLink,
    image,
    sidebar: {
      ...content.sidebar,
      image: logo,
      linkComponent: websiteLink,
      shareComponent: () => <SocialShare />,
    },
  }

  return (
    <Container>
      <EventDetailsComponent {...mappedData} />
    </Container>
  )
}

export default EventDetails
