import React, { useEffect, useState, useRef } from 'react'

import { Icon, Navigation, TabBar as TabBarComponent, theme } from '@damen/ui'

import { useRouter } from 'next/router'
import { useReactiveVar } from '@apollo/client'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { TabsFamily as TabsFamilyContent } from '@/queries/schema.generated'
import { Background } from '@/types'
import { showFamilyNav, toggleNav } from '@/lib/apollo/useApollo'
import { useOnRouteChangeStart } from '@/hooks/router/useOnRouteChange'

interface ExtendedProps extends TabsFamilyContent {
  label: string
  title: string
}

interface Props {
  content: ExtendedProps
}

const StickyBarWrapper = styled(motion.div).withConfig({
  shouldForwardProp: (prop) => !['isVisible'].includes(prop),
})<{ isVisible: boolean }>`
  position: fixed;
  display: flex;
  z-index: 12;
  top: 0;
  width: 100%;
  background: #fff;

  ${({ isVisible }) => isVisible && `opacity: 0 !important; `}

  & > div {
    width: 100%;
    height: 48px;
  }

  @media ${theme.legacyMediaQueries.md} {
    & > div {
      width: 100%;
      height: 68px;
    }
  }
`

const TabsFamily: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { background = 'default', tabs } = content
  const componentRef = useRef<HTMLDivElement>(null)
  const navToggle = useReactiveVar<boolean>(toggleNav)
  const isShowFamilyNav = useReactiveVar<boolean>(showFamilyNav)

  const { query, push, route } = useRouter()
  const [activeValue, setActiveValue] = useState(null)
  const slugs = (query.slugs as string[]) || []

  const clickHandler = (tab: string) => {
    setActiveValue(tab)
    const path = slugs.join('/')

    void push(`/${route.split('/')[1]}/${path}?view=${tab}`, undefined, { shallow: true })
  }

  const scrollToTop = (type: 'auto' | 'smooth') => {
    window.scroll({
      top: 0,
      behavior: type,
    })
  }

  const enrichedTabs = tabs.map((tab) => ({
    ...tab,
    handle: tab.text.toLowerCase(),
  }))

  const toggles =
    tabs && tabs.length > 0
      ? tabs.map((item) => ({
          label: item.text,
          onClick: () => {
            clickHandler(item.text.toLowerCase())
            scrollToTop('auto')
          },
        }))
      : []

  const handleNavToggle = () => {
    document.body.classList.toggle('ReactModal__Body--open')
    toggleNav(!navToggle)
  }

  useEffect(() => {
    if (query.view !== undefined) {
      setActiveValue(`${query.view}`)
    } else {
      setActiveValue(tabs[0].text.toLowerCase())
    }
  }, [query.view, tabs])

  useEffect(() => {
    const component = componentRef.current

    function onScroll() {
      if (component) {
        const { offsetTop } = component
        showFamilyNav(component.getBoundingClientRect().top < 0 - offsetTop)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useOnRouteChangeStart(() => {
    showFamilyNav(false)
  })

  return (
    <>
      <div ref={componentRef}>
        <TabBarComponent.Default
          background={background as Background}
          initialValue={activeValue}
          items={enrichedTabs}
          onTabClick={(tab: string) => clickHandler(tab)}
        />
      </div>
      {isShowFamilyNav && tabs.length > 0 && (
        <StickyBarWrapper animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }} isVisible={navToggle}>
          <Navigation.StickyBar
            initialValue={activeValue}
            buttonIcon={Icon.Boat}
            buttonText="My Damen"
            label={content.title}
            toggles={toggles}
            onButtonClick={() => window.open('https://my.damen.com', '_blank')}
            onLabelClick={() => scrollToTop('smooth')}
            onMenuToggle={handleNavToggle}
          />
        </StickyBarWrapper>
      )}
    </>
  )
}

export default TabsFamily
