import React, { createContext, useContext, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import { ModalQuoteFragment } from '@/queries/fragments/Modal/Quote.generated'
import { QuoteModalProps, UsedOrCharterProductModalProps } from '@/components/UI/Modal/types'

const QuoteModal = dynamic(() => import('@/components/Family/Modal/Quote'), { ssr: false })

interface ModalQuoteContextProps {
  modalQuoteData?: ModalQuoteFragment
  setModal: React.Dispatch<React.SetStateAction<QuoteModalProps | UsedOrCharterProductModalProps | null>>
}

const ModalQuoteContext = createContext<ModalQuoteContextProps | null>(null)

export interface ModalQuoteContextProviderProps {
  modalQuoteData?: ModalQuoteFragment
  children: React.ReactNode | React.ReactNode[] | null
}

const ModalQuoteContextProvider = ({ children, modalQuoteData }: ModalQuoteContextProviderProps) => {
  const [modal, setModal] = useState<QuoteModalProps | UsedOrCharterProductModalProps | null>(null)
  const context = useMemo(() => {
    return {
      modalQuoteData,
      setModal,
    }
  }, [modalQuoteData, setModal])

  if (!modalQuoteData) {
    // Fragment needed as wrapper for the provider
    return <>{children}</>
  }

  return (
    <ModalQuoteContext.Provider value={context}>
      {children}
      <QuoteModal selectedModal={modal} handleCloseModal={() => setModal(null)} modalQuote={modalQuoteData.content} />
    </ModalQuoteContext.Provider>
  )
}

export const useModalQuoteContext = () => {
  return useContext(ModalQuoteContext)
}

export default ModalQuoteContextProvider
