import { isNullOrUndefined } from '../../../utils/nullUtils'
import { formatStoryblokLink, formatUrl, isValidLink } from '@/utils/uriUtil'
import type { ButtonLink } from './types'

export const getLinkFromLink = (link: ButtonLink) => {
  if (isNullOrUndefined(link)) {
    return null
  }
  if (typeof link === 'string') {
    if (link.startsWith('tel:') || link.startsWith('mailto:')) {
      return link
    }
    return formatUrl(link)
  }

  if (!isValidLink(link)) {
    return null
  }
  return formatStoryblokLink(link)
}
