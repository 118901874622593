import React, { useCallback, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import type { Asset, Assets } from '@damen/ui/lib/esm/types/assets'
import { ProductDetails as ProductDetailsComponent, Icon, theme } from '@damen/ui'
import { GalleryItemType, Product, ProductDetails } from '@/queries/schema.generated'
import Link from '@/components/Link'
import { ButtonColor } from '@/components/Link/vNext/types'
import { ModalProps } from '../Modal/types'
import { QUOTE_QUERYSTRING } from '@/components/Forms/constants'
import { Container } from '@/components/UI'
import { onDownloadClick, useDownloadModal } from '@/hooks/modal/download'

interface Props {
  content: ProductDetails
  setOpenModal: (props: ModalProps) => void
}

const ProductDetailCard: React.FC<React.PropsWithChildren<Props>> = ({ content, setOpenModal }) => {
  const productDetail = content
  const testId = productDetail.product.name.replaceAll(' ', '-')
  const router = useRouter()

  const handleQuoteModal = useCallback(
    (product: Product) => {
      if (!setOpenModal) return null

      setOpenModal({
        type: 'quote',
        data: {
          product,
        },
      });
    },
    [setOpenModal],
  )

  const handleDownloadModal = useCallback(() => {
    if (!setOpenModal) return null

    setOpenModal({
      type: 'download',
      data: {
        link: productDetail.downloadLink,
        title: productDetail.product.name,
        product: productDetail.product,
      },
    });
  }, [productDetail.downloadLink, productDetail.product, setOpenModal])

  useDownloadModal(handleDownloadModal)

  useEffect(() => {
    const handleHashChange = (url: string) => {
      if (url.includes(QUOTE_QUERYSTRING)) {
        handleQuoteModal(productDetail.product)
      }
    }

    router.events.on('hashChangeComplete', handleHashChange)

    return () => {
      router.events.off('hashChangeComplete', handleHashChange)
    }
  }, [router.events, productDetail.product, productDetail.downloadLink, handleDownloadModal, handleQuoteModal])

  const downloadButton = (color: ButtonColor) =>
    productDetail.downloadText &&
    !!productDetail.downloadLink?.cachedUrl &&
    (() => {
      if (productDetail.enableDirectProductSheetDownload) {
        return (
          <StyledLink
            type="button"
            link={productDetail.downloadLink}
            text={productDetail.downloadText}
            icon={() => <Icon.FileDownload width={16} height={16} fill={theme.colors.blue} />}
            iconSize={16}
            color={color}
          />
        )
      }

      return (
        <StyledLink
          type="button"
          color={color}
          icon={Icon.FileDownload}
          iconSize={16}
          onClick={() => onDownloadClick()}
          dataAttrs={{
            'data-gtm-product': productDetail.product.shipType,
            'data-gtm-action': 'download',
          }}
          link={null}
          text={productDetail.downloadText}
          testId={`productDetail.button-download-${testId}`}
        />
      )
    })

  const configurationButton =
    productDetail.configureText &&
    !!productDetail.configureLink?.cachedUrl &&
    (() => <StyledLink type="contentlink" link={productDetail.configureLink} text={productDetail.configureText} />)

  const virtualTourButton =
    productDetail.virtualTourText &&
    !!productDetail.virtualTourLink?.cachedUrl &&
    (() => (
      <StyledLink
        type="button"
        link={productDetail.virtualTourLink}
        text={productDetail.virtualTourText}
        color="whiteBlue"
        icon={Icon.Eye}
      />
    ))

  const assets: Assets = useMemo(() => {
    return {
      ...productDetail.assets,
      items: productDetail.assets?.items.map((item) => {
        const result: Asset = {
          ...item,
          thumbnail: {
            src: item?.thumbnail,
            alt: item?.title,
            sizes: `${theme.legacyMediaQueries.lg} 200px, ${theme.legacyMediaQueries.md} 150px, 50vw`,
          },
          overviewImage: {
            src: item?.thumbnail,
            alt: item?.title,
            sizes: `${theme.legacyMediaQueries.lg} 600px, ${theme.legacyMediaQueries.md} 460px, 100vw`,
          },
          linkComponent:
            item.type === GalleryItemType.VirtualTour && productDetail.virtualTourLink?.url
              ? ({ children }) => (
                  <Link.VNext type="link" link={productDetail.virtualTourLink}>
                    {children}
                  </Link.VNext>
                )
              : undefined,
        }
        return result
      }),
    }
  }, [productDetail.assets, productDetail.virtualTourLink])

  return (
    <Container>
      <ProductCardContainer>
        <ProductDetailsComponent.Default
          overviewSectionId={productDetail.overviewSectionId}
          specificationsSectionId={productDetail.specificationsSectionId}
          gallerySectionId={productDetail.gallerySectionId}
          description={productDetail.description}
          downloadButton={downloadButton('whiteBlue')}
          configurationButton={configurationButton}
          highlights={productDetail.highlights || null}
          options={productDetail.options}
          keyFigures={productDetail.keyFigures?.columns?.length > 0 ? productDetail.keyFigures : null}
          specifications={productDetail.specifications}
          galleryItems={productDetail.galleryItems}
          assets={assets}
          virtualTourButton={virtualTourButton}
        />
      </ProductCardContainer>
    </Container>
  )
}

const ProductCardContainer = styled.div`
  width: 100%;
  max-width: 1208px;
`

const StyledLink = styled(Link.VNext)`
  display: inline-flex;

  a {
    padding: 0;

    &:hover {
      background: transparent;
    }
  }
`

export default ProductDetailCard
