import React from 'react'

import { Icon, ProjectDetails as ProjectDetailsComponent, theme } from '@damen/ui'
import { Container } from '@/components/UI'
import { ProjectDetailsGalleryItemType, ProjectDetails as ProjectDetailsProps } from '@/queries/schema.generated'
import Link from '@/components/Link'
import { arrayIntoColumns } from '@/utils/arrayUtils'
import { renderRichText } from '@/utils/richTextUtils'

interface Props {
  content: ProjectDetailsProps
}

const ProjectDetails: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { projectSpecifications: specifications, shipyardInfo, title } = content

  const mappedAssets = content.projectAssets?.items.map((asset) => {
    return {
      ...asset,
      thumbnail: {
        src: asset.thumbnail,
        alt: asset.title,
        sizes: `${theme.legacyMediaQueries.lg} 200px, ${theme.legacyMediaQueries.md} 150px, 50vw`,
      },
      overviewImage: {
        src: asset.thumbnail,
        alt: asset.title,
        sizes: `${theme.legacyMediaQueries.lg} 600px, ${theme.legacyMediaQueries.md} 460px, 100vw`,
      },
      linkComponent:
        asset.type === ProjectDetailsGalleryItemType.VirtualTour && content.virtualTourLink?.url
          ? ({ children }: any) => (
              <Link.VNext type="link" link={content.virtualTourLink}>
                {children}
              </Link.VNext>
            )
          : undefined,
    }
  })

  const shipyardButtonComponent =
    content.shipyardInfo?.buttonText && content.shipyardInfo.link
      ? () => (
          <Link.VNext
            type="button"
            link={content.shipyardInfo?.link}
            dataAttrs={{ 'data-gtm-product': title, 'data-gtm-action': 'quote' }}
            text={content.shipyardInfo.buttonText}
          />
        )
      : null

  const virtualTourButton =
    content.virtualTourText &&
    !!content.virtualTourLink?.url &&
    (() => (
      <Link.VNext
        type="button"
        link={content.virtualTourLink}
        text={content.virtualTourText}
        color="whiteBlue"
        icon={Icon.Eye}
      />
    ))

  const shipyardImage = {
    alt: shipyardInfo?.image?.alt,
    src: shipyardInfo?.image?.filename,
  }

  const chunkedSpecs = arrayIntoColumns(specifications.items, 2).map((x, index) => {
    return {
      id: `column-${index}`,
      items: x,
    }
  })

  return (
    <Container background="light">
      <ProjectDetailsComponent
        buttonComponent={
          content.ctaText &&
          (() => <Link.VNext type="button" link={content.link} text={content.ctaText} color="orange" />)
        }
        description={content.description ? renderRichText(JSON.parse(content.description)) : ''}
        specifications={{
          columns: chunkedSpecs,
        }}
        assets={{
          title: content.projectAssets?.title,
          items: mappedAssets,
        }}
        downloads={{
          title: content.projectDownloads?.title,
          showMore: content.projectDownloads?.showMore,
          showLess: content.projectDownloads?.showLess,
          items: content.projectDownloads?.items,
        }}
        title={title}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // TODO fix types
        galleryItems={content.projectGalleryItems}
        shipyardInfo={
          shipyardInfo
            ? {
                title: shipyardInfo.title,
                description: shipyardInfo.description,
                image: shipyardImage,
                buttonComponent: shipyardButtonComponent,
              }
            : null
        }
        virtualTourButton={virtualTourButton}
      />
    </Container>
  )
}

export default ProjectDetails
