import React, { useState } from 'react'
import { Collage, Video, theme } from '@damen/ui'

import { ContainerVertical, Modal } from '@/components/UI'

import { ImageType, Spacing, VideoType } from '@/types'
import setBodyScrollLock from '@/utils/bodyScrollLock'

interface Content {
  id: string
  imageLarge: ImageType
  imageMedium: ImageType
  imageSmall: ImageType
  label?: string
  spacing?: Spacing
  text?: string
  title: string
  videoId?: string
  videoType?: VideoType
}

interface Props {
  content: Content
}

const CollageSecondary: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const [showModal, setShowModal] = useState(false)

  const images = {
    large: {
      alt: content.imageLarge.alt,
      src: content.imageLarge.filename,
      sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 2 + 120px), ${theme.legacyMediaQueries.md} 50vw, 100vw`,
    },
    medium: {
      alt: content.imageMedium.alt,
      src: content.imageMedium.filename,
      sizes: `${theme.legacyMediaQueries.md} 50vw`,
    },
    small: {
      alt: content.imageSmall.alt,
      src: content.imageSmall.filename,
      sizes: `${theme.legacyMediaQueries.lg} calc(((100vw - 240px) / 2) / 2), ${theme.legacyMediaQueries.md} calc(((100vw - 160px) / 2) / 2), calc(100vw / 3)`,
    },
  }

  return (
    <ContainerVertical verticalSpacing={content.spacing}>
      <Collage.Secondary
        images={images}
        overline={content.label}
        title={content.title}
        text={content.text}
        key={content.id}
        videoOnClick={content.videoId ? () => setShowModal(true) : null}
      />
      {content.videoId && (
        <Modal.Default
          isOpen={showModal}
          onClose={() => {
            setShowModal(false)
            setBodyScrollLock(false)
          }}
        >
          <Video.Default autoplay={1} mute={false} videoId={content.videoId} videoType={content.videoType} />
        </Modal.Default>
      )}
    </ContainerVertical>
  )
}

export default CollageSecondary
