import React from 'react'
import { Testimonials as TestmonialsComponent, theme } from '@damen/ui'

import Container from '@/components/UI/Container'

import { Background } from '@/types'
import Link from '@/components/Link'
import { TestimonialsFragment } from '@/queries/fragments/TestimonialsFragment.generated'

interface Props {
  content: TestimonialsFragment
}

const Testimonials: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const items = content.items.map((item) => {
    return {
      ...item,
      title: item.testimonialTitle,
      image: {
        alt: item.image?.alt,
        src: item.image?.filename,
        sizes: `${theme.legacyMediaQueries.md} 220px, 156px`,
      },
      linkComponent: item.link?.url
        ? () => <Link.VNext type="contentlink" link={item.link} text={item.linkText} />
        : null,
    }
  })

  return (
    <Container background={content.background as Background} id={content.sectionId}>
      <TestmonialsComponent testimonials={items} />
    </Container>
  )
}

export default Testimonials
