import React, { ComponentProps, forwardRef, ReactNode, useEffect, useRef } from 'react'
import { keyframes, styled } from 'styled-components'
import { Icon, theme } from '@damen/ui'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import PortalRoot from '../../PortalRoot'
import mergeRefs from '@/utils/mergeRefs'
import useElementScrollLock from '@/utils/useElementScrollLock'
import IntegratedServicesHighlights from './IntegratedServicesHighlight'
import { HotSpot } from '../types'

const Container = styled.div`
  z-index: 20;
  overflow: hidden;

  position: absolute;
  --image-top: 0; // filled via style
  --image-height: 0; // filled via style
  --margin: 0px;
  --left: var(--margin);
  --width: calc(100vw - var(--margin) * 2);
  --height: 100vh;
  --carousel-height: var(--height);
  // Center on top of the image
  --top: var(--scroll-y);
  left: var(--left);
  top: var(--top);
  width: var(--width);
  height: var(--height);
  background: ${theme.colors.marineBlack};

  // The initial; left/top/width/height values are set via style, and only
  // animation props overrule that
  animation: ${keyframes`
      to {
        left: var(--left);
        top: var(--top);
        width: var(--width);
        height: var(--height);
      }
    `} 1200ms ease-in-out forwards;
  @media (prefers-reduced-motion: reduce) {
    animation-duration: 0ms;
  }

  @media screen and (${theme.mediaQueries.mediaQueryTablet}) {
    position: absolute;
    --margin: 0px;
    --left: var(--margin);
    --width: calc(100vw - var(--margin) * 2);
    --height: min(var(--image-height), calc(100vh - var(--margin) * 2));
    // Center on top of the image
    --top: calc(var(--image-top) + (var(--image-height) - var(--height)) / 2);
  }
`

const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: ${theme.spacing.x2}px;
  right: ${theme.spacing.x3}px;
  z-index: 1;
  border: 1px solid ${theme.colors.white};
  border-radius: 50%;
  padding: ${theme.spacing.x05}px;
  cursor: pointer;
  color: ${theme.colors.white};
  background-color: ${theme.colors.marineBlack};
  width: ${theme.spacing.x3}px;
  height: ${theme.spacing.x3}px;
  transition: transform 0.2s;

  &:hover {
    svg {
      transform: rotate(90deg);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    opacity: 0;
    will-change: opacity;
    animation: ${keyframes`
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        `} 600ms ease-in-out forwards;
    animation-delay: 800ms;
  }

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    top: ${theme.spacing.x5}px;
    right: ${theme.spacing.x6}px;
    width: ${theme.spacing.x4}px;
    height: ${theme.spacing.x4}px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`

const PortalContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

interface Props extends Omit<ComponentProps<typeof Container>, 'title'> {
  id: string
  title: ReactNode
  subTitle: ReactNode
  image: {
    src: string
    alt?: string
  }
  buttons: HotSpot[]
  close: () => void
  'data-testid'?: string
}

// eslint-disable-next-line react/display-name
const IntegratedServicesModal = forwardRef<HTMLDivElement, Props>(
  (
    { id, title, subTitle, image, buttons, close, 'data-testid': testid = 'textWithImageModal', ...others }: Props,
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const elem = containerRef.current
      if (!elem) {
        return
      }
      disableBodyScroll(elem, {
        reserveScrollBarGap: true,
      })
      // eslint-disable-next-line consistent-return
      return () => enableBodyScroll(elem)
    }, [containerRef])
    useElementScrollLock(containerRef, true, 2000, true)

    return (
      <Container data-testid={testid} ref={mergeRefs(ref, containerRef)} {...others}>
        <CloseButton onClick={close} data-testid={`${testid}.close`}>
          <Icon.CloseThin width={12} height={12} />
        </CloseButton>

        <PortalRoot>
          <PortalContent>
            <IntegratedServicesHighlights id={id} title={title} subTitle={subTitle} image={image} buttons={buttons} />
          </PortalContent>
        </PortalRoot>
      </Container>
    )
  },
)

export default IntegratedServicesModal
