import { css, keyframes, styled } from 'styled-components'
import { Heading, theme } from '@damen/ui'

export const HighlightCloseButton = styled.button`
  position: absolute;
  z-index: 1;
  top: ${theme.spacing.x5}px;
  left: ${theme.spacing.x6}px;
  border: 1px solid ${theme.colors.white};
  border-radius: 50%;
  padding: ${theme.spacing.x05}px;
  cursor: pointer;
  color: ${theme.colors.white};
  background-color: ${theme.colors.marineBlack};
  width: ${theme.spacing.x4}px;
  height: ${theme.spacing.x4}px;
  transition: transform 0.2s;

  svg {
    path {
      fill: ${theme.colors.white};
    }
  }

  &:hover {
    svg {
      transform: translateX(-2px);
    }
  }

  @media screen and (${theme.mediaQueries.mediaQueryTablet}) {
    top: ${theme.spacing.x4}px;
    right: ${theme.spacing.x4}px;
  }
`

export const Title = styled(Heading.HeadingFour)`
  position: relative;
  margin: 0;
  transition: opacity 800ms ease-in-out;
  min-width: 217px;

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    margin-right: ${theme.spacing.x5}px;
  }
`

export const SubTitle = styled.span`
  position: relative;
  font-size: ${theme.typography.fontSizeText}px;
  line-height: ${theme.typography.lineHeightText};
  transition: opacity 800ms ease-in-out;
  margin: 0;

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    padding-left: ${theme.spacing.x5}px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      background: ${theme.colors.orange};
    }
  }
`

export const activeHighlightCss = () => css`
  ${Title}, ${SubTitle} {
    @media screen and (${theme.mediaQueries.mediaQueryTablet}) {
      opacity: 0;
      // We need pointer-events:none so these elems don't overlap controls such
      // as the close button while hidden
      pointer-events: none;
    }
  }
`

export const HighlightContent = styled.div`
  display: none;

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    position: absolute;
    display: flex;
    align-items: center;
    bottom: ${theme.spacing.x8}px;
    width: 100%;
    max-width: calc(100% - 240px);
    left: 50%;
    transform: translateX(-50%);
    padding: ${theme.spacing.x2}px ${theme.spacing.x5}px;
    color: ${theme.colors.white};
    background: ${theme.colors.marineBlack};

    @media (prefers-reduced-motion: no-preference) {
      opacity: 0;
      will-change: opacity;
      animation: ${keyframes`
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        `} 600ms ease-in-out forwards;
      animation-delay: 1200ms;
    }

    @media screen and (${theme.mediaQueries.mediaQueryDesktopLarge}) {
      width: auto;
    }
  }
`

export const HighlightContentMobile = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: ${theme.spacing.x2}px;
  left: 50%;
  transform: translateX(-50%);
  color: ${theme.colors.white};
  text-align: center;

  @media (prefers-reduced-motion: no-preference) {
    opacity: 0;
    will-change: opacity;
    animation: ${keyframes`
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        `} 600ms ease-in-out forwards;
    animation-delay: 1200ms;
  }

  @media screen and (${theme.mediaQueries.mediaQueryDesktop}) {
    display: none;
  }
`

export const Positioner = styled.div`
  position: relative;
  z-index: -1;
  margin: 0 var(--mobile-margins, 0);

  @media screen and (${theme.mediaQueries.mediaQueryTablet}) {
    max-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0;
  }
`
