import * as Types from '../../schema.generated';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetSocialShareContentQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSocialShareContentQuery = (
  { __typename?: 'Query' }
  & { socialShare: Types.Maybe<(
    { __typename?: 'SocialShare' }
    & Pick<Types.SocialShare, 'id' | 'slug'>
    & { content: (
      { __typename: 'SocialShareContent' }
      & Pick<Types.SocialShareContent, 'id' | 'title' | 'copyTitle' | 'afterCopyTitle'>
      & { socials: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'SocialShareItem' }
        & Pick<Types.SocialShareItem, 'title' | 'descriptiveTitle' | 'type'>
      )>>> }
    ) }
  )> }
);


export const GetSocialShareContentDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getSocialShareContent"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SocialShareQueryFragment"}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SocialShareFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SocialShare"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"slug"}},{"kind":"Field","name":{"kind":"Name","value":"content"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"copyTitle"}},{"kind":"Field","name":{"kind":"Name","value":"afterCopyTitle"}},{"kind":"Field","name":{"kind":"Name","value":"socials"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"descriptiveTitle"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SocialShareQueryFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Query"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"socialShare"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SocialShareFragment"}}]}}]}}]} as unknown as DocumentNode<GetSocialShareContentQuery, GetSocialShareContentQueryVariables>;