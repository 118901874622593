import React, { useState, useMemo, useCallback } from 'react'
import { Heading, Paragraph, theme } from '@damen/ui'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { useMutation } from '@apollo/client'
import { Container } from '@/components/UI'
import Form from '@/components/Forms'

import { pushToDataLayer } from '@/lib/gtm'
import { CampaignSmallFormFragment } from '@/queries/fragments/CampaignSmallFormFragment.generated'
import {
  CampaignSmallFormDocument,
  CampaignSmallFormMutation,
} from '@/queries/mutations/CampaignSmallFormMutation.generated'
import SuccessContent from '@/components/Modal/SuccessContent'
import { mapFormData } from './map'
import Link from '@/components/Link'
import { useBasePath } from '@/hooks'
import { FormContent } from '@/components/Modal/styles'

interface Props {
  content: CampaignSmallFormFragment
}

const CampaignSmallForm: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const router = useRouter()
  const { url } = useBasePath()
  const [completed, setCompleted] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const formData = useMemo(() => mapFormData(content), [content])
  const currentUrl = url ?? ''
  const currentPath = router.asPath ? router.asPath : ''

  const [executeMutation, { data, loading, error }] = useMutation<CampaignSmallFormMutation>(CampaignSmallFormDocument)

  const isDataSuccess = data?.campaignSmallForm.code === 200 && !error

  const onFormSubmit = useCallback(
    (values: any) => {
      const payload = () => ({
        ...values,
        currentUrl,
        ...(retryCount > 0 && { retryCount }),
      })
      executeMutation({
        variables: { id: currentPath, payload: payload() },
      })
        .then((resp) => {
          if (resp.data?.campaignSmallForm.code === 200) {
            setCompleted(true)
            return pushToDataLayer({ event: 'form_submit', type: 'get_in_contact' })
          }
          handleFormError();
        })
        .catch(() => {
          handleFormError()
        })
    },
    [executeMutation, currentPath, currentUrl, retryCount],
  )
  const handleFormError = () => {
    setCompleted(true);
  }
  const retryForm = useCallback(() => {
    setRetryCount(retryCount + 1)
    window.grecaptcha?.reset()
    setCompleted(false);
  }, [retryCount])

  return (
    content && (
      <Container>
        <SmallWrapper>
          <FormContent show={!completed}>
            <TitleWrapper>
              <Title>{content.title}</Title>
              {content.subtitle && <Subtitle>{content.subtitle}</Subtitle>}
            </TitleWrapper>
            <Form.CampaignSmallForm
              formData={formData}
              isLoading={loading}
              handleOnSubmit={onFormSubmit}
              handleOnError={handleFormError}
              stickyButton={false}
            />
          </FormContent>

          <SuccessContent image={isDataSuccess ? content.success.image : content.error.image} inline show={completed}>
            <Heading.HeadingTwo>{isDataSuccess ? content.success.title : content.error.title}</Heading.HeadingTwo>
            <Paragraph.Default>{isDataSuccess ? content.success.text : content.error.text}</Paragraph.Default>
            {/* Should we link somewhere? */}
            <Link.VNext
              type="button"
              testId="button-campaign-small-close"
              text={isDataSuccess ? content.success.buttonText : content.error.buttonText}
              link={isDataSuccess ? content.success.link : null}
              onClick={
                isDataSuccess
                  ? () => {
                      setCompleted(false)
                    }
                  : retryForm
              }
            />
          </SuccessContent>
        </SmallWrapper>
      </Container>
    )
  )
}

const TitleWrapper = styled.div`
  margin: 0 0 32px;
`

const Title = styled(Heading.HeadingTwo)`
  margin-top: 0;
  margin-bottom: ${theme.layout.spacingL};
`

const Subtitle = styled.div`
  color: ${theme.colors.marineBlack};
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: ${theme.typography.fontSizeText};
  font-weight: ${theme.typography.fontWeightRegular};
`

const SmallWrapper = styled.div`
  max-width: ${theme.layout.textContainerWidth};
  margin: 0 auto;
`

export default CampaignSmallForm
