import styled from 'styled-components'
import { Action, ObfuscateLink, theme } from '@damen/ui'
import LinkVNext from '@/components/Link'

export const StyledContactLink = styled(LinkVNext.VNext)`
  ${Action.LinkStyle}

  color: ${theme.colors.blue} !important;
  margin-top: 4px !important;
  width: fit-content;
  margin-bottom: 4px;
`

export const StyledObfuscateLink = styled(ObfuscateLink)`
  ${Action.LinkStyle}

  color: ${theme.colors.blue} !important;
  margin-top: 4px !important;
  width: fit-content;
  margin-bottom: 4px;
`
