import React from 'react'
import NextLink from 'next/link'
import { Icon, Button as StyledButton } from '@damen/ui'
import { Props } from '../types'
import { StyledLink } from './styles'

import { getLinkFromLink } from '../utils'
import { isExternalUrl } from '@/utils/uriUtil'

const Link: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    className,
    color: colorType,
    text,
    scroll,
    icon: IconComponent,
    iconPosition,
    iconSize,
    size,
    rounded,
    shadow,
    link: currentUrl,
    target: passedTarget,
    prefetch,
  } = props
  const hideText = !(text?.length > 0)
  const isSmall = size === 'small'
  const hasIcon = true

  const href = getLinkFromLink(currentUrl) ?? ''
  const isExternalUrlValue = isExternalUrl(href)

  const rel = isExternalUrlValue ? 'noopener' : null
  const target = isExternalUrlValue ? '_blank' : null
  return (
    <StyledLink
      className={className}
      colorType={colorType}
      hasIcon={hasIcon}
      hideText={hideText}
      isSmall={isSmall}
      rounded={rounded}
      shadow={shadow}
    >
      <NextLink href={href} scroll={scroll} passHref rel={rel} target={passedTarget ?? target} prefetch={prefetch}>
        <StyledButton.TextWrapper hideText={hideText}>{text}</StyledButton.TextWrapper>
        {hasIcon && (
          <StyledButton.IconWrapper hideText={hideText} iconPosition={iconPosition}>
            {IconComponent && <IconComponent />}
            {!IconComponent && isExternalUrlValue && (
              <Icon.OpenInNew display="block" fill="currentColor" width={iconSize || 14} height={iconSize || 14} />
            )}
            {!IconComponent && !isExternalUrlValue && (
              <Icon.LeftChevron display="block" fill="currentColor" width={iconSize || 5} height={iconSize || 8} />
            )}
          </StyledButton.IconWrapper>
        )}
      </NextLink>
    </StyledLink>
  )
}

export default { Link }
