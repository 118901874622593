import React from 'react'
import { Image } from '@damen/ui'

import Container from '@/components/UI/Container'
import { Background, ImageType } from '@/types'

type ImageProps = 'primary' | 'secondary'

interface FullscreenImage {
  img: ImageType
  type: ImageProps
}

interface Content {
  id: string
  background: Background
  bowWave: boolean
  images: FullscreenImage[]
}

interface Props {
  content: Content
}

const FullWidthImage: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const images = content.images.map(({ img, type }) => {
    return {
      alt: img.alt,
      src: img.filename,
      type,
    }
  })

  return (
    <Container background={content.background} componentType="image-full">
      <Image.Fullscreen bowWave={content.bowWave} images={images} key={content.id} />
    </Container>
  )
}

export default FullWidthImage
