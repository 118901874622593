import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { mapTags } from '@/utils/helpers'

interface Props {
  values: any[]
}

const filterItems = (tags: any[], values: any[]) => {
  const filteredItems = values.reduce((accumulator, currentValue) => {
    const itemTags = currentValue.tags ? mapTags(currentValue.tags) : null

    if (!itemTags) {
      return accumulator
    }

    if (itemTags.some((tag) => tags.includes(tag))) {
      accumulator.push(currentValue)
    }

    return accumulator
  }, [])

  return filteredItems
}

export const useTagFilters = ({ values }: Props): any[] => {
  const [filteredValues, setFilteredValues] = useState(values)
  const [tags, setTags] = useState([])
  const router = useRouter()

  const filtered = tags.length > 0

  const toggleFilterTag = (tag: any) => {
    if (tags.includes(tag)) {
      const newTags = tags.filter((item) => item !== tag)

      void router.push({ pathname: '', query: { f: newTags.join('_') } }, null, { shallow: true })
      setTags(newTags)
      return
    }

    void router.push({ pathname: '', query: { f: [...tags, tag].join('_') } }, null, { shallow: true })

    setTags([...tags, tag])
  }

  const clearFilterTags = () => {
    void router.push({ pathname: '', query: { f: null } }, null, { shallow: true })

    setTags([])
  }

  useEffect(() => {
    const filters = (router.query.f as string) || null

    if (tags.length === 0 && filters) {
      setTags(filters.split('_'))
    }
  }, [router.query, tags])

  useEffect(() => {
    if (tags.length === 0) {
      setFilteredValues([...values])
    }

    if (tags.length > 0) {
      setFilteredValues([...filterItems(tags, values)])
    }
  }, [tags, values])

  return [filteredValues, filtered, tags, toggleFilterTag, clearFilterTags]
}
