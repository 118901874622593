import React from 'react'
import { FAQ } from '@damen/ui'
import styled from 'styled-components'

import { trimAndLowerCaseString } from '@/helpers/common'
import { FAQItem } from '@/types'
import { renderRichText } from '@/utils/richTextUtils'

interface Props {
  items: any[]
  selectedOption: React.ReactNode
  verticalSpacing?: boolean
}

const FAQItems: React.FC<React.PropsWithChildren<Props>> = ({ items, selectedOption, verticalSpacing = false }) => (
  <Inner verticalSpacing={verticalSpacing}>
    {items.map((item) => {
      const title = trimAndLowerCaseString(item.title)

      return item.questions?.map((faq: FAQItem) => {
        const faqContent = faq.content ? renderRichText(JSON.parse(faq.content)) : []

        return (
          <FaqInner key={faq.title} active={selectedOption === title}>
            <FAQ.Item label={faq.title} initiallyOpen={false}>
              {faqContent}
            </FAQ.Item>
          </FaqInner>
        )
      })
    })}
  </Inner>
)

const Inner = styled.div.withConfig({
  shouldForwardProp: (prop) => !['verticalSpacing'].includes(prop),
})<{ verticalSpacing?: boolean }>`
  margin: ${({ verticalSpacing }) => (verticalSpacing ? '24px 0 0' : '0')};

  @media ${({ theme }) => theme.legacyMediaQueries.md} {
    margin: ${({ verticalSpacing }) => (verticalSpacing ? '48px auto 0' : '0 auto')};
    max-width: calc(8 / 12 * 100%);
  }
`

const FaqInner = styled.div.withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})<{ active: boolean }>`
  border-bottom: solid 1px ${({ theme }) => theme.colors.greyAccentLight};
  display: ${({ active }) => (active ? 'block' : 'none')};
`

export default FAQItems
