import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Card, Icon, theme } from '@damen/ui'
import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'

import Link from '@/components/Link'

import { FamilyModel } from '@/queries/schema.generated'
import { getSustainableMarkWithPopover } from '@/utils/helpers/sustainableIcon'
import { onDownloadClick } from '@/hooks/modal/download'
import { ModalProps } from '../../Modal/types'

interface Props {
  model: FamilyModel
  enableDirectProductSheetDownload: boolean
  setOpenModal: (props: ModalProps) => void
  testId?: string
}

const CardWrapper: React.FC<React.PropsWithChildren<Props>> = ({
  model,
  enableDirectProductSheetDownload,
  setOpenModal,
  testId,
}) => {
  const [ref, inView] = useInView({ rootMargin: '-50%' })
  const [startAnimation, setAnimation] = useState(false)

  useEffect(() => {
    if (inView && !startAnimation) {
      setAnimation(true)
    }
  }, [inView, startAnimation])

  const image = useMemo(() => {
    if (!model.image) return undefined
    return {
      alt: model.title,
      src: model.image,
      sizes: `${theme.legacyMediaQueries.lg} 168px, 48px`,
    }
  }, [model.image, model.title])

  const onClickDownload = useCallback(() => {
    onDownloadClick()
    setOpenModal({
      type: 'download',
      data: {
        link: model.downloadLink,
        title: model.title,
        product: {
          id: model.id,
          name: model.title,
          family: model.family,
          range: model.range,
          salesforceName: model.salesforceName,
        },
      },
    })
  }, [model.downloadLink, model.title, model.id, model.family, model.range, model.salesforceName, setOpenModal])

  return (
    <Container ref={ref}>
      <Card.ProductCard
        buttonComponent={
          model.ctaText &&
          (() => (
            <Link.VNext
              type="button"
              link={model.productDetailLink}
              text={model.ctaText}
              icon={Icon.LeftChevron}
              iconSize={9}
              testId={`model.button-detail-${testId}`}
            />
          ))
        }
        description={model.description}
        downloadButton={
          model.downloadText &&
          !!model.downloadLink?.cachedUrl &&
          (() => {
            if (enableDirectProductSheetDownload) {
              return (
                <StyledLink
                  type="button"
                  link={model.downloadLink}
                  text={model.downloadText}
                  icon={() => <Icon.FileDownload width={16} height={16} fill={theme.colors.blue} />}
                  iconSize={16}
                  color="white"
                />
              )
            }

            return (
              <Link.VNext
                type="button"
                color="white"
                icon={Icon.FileDownload}
                iconSize={16}
                onClick={onClickDownload}
                dataAttrs={{
                  'data-gtm-product': model.title,
                  'data-gtm-action': 'download',
                }}
                link={null}
                text={model.downloadText}
                testId={`model.button-download-${testId}`}
              />
            )
          })
        }
        highlights={model.highlights || null}
        image={image}
        tags={model.labels.slice(0, 2)}
        title={model.title || ''}
        titleLink={({ children }) => (
          <Link.VNext type="link" link={model.productDetailLink}>
            {children}
          </Link.VNext>
        )}
        titleIcon={getSustainableMarkWithPopover(model.sustainableMark, 36, 30)}
      />
    </Container>
  )
}

const Container = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin: 0;
  }

  div div div div div span {
    white-space: pre-line;
  }
`
const StyledLink = styled(Link.VNext)`
  display: inline-flex;

  a {
    padding: 0;
    font-size: ${theme.typography.fontSizeTextSmall}px;

    &:hover {
      background: transparent;
    }
  }
`

export default CardWrapper
