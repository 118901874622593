import React, { useCallback, useEffect } from 'react'

import { useRouter } from 'next/router'
import { styled } from 'styled-components'
import { Icon, ProductDetails as ProductDetailsComponent, theme } from '@damen/ui'
import { Container } from '@/components/UI'
import { GalleryItemType, ProductDetails as ProductDetailsProps, ProductTypes } from '@/queries/schema.generated'
import { useModalQuoteContext } from '@/contexts/modalQuoteContext'
import Link from '@/components/Link'
import { arrayIntoColumns } from '@/utils/arrayUtils'
import { QUOTE_QUERYSTRING } from '@/components/Forms/constants'

interface Props {
  content?: ProductDetailsProps
}

const StyledLink = styled(Link.VNext)`
  display: inline-flex;

  a {
    padding: 0;

    &:hover {
      background: transparent;
    }
  }
`

const ProductDetails: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const { specifications, document, title, product } = content
  const quoteModalContext = useModalQuoteContext()
  const setQuoteModal = quoteModalContext.setModal
  const router = useRouter()
  const quoteType = useCallback(() => {
    switch (content.productType) {
      case ProductTypes.UsedProduct:
        return 'usedProductQuote' as const

      case ProductTypes.CharterProduct:
        return 'charterProductQuote' as const

      default:
        return 'quote' as const
    }
  }, [content.productType])

  const mappedAssets = content?.assets?.items.map((asset) => {
    return {
      ...asset,
      thumbnail: {
        src: asset?.thumbnail,
        alt: asset?.title,
        sizes: `${theme.legacyMediaQueries.lg} 300px, ${theme.legacyMediaQueries.md} 190px, 50vw`,
      },
      overviewImage: {
        src: asset?.thumbnail,
        alt: asset?.title,
        sizes: `${theme.legacyMediaQueries.lg} 600px, ${theme.legacyMediaQueries.md} 460px, 100vw`,
      },
      linkComponent:
        asset.type === GalleryItemType.VirtualTour && content.virtualTourLink?.url
          ? ({ children }: any) => (
              <Link.VNext type="link" link={content.virtualTourLink}>
                {children}
              </Link.VNext>
            )
          : undefined,
    }
  })

  const downloadButton =
    content?.downloadText && document
      ? () => <StyledLink type="contentlink" link={{ url: document }} text={content.downloadText} size="small" />
      : null

  const quoteLink = { id: `quoteLink--${quoteType()}-${content.id}`, url: `${router.asPath}${QUOTE_QUERYSTRING}` }
  const buttonComponent =
    content.ctaText && setQuoteModal
      ? () => (
          <Link.VNext
            type="button"
            link={quoteLink}
            dataAttrs={{ 'data-gtm-product': product.shipType, 'data-gtm-action': 'quote' }}
            text={content.ctaText}
          />
        )
      : null

  const configurationButtonComponent =
    content?.configurationText && content.configurationLink?.url
      ? () => (
          <StyledLink
            type="contentlink"
            link={content.configurationLink}
            text={content.configurationText}
            size="small"
          />
        )
      : null

  const virtualTourButton =
    content.virtualTourText &&
    !!content.virtualTourLink?.url &&
    (() => (
      <StyledLink
        type="button"
        link={content.virtualTourLink}
        text={content.virtualTourText}
        color="whiteBlue"
        icon={Icon.Eye}
      />
    ))

  const chunkedSpecs = arrayIntoColumns(specifications.items, 2).map((x, index) => {
    return {
      id: `column-${index}`,
      items: x,
    }
  })

  useEffect(() => {
    const handleHashChange = (url: string) => {
      if (!setQuoteModal) return
      if (url.includes(QUOTE_QUERYSTRING)) {
        const quoteModalData = { type: quoteType(), data: { product } }
        setQuoteModal(quoteModalData)
      } else {
        // Make sure the modal closes even when using browser back
        setQuoteModal(null)
      }
    }

    router.events.on('hashChangeComplete', handleHashChange)

    return () => {
      router.events.off('hashChangeComplete', handleHashChange)
    }
  }, [router.events, quoteType, product, setQuoteModal])

  return (
    <Container background="light">
      <ProductDetailsComponent.Used
        title={title}
        tags={content.labels}
        description={content.description}
        highlights={content.highlights}
        assets={{
          title: content?.assets?.title,
          items: mappedAssets,
        }}
        galleryItems={content.galleryItems}
        buttonComponent={buttonComponent}
        downloadButton={downloadButton}
        configurationButton={configurationButtonComponent}
        virtualTourButton={virtualTourButton}
        specifications={{
          title: specifications.title,
          columns: chunkedSpecs,
        }}
      />
    </Container>
  )
}

export default ProductDetails
