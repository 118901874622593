import React from 'react'
import { Search } from '@damen/ui'

interface Option {
  label: string
  value: string
}

interface Facet {
  expandText: string
  initialValues?: string[]
  initiallyOpen?: boolean
  isCollapsible?: boolean
  options: Option[]
  title: string
  onChange: (selectedValues: any) => void
}
interface Props {
  facets: Facet[]
}

const SearchFilters: React.FC<React.PropsWithChildren<Props>> = ({ facets }) => <Search.Filters items={facets} />

export default SearchFilters
