import easeInOutCubic from './easeInOutCubic'

const smoothScrollTo = (
  elem: HTMLElement,
  duration: number,
  // eslint-disable-next-line default-param-last
  centered = false,
  abortController?: AbortController,
) =>
  new Promise<void>((resolve) => {
    let startTime: number
    const startY = window.scrollY
    const scroll = (timestamp: number) => {
      startTime = startTime || timestamp
      const elapsed = timestamp - startTime
      const rect = elem.getBoundingClientRect()
      const top = window.scrollY + rect.top - (centered ? Math.max(0, window.innerHeight - rect.height) / 2 : 0)
      if (abortController?.signal.aborted) {
        resolve()
        return
      }

      window.scrollTo({
        top: startY + (top - startY) * easeInOutCubic(Math.min(1, elapsed / duration)),
        behavior: 'auto',
      })

      if (elapsed >= duration) {
        resolve()
        return
      }

      window.requestAnimationFrame(scroll)
    }

    window.requestAnimationFrame(scroll)
  })

export default smoothScrollTo
