import React, { FunctionComponent, useMemo } from 'react'

interface Props {
  bloks: Record<string, any>[]
  componentLookup: Record<string, FunctionComponent<any>>
  additionalProps?: Record<string, unknown>
}

export const BlokZone = ({ bloks, componentLookup, additionalProps = undefined }: Props) => {
  const renderedBloks = useMemo(
    () =>
      bloks.map((blok) => {
        const { __typename, id } = blok

        if (typeof componentLookup[__typename] !== 'undefined') {
          const props: any = {
            key: id,
            blok,
          }
          const CurrentComponent = componentLookup[__typename]

          return (
            <section key={id}>
              <CurrentComponent {...props} key={id} content={blok} {...additionalProps} />
            </section>
          )
        }

        return null
      }),
    [bloks, componentLookup, additionalProps],
  )

  return <>{renderedBloks}</>
}
